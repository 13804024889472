<template>
  <vuestic-modal
    class="housing-help-modal"
    ref="housingHelpModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">Ajouter un élément au dossier logement</span>
    </div>
    <form novalidate @submit.prevent="addHousingHelp">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('type')">
            <label for="housing-help-type">Type de demande</label>
            <md-select
              name="housing-help-type"
              id="housing-help-type"
              v-model="currentHousingHelp.type"
            >
              <md-option
                v-for="(p, i) in [
                  'SIAO',
                  'DAHO',
                  'DALO',
                  'Logement social',
                  'Autre',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentHousingHelp.type.required"
              >Le type est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('status')">
            <label for="housing-help-status">Statut</label>
            <md-select
              name="housing-help-status"
              id="housing-help-status"
              v-model="currentHousingHelp.status"
            >
              <md-option
                v-for="(p, i) in ['En cours', 'Accepté', 'Refusé']"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentHousingHelp.status.required"
              >Le status est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="housing-help-id">Identifiant</label>
            <md-input
              name="housing-help-id"
              id="housing-help-id"
              v-model="currentHousingHelp.id_number"
            />
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de la demande :
              <vuestic-date-picker
                v-model="currentHousingHelp.ask_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de la réponse :
              <vuestic-date-picker
                v-model="currentHousingHelp.answer_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div
          v-if="currentHousingHelp.type == 'SIAO'"
          class="md-layout-item d-flex align-items-center"
        >
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Début de validité :
              <vuestic-date-picker
                v-model="currentHousingHelp.validity_start_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div
          v-if="currentHousingHelp.type == 'SIAO'"
          class="md-layout-item d-flex align-items-center"
        >
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Fin de validité :
              <vuestic-date-picker
                v-model="currentHousingHelp.validity_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
    </form>
    <h4 class="p-4 d-flex">Lier un contact</h4>
    <contact-linker
      ref="contactLinker"
      :recipient_id="recipient_id"
      form_ref="housingHelpLinker"
      :contact_ids="currentHousingHelp.contact_ids"
      @change="changeContact"
    />
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ContactLinker from "../contacts/ContactLinker.vue";
export default {
  name: "housingHelp-modal",
  components: { VuesticModal, VuesticDatePicker, ContactLinker },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      originHousingHelp: {},
      currentHousingHelp: {
        contact_ids: [],
      },
      housingHelp: {},
    };
  },
  validations: {
    currentHousingHelp: {
      type: {
        required,
      },
      status: {
        required,
      },
    },
  },
  methods: {
    refreshHousingHelp() {
      if (this.housingHelp._id) {
        axios
          .get(`/api/web/housing_help/${this.housingHelp._id}`)
          .then((res) => {
            this.originHousingHelp = this._.cloneDeep(res.data.data);
            this.currentHousingHelp = this._.cloneDeep(this.originHousingHelp);
          });
      } else {
        this.originHousingHelp = {};
        this.currentHousingHelp = {
          contact_ids: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentHousingHelp[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentHousingHelp[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(housingHelp) {
      this.$refs.housingHelpModal.open();
      this.housingHelp = housingHelp;
      this.refreshHousingHelp();
      this.$refs.contactLinker.open();
    },
    resetForm() {
      this.currentHousingHelp = {
        contact_ids: [],
      };
      this.originHousingHelp = {};
    },
    createHousingHelp() {
      let body = {
        housing_help: this.currentHousingHelp,
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/housing_helps`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "housingHelp");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    saveHousingHelp() {
      let body = {
        current: this.currentHousingHelp,
        origin: this.originHousingHelp,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/housing_help/${this.housingHelp._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "housingHelp");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.housingHelp._id) {
          this.saveHousingHelp();
        } else {
          this.createHousingHelp();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Parcours non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
    changeContact(payload) {
      this.currentHousingHelp.contact_ids = payload;
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.housingHelp-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>