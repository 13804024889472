<template>
  <div id="app">
    <auth-layout v-if="isAuth"></auth-layout>
    <layout v-else />
  </div>
</template>

<script>
import Layout from "./components/layout/Layout.vue";
import AuthLayout from "./components/layout/AuthLayout.vue";
export default {
  name: "app",
  components: {
    Layout,
    AuthLayout,
  },
  computed: {
    isAuth() {
      return this.$route.path.match(/^\/auth\//);
    },
  },
};
</script>

<style lang="scss">
@import "sass/main";
@import "sass/_variables.scss";

:root {
  --vs-light: 163, 224, 255 !important;
  --vs-primary: 46, 81, 123 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
