<template>
  <div class="contact-linker">
    <b-table
      v-if="contacts && contacts.length > 0"
      striped
      hover
      :items="contacts"
      :fields="fields"
    >
      <template #cell(actions)="row">
        <ion-icon
          name="trash"
          style="cursor: pointer"
          v-b-tooltip.hover
          title="Supprimer"
          @click="askDelete(row.index)"
        ></ion-icon>
        <ion-icon
          name="arrow-up"
          style="cursor: pointer"
          v-b-tooltip.hover
          title="Mettre en premier"
          @click="setPriority(row.index)"
        ></ion-icon>
      </template>
    </b-table>
    <contact-form
      :emitButton="true"
      :recipient_id="recipient_id"
      :ref="form_ref"
      @contact_id="linkContact"
    />
  </div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import axios from "axios";
import { telFormatter } from "../../services/utils";
export default {
  components: { ContactForm },
  name: "contact-linker",
  props: {
    form_ref: {
      type: String,
      default: () => "contactForm",
    },
    recipient_id: {
      type: String,
      required: true,
    },
    contact_ids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contacts: [],
      fields: [
        {
          key: "organism.name",
          label: "Organisme",
        },
        {
          key: "contact.civility",
          label: "Civilité",
        },
        {
          key: "contact.surname",
          label: "Prénom",
        },
        {
          key: "contact.name",
          label: "Nom",
          formatter: (val) => (val ? val.toUpperCase() : ""),
        },
        {
          key: "contact.tel",
          label: "Téléphone",
          formatter: telFormatter,
        },
        {
          key: "contact.email",
          label: "Email",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  mounted() {
    this.open();
    this.$refs[this.form_ref].open({});
  },
  watch: {
    contact_ids(val) {
      this.refreshContacts(val);
    },
  },
  methods: {
    askDelete(index) {
      this.$toasted
        .show("Souhaitez-vous supprimer le contact", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                let contacts = this.contact_ids
                  .slice(0, index)
                  .concat(this.contact_ids.slice(index + 1));
                this.$emit("change", contacts);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    open() {
      this.$refs[this.form_ref].open({});
      this.refreshContacts(this.contact_ids);
    },
    setPriority(index) {
      let contacts = [this.contact_ids[index]].concat(
        this.contact_ids
          .slice(0, index)
          .concat(this.contact_ids.slice(index + 1))
      );
      this.$emit("change", contacts);
    },
    refreshContacts(contacts) {
      axios
        .post("/api/web/contacts/by_list", {
          contact_ids: contacts,
        })
        .then((res) => {
          this.contacts = res.data.data;
        });
    },
    linkContact(payload) {
      let contacts = this.contact_ids.concat([payload.contact_id]);
      this.$emit("change", contacts);
      this.$refs[this.form_ref].open({});
    },
  },
};
</script>

<style>
</style>