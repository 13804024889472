<template>
  <div class="recipient-health">
    <div class="health-container pt-2 pl-4 pr-4">
      <form novalidate class="md-layout" @submit.prevent="validateHealth">
        <widget headerText="Santé de l'accueilli">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="medical_following">Suivi médical</label>
                <md-select
                  name="medical_following"
                  id="medical_following"
                  v-model="currentMedical.medical_following"
                >
                  <md-option
                    v-for="(o, i) in ['NSP', 'Aucun', 'En cours', 'Souhaité']"
                    :key="i"
                    :value="o"
                  >
                    {{ o }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="psy_following">Suivi psy</label>
                <md-select
                  name="psy_following"
                  id="psy_following"
                  v-model="currentMedical.psy_following"
                >
                  <md-option
                    v-for="(o, i) in [
                      'NSP',
                      'Aucun',
                      'En cours',
                      'Souhaité',
                      'Ancien',
                    ]"
                    :key="i"
                    :value="o"
                  >
                    {{ o }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="toxico">Toxicomanie</label>
                <md-select
                  name="toxico"
                  id="toxico"
                  v-model="currentMedical.toxico"
                >
                  <md-option
                    v-for="(o, i) in [
                      'NSP',
                      'Oui',
                      'Non',
                      'Substitution',
                      'Ancien',
                    ]"
                    :key="i"
                    :value="o"
                  >
                    {{ o }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="alcool">Alcoolisme</label>
                <md-select
                  name="alcool"
                  id="alcool"
                  v-model="currentMedical.alcool"
                >
                  <md-option
                    v-for="(o, i) in ['NSP', 'Oui', 'Non', 'Ancien']"
                    :key="i"
                    :value="o"
                  >
                    {{ o }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <h4 class="p-4 d-flex">Lier un contact</h4>
          <contact-linker
            ref="contactLinker"
            class="pb-2"
            :recipient_id="recipient_id"
            form_ref="healthLinker"
            :contact_ids="currentMedical.contact_ids"
            @change="changeContact"
          />
          <button type="submit" class="btn btn-primary">Enregistrer</button>
        </widget>
      </form>
    </div>
    <comments
      :recipient_id="recipient_id"
      field="health"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import axios from "axios";
import Comments from "../comment/Comments.vue";
import ContactLinker from "../contacts/ContactLinker.vue";
export default {
  name: "recipient-health",
  components: {
    Widget,
    Comments,
    ContactLinker,
  },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      currentMedical: {
        contact_ids: [],
      },
      originMedical: {},
    };
  },
  watch: {
    originMedical() {
      if (this.originMedical.contact_ids == undefined) {
        this.originMedical.contact_ids = [];
      }
      this.currentMedical = this._.cloneDeep(this.originMedical);
    },
  },
  mounted() {
    this.refreshMedical();
  },
  methods: {
    refreshMedical() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/medical`)
        .then((res) => {
          this.originMedical = res.data.data;
        });
    },
    validateHealth() {
      let body = {
        medical: this.currentMedical,
      };
      axios
        .post(`/api/web/recipient/${this.recipient_id}/medical`, body)
        .then(() => {
          this.$toasted.show("Données médicales enregistrées").goAway(2000);
          this.refreshMedical();
        });
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    changeContact(payload) {
      this.currentMedical.contact_ids = payload;
    },
  },
};
</script>

<style>
</style>