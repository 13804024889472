<template>
  <div class="room-calendar">
    <div class="calendar-container pt-4 pl-4 pr-4 pb-2">
      <widget headerText="Planning d'occupation des chambres">
        <div class="p-2 d-flex">
          <div class="md-layout-item p-2">
            <md-field>
              <label for="hotel_for_calendar">Hotel</label>
              <md-select
                name="hotel_for_calendar"
                id="hotel_for_calendar"
                v-model="selectedHotel"
              >
                <md-option v-for="(h, i) in hotels" :key="i" :value="h._id">
                  {{ h.name }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item p-2">
            <md-field>
              <label for="room_for_calendar">Chambre</label>
              <md-select
                name="room_for_calendar"
                id="room_for_calendar"
                v-model="selectedRoom"
              >
                <md-option v-for="(r, i) in rooms" :key="i" :value="r._id">
                  {{ r.number }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <button class="btn btn-secondary p-2" type="button" @click="init">
            Réinitialiser
          </button>
        </div>
        <rotate-square2 v-if="isLoading"></rotate-square2>
        <vc-calendar
          class="custom-calendar max-w-full"
          :masks="masks"
          :attributes="attributes"
          disable-page-swipe
          is-expanded
          :style="{ visibility: isLoading ? 'hidden' : 'visible' }"
          @update:from-page="changeMonth"
          ><template v-slot:day-content="{ day, attributes }">
            <div
              class="day d-flex flex-column h-full z-10 overflow-hidden"
              v-on:click="selectDay(day.date)"
            >
              <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <div
                  class="d-flex justify-content-center"
                  v-for="attr in attributes"
                  :key="attr.key"
                >
                  <div
                    class="room-square-total m-1"
                    :class="getRoomClass(attr.customData.rooms_available)"
                    v-b-tooltip.hover
                    :title="
                      attr.customData.rooms_available +
                      ' chambres disponibles pour le MRS'
                    "
                  >
                    {{ attr.customData.rooms_available }}
                  </div>
                  <div
                    v-b-tooltip.hover
                    :title="
                      attr.customData.in_antenna +
                      ' chambres disponibles pour l\'antenne'
                    "
                    class="room-circle-antenna m-1"
                    :class="getAntennaRoomClass(attr.customData.in_antenna)"
                  >
                    {{ attr.customData.in_antenna }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </vc-calendar>
      </widget>
    </div>
    <div
      v-if="occupationList && occupationList.length > 0"
      class="list-container pl-4 pr-4"
    >
      <widget
        :headerText="
          'Liste des chambres disponibles au ' +
          selectedDay.format('DD/MM/YYYY')
        "
      >
        <b-table
          striped
          hover
          :items="occupationList"
          :fields="fields"
          @row-clicked="selectRoom"
          ><template #cell(actions)="row">
            <ion-icon
              class="pl-2"
              name="eye"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Fiche hotel"
              @click="goToHotel(row.item)"
            >
            </ion-icon></template
        ></b-table>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget.vue";
import { mapState } from "vuex";
import { RotateSquare2 } from "vue-loading-spinner";
import axios from "axios";
export default {
  name: "room-calendar",
  components: {
    Widget,
    RotateSquare2,
  },
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      isLoading: false,
      occupationList: null,
      attributes: [
        {
          key: 1,
          customData: {
            rooms_available: 2,
            in_antenna: 0,
          },
          dates: new Date(2021, 4, 1),
        },
      ],
      selectedDay: this.$moment(this.$moment().format("YYYY-MM-DD")),
      selectedHotel: null,
      selectedRoom: null,
      selectedMonth: null,
      selectedYear: null,
      hotels: [],
      rooms: [],
      fields: [
        { key: "hotel.name", label: "Hôtel", sortable: true },
        {
          key: "number",
          label: "Numéro",
        },
        { key: "hotel.address.street", label: "Adresse" },
        { key: "hotel.address.postal_code", label: "Code postal" },
        { key: "hotel.address.city", label: "Ville" },
        {
          key: "antenna_id",
          label: "Antenne",
          sortable: true,
          formatter: (val) => this.antennas.filter((a) => a._id == val)[0].name,
        },
        {
          key: "borrow_antenna_id",
          label: "Prétée au",
          formatter: (val) =>
            val && val != "Aucune"
              ? this.antennas.filter((a) => a._id == val)[0].name
              : "",
        },
        {
          key: "number",
          label: "Numéro",
        },
        {
          key: "archived",
          label: "Archivée",
          formatter: (val) => (val ? "Oui" : "Non"),
        },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  mounted() {
    this.refreshHotels();
    this.refreshRooms();
    this.refreshOccupation();
  },
  watch: {
    selectedHotel() {
      this.refreshRooms();
      this.refreshOccupations(this.selectedMonth, this.selectedYear);
    },
    selectedRoom() {
      this.refreshOccupations(this.selectedMonth, this.selectedYear);
    },
  },
  computed: {
    ...mapState(["user", "antennas"]),
  },
  methods: {
    goToHotel(room) {
      this.$router.push(`/hotel/${room.hotel_id}`);
    },
    getRoomClass(val) {
      return val > 0 ? "square-green" : "square-red";
    },
    getAntennaRoomClass(val) {
      return val > 0 ? "circle-green" : "circle-orange";
    },
    changeMonth(payload) {
      let previousYear = this._.cloneDeep(this.selectedYear);
      let previousMonth = this._.cloneDeep(this.selectedMonth);
      this.selectedMonth = payload.month;
      this.selectedYear = payload.year;
      if (
        this.selectedMonth != previousMonth ||
        this.selectedYear != previousYear
      ) {
        this.refreshOccupations(payload.month, payload.year);
      }
    },
    selectDay(val) {
      this.selectedDay = this.$moment(val);
      this.refreshOccupation();
    },
    refreshHotels() {
      axios.get("/api/web/hotels").then((res) => {
        this.hotels = res.data.data;
      });
    },
    refreshRooms() {
      if (this.selectedHotel) {
        axios.get(`/api/web/hotel/${this.selectedHotel}/rooms`).then((res) => {
          this.rooms = res.data.data;
        });
      } else {
        axios.get(`/api/web/rooms`).then((res) => {
          this.rooms = res.data.data;
        });
      }
    },
    init() {
      this.selectedHotel = null;
      this.selectedRoom = null;
      this.selectedDay = null;
    },
    selectRoom(room) {
      this.selectedHotel = room.hotel_id;
      this.selectedRoom = room._id;
    },
    refreshOccupations(month, year) {
      let params = { antenna: this.user.antenna_id };
      if (this.selectedHotel) {
        params.hotel_id = this.selectedHotel;
      }
      if (this.selectedRoom) {
        params.room_id = this.selectedRoom;
      }
      this.isLoading = true;
      axios
        .get(`/api/web/rooms/calendar/${year + "-" + month + "-" + "01"}`, {
          params: params,
        })
        .then((res) => {
          this.isLoading = false;
          this.attributes = res.data.data.map((value, index) => {
            return {
              key: index,
              customData: {
                rooms_available: value.rooms,
                in_antenna: value.rooms_antenna,
              },
              dates: new Date(this.$moment(value.date)),
            };
          });
        });
    },
    refreshOccupation() {
      let params = {};
      if (this.selectedHotel) {
        params.hotel_id = this.selectedHotel;
      }
      if (this.selectedRoom) {
        params.room_id = this.selectedRoom;
      }
      axios
        .get(
          `/api/web/rooms/on_date/${this.selectedDay.format("YYYY-MM-DD")}`,
          { params: params }
        )
        .then((res) => {
          this.occupationList = res.data.data;
        });
    },
  },
};
</script>

<style lang="scss">
.day {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid white;
  &:hover {
    border: 1px solid black;
  }
}
.room-square-total {
  border-radius: 20%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  &.square-green {
    background-color: #c6f1b8;
  }
  &.square-red {
    background-color: #f1bdbe;
  }
}
.room-circle-antenna {
  border-radius: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  &.circle-green {
    background-color: #c6f1b8;
  }
  &.circle-orange {
    background-color: #fce5c7;
  }
}
</style>