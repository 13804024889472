var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"housing-dashboard"},[_c('div',{staticClass:"housing-list pl-4 pt-4 pr-2"},[_c('widget',{attrs:{"headerText":"Hébergements en cours"}},[(_vm.isLoading)?_c('rotate-square2'):_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.housings,"fields":_vm.fields,"tbody-tr-class":_vm.housingClass,"sort-by":_vm.filter.sortBy,"sort-desc":_vm.filter.sortDesc},on:{"row-clicked":_vm.handleRowClick,"sort-changed":_vm.sortChanged}}),_c('div',{staticClass:"pagination d-flex justify-content-end"},[_c('nav',{attrs:{"aria-label":"..."}},[_c('ul',{staticClass:"pagination"},[(_vm.page > 0)?_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function () {
                    _vm.page -= 1;
                  }}},[_vm._v("Précédent")])]):_vm._e(),(_vm.page > 0)?_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function () {
                    _vm.page -= 1;
                  }}},[_vm._v(_vm._s(_vm.page))])]):_vm._e(),_c('li',{staticClass:"page-item active"},[_c('span',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.page + 1)+" "),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])]),(_vm.hasMorePages)?_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function () {
                    _vm.page += 1;
                  }}},[_vm._v(_vm._s(_vm.page + 2))])]):_vm._e(),(_vm.hasMorePages)?_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function () {
                    _vm.page += 1;
                  }}},[_vm._v("Suivant")])]):_vm._e()])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }