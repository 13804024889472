import axios from 'axios'

const ACCESS_TOKEN_KEY = 'access_token'

export function saveToken(token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

function invalidateToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
}

function readToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
}

function pushRouterToLogin(to, reason) {
    let query = {}

    if (to !== false) query.redirect = to
    if (reason !== undefined) query.reason = reason
    window.$vue.$router.push({
        path: '/auth/login',
        query: query,
    })
}

function redirectLogin(reason, invalidate = true) {
    if (invalidate) invalidateToken()

    var to = !window.$vue.$route.path.startsWith('/auth')
        ? window.$vue.$route.path
        : false

    if (reason === 'LOGIN_REQUIRED' || reason === 'NO_TOKEN') {
        pushRouterToLogin(to)
    } else {
        pushRouterToLogin(to, reason)
    }
}

function checkTokenValidity() {
    return axios
        .post('/api/auth/check', { token: readToken() })
        .then(resp => {
            if (!resp.data.data.valid) {
                if (!window.$vue.$route.path.startsWith('/auth')) {
                    redirectLogin(resp.data.data.reason)
                }
                return false
            } else return true
        })
        .catch(error => {
            invalidateToken()
            console.error(error.response ? error.response : error)
            return false
        })
}

axios.interceptors.request.use(
    config => {
        let token = readToken()
        if (token !== null) {
            config.headers.authorization = token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// On souhaite être notifié des erreurs de connexion a l'API et invalider le token
axios.interceptors.response.use(response => response, function (error) {
    if (error.response && error.response.status === 403) {
        var message = error.response.data.message
        redirectLogin(message)
    }
    return Promise.reject(error)
})

export const initUser = {
    _id: '',
    name: '',
    surname: '',
    habilitation: '',
    login: '',
    settings: {
    },
    email: '',
}
export default {
    user: {
        authentificated: false,
    },
    isLoggedIn() {
        return readToken() !== null
    },
    login(username, password) {
        return axios.post('/api/auth/login', { username, password }).then(resp => {
            let token = resp.data.data.token
            saveToken(token)
            return resp.data.data
        })
    },
    logout() {
        return axios.post('/api/auth/logout', { token: readToken() }).then(() => {
            invalidateToken()
        })
    },
    checkTokenValidity() {
        return checkTokenValidity(readToken())
    },
}