<template>
  <div class="antenna">
    <div class="antenna">
      <form novalidate class="md-layout p-4" @submit.prevent="validateAntenna">
        <widget :headerText="'Informations de l\'antenne ' + antennaName">
          <div class="md-layout md-gutter">
            <div
              class="md-layout-item md-small-size-100"
              style="max-width: 315px"
            >
              <md-field>
                <label for="antenna-choice">Choix de l'antenne</label>
                <md-select
                  v-model="antennaChoice"
                  name="antenna-choice"
                  id="antenna-choice"
                >
                  <md-option
                    v-if="user.rights.indexOf('antennas_absolute') > 0"
                    value="new"
                    >Nouvelle Antenne</md-option
                  >
                  <md-option
                    :disabled="isDisabled(a)"
                    v-for="(a, i) in antennas"
                    :key="i"
                    :value="i"
                    >{{ a.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>

          <md-subheader>Contact</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('name')">
                <label for="center-name">Centre</label>
                <md-input
                  name="center-name"
                  id="center-name"
                  v-model="currentAntenna.name"
                />
                <span class="md-error" v-if="!$v.currentAntenna.name.required"
                  >Le nom du centre est requis</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.currentAntenna.name.minlength"
                  >Nom trop court</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('tel')">
                <label for="center-tel">Téléphone</label>
                <md-input
                  name="center-tel"
                  id="center-tel"
                  v-model="currentAntenna.tel"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="center-tel2">Téléphone 2</label>
                <md-input
                  name="center-tel2"
                  id="center-tel2"
                  v-model="currentAntenna.tel2"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="center-fax">Fax</label>
                <md-input
                  name="center-fax"
                  id="center-fax"
                  v-model="currentAntenna.fax"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('email')">
                <label for="center-email">Email</label>
                <md-input
                  name="center-email"
                  id="center-email"
                  v-model="currentAntenna.email"
                />
                <a
                  v-if="currentAntenna.email"
                  :href="`mailto:${currentAntenna.email}`"
                  ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
                ></a>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('address.street')">
                <label for="center-street">Adresse</label>
                <md-input
                  name="center-street"
                  id="center-street"
                  v-model="currentAntenna.address.street"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentAntenna.address.street.required"
                  >L'adresse est requise</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field :class="getValidationClass('address.postal_code')">
                <label for="center-postal_code">Code postal</label>
                <md-input
                  name="center-postal_code"
                  id="center-postal_code"
                  v-model="currentAntenna.address.postal_code"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentAntenna.address.postal_code.required"
                  >L'adresse est requise</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field :class="getValidationClass('address.city')">
                <label for="center-city">Ville</label>
                <md-input
                  name="center-city"
                  id="center-city"
                  v-model="currentAntenna.address.city"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentAntenna.address.city.required"
                  >L'adresse est requise</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                ><label for="center-access">Accès</label>
                <md-input
                  name="center-access"
                  id="center-access"
                  v-model="currentAntenna.access"
              /></md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                ><label for="center-comment">Remarque</label>
                <md-input
                  name="center-comment"
                  id="center-comment"
                  v-model="currentAntenna.comment"
              /></md-field>
            </div>
          </div>
          <md-subheader>Agrément</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-20">
              <md-field
                ><label for="agreement_num">Numéro d'agrément</label>
                <md-input
                  name="agreement_num"
                  id="agreement_num"
                  v-model="currentAntenna.agreement_num"
              /></md-field>
            </div>
            <div class="md-layout-item md-small-size-20">
              <md-field
                ><label for="agreement_pref">Préfecture d'agrément</label>
                <md-input
                  name="agreement_pref"
                  id="agreement_pref"
                  v-model="currentAntenna.agreement_pref"
              /></md-field>
            </div>
            <div class="md-layout-item d-flex align-items-center">
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Date de début d'agrément :
                  <vuestic-date-picker
                    v-model="currentAntenna.agreement_start"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
            <div class="md-layout-item d-flex align-items-center">
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Date de fin d'agrément :
                  <vuestic-date-picker
                    v-model="currentAntenna.agreement_end"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
          </div>
          <md-subheader>Horaires</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item horaire">
              <md-field
                ><label for="center-opening-monday">Lundi</label>
                <md-input
                  name="center-opening-monday"
                  id="center-opening-mondays"
                  v-model="currentAntenna.opening.monday"
              /></md-field>
            </div>
            <div class="md-layout-item horaire">
              <md-field
                ><label for="center-opening-tuesday">Mardi</label>
                <md-input
                  name="center-opening-tuesday"
                  id="center-opening-tuesday"
                  v-model="currentAntenna.opening.tuesday"
              /></md-field>
            </div>
            <div class="md-layout-item horaire">
              <md-field
                ><label for="center-opening-wednesday">Mercredi</label>
                <md-input
                  name="center-opening-wednesday"
                  id="center-opening-wednesday"
                  v-model="currentAntenna.opening.wednesday"
              /></md-field>
            </div>
            <div class="md-layout-item horaire">
              <md-field
                ><label for="center-opening-thursday">Jeudi</label>
                <md-input
                  name="center-opening-thursday"
                  id="center-opening-thursday"
                  v-model="currentAntenna.opening.thursday"
              /></md-field>
            </div>
            <div class="md-layout-item horaire">
              <md-field
                ><label for="center-opening-friday">Vendredi</label>
                <md-input
                  name="center-opening-friday"
                  id="center-opening-friday"
                  v-model="currentAntenna.opening.friday"
              /></md-field>
            </div>
            <div class="md-layout-item horaire">
              <md-field
                ><label for="center-opening-saturday">Samedi</label>
                <md-input
                  name="center-opening-saturday"
                  id="center-opening-saturday"
                  v-model="currentAntenna.opening.saturday"
              /></md-field>
            </div>
            <div class="md-layout-item horaire">
              <md-field
                ><label for="center-opening-sunday">Dimanche</label>
                <md-input
                  name="center-opening-sunday"
                  id="center-opening-sunday"
                  v-model="currentAntenna.opening.sunday"
              /></md-field>
            </div>
          </div>

          <button
            class="btn"
            :class="modifMade ? 'btn-primary' : 'btn-pale'"
            type="submit"
          >
            Enregistrer
          </button>
        </widget>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import Widget from "../widgets/Widget.vue";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { antennaTemplate } from "./antennaTemplate.js";
export default {
  name: "antenna",
  components: { Widget, VuesticDatePicker },
  mixins: [validationMixin],
  data() {
    return {
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      antennaChoice: "new",
      currentAntenna: antennaTemplate,
      originAntenna: antennaTemplate,
      antennas: [],
    };
  },
  validations() {
    let val = {
      currentAntenna: {
        name: {
          required,
          minLength: minLength(4),
        },
        address: {
          street: {
            required,
          },
          postal_code: {
            required,
          },
          city: {
            required,
          },
        },
      },
    };
    return val;
  },
  computed: {
    ...mapState(["user"]),
    isNewAntenna() {
      return this.originAntenna ? this.originAntenna._id == undefined : true;
    },
    antennaName() {
      return this.isNewAntenna ? "à créer" : this.currentAntenna.name;
    },
    modifMade() {
      return !this._.isEqual(this.originAntenna, this.currentAntenna);
    },
  },
  mounted() {
    this.refreshAntennas();
  },
  watch: {
    originAntenna() {
      if (this.originAntenna.opening == undefined) {
        this.originAntenna.opening = {};
      }
      this.currentAntenna = this._.cloneDeep(this.originAntenna);
    },
    antennaChoice() {
      if (this.antennaChoice == "new") {
        this.originAntenna = antennaTemplate;
      } else {
        this.originAntenna = this.antennas[this.antennaChoice];
      }
    },
  },
  methods: {
    isDisabled(antenna) {
      if (this.user.rights.indexOf("antennas_absolute") > 0) {
        return false;
      } else {
        return !(antenna._id == this.user.antenna_id);
      }
    },
    refreshAntennas() {
      axios
        .get("/api/web/antennas")
        .then((res) => {
          this.antennas = res.data.data.filter((a) => a.acr == undefined);
          if (this.antennas.length > 0) {
            this.originAntenna = this.antennas[0];
            this.antennaChoice = 0;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.currentAntenna[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    createAntenna() {
      let body = {
        antenna: this.currentAntenna,
      };
      axios
        .put("/api/web/antenna/new", body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Antenne enregistrée").goAway(1500);
            this.refreshAntennas();
          } else {
            this.$toasted.error("Antenne non enregistrée".goAway(1500));
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toasted.error("Antenne non enregistrée").goAway(1500);
        });
    },
    saveAntenna() {
      let body = {
        current: this.currentAntenna,
        origin: this.originAntenna,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/antenna/${this.originAntenna._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Antenne enregistrée").goAway(1500);
            this.refreshAntennas();
          } else {
            this.$toasted.error("Antenne non enregistrée".goAway(1500));
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toasted.error("Antenne non enregistrée").goAway(1500);
        });
    },
    validateAntenna() {
      if (this.modifMade) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.sending = true;
          if (this.currentAntenna._id === undefined) {
            this.createAntenna();
          } else {
            this.saveAntenna();
          }
        } else {
          this.$toasted
            .error("Tous les champs nécessaires ne sont pas remplis")
            .goAway(2000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-subheader {
  font-size: 1.2rem;
}

.horaire {
  .md-input {
    max-width: 120px;
  }
}
</style>