<template>
  <div class="siege-fiche pl-2 pt-4 pr-4">
    <widget v-if="siege != null" headerText="Informations du siège">
      <div class="d-flex">
        <div class="infos">
          <div class="d-flex">{{ siege.object }}</div>
          <div class="pl-4 pr-4">
            <div class="address d-flex justify-content-between pb-1">
              <div class="pr-1">Adresse :</div>
              <div>
                <div>{{ siege.address.street }}</div>
                <div>
                  {{ siege.address.postal_code + "  " + siege.address.city }}
                </div>
              </div>
            </div>
            <div class="address d-flex justify-content-between pb-1">
              <div class="pr-1">Téléphone :</div>
              <div>{{ " " + telFormatter(siege.tel) }}</div>
            </div>
            <div class="address d-flex justify-content-between pb-1">
              <div class="pr-1">Téléphone 2 :</div>
              <div>
                {{ " " + (siege.tel2 ? telFormatter(siege.tel2) : "") }}
              </div>
            </div>
            <div class="address d-flex justify-content-between pb-1">
              <div class="pr-1">Email :</div>
              <div>{{ " " + siege.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </widget>
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import axios from "axios";
import { telFormatter } from "../../services/utils";
export default {
  name: "siege-fiche",
  components: { Widget },
  data() {
    return {
      siege: {
        address: {},
        opening: {},
      },
      boss: {},
    };
  },
  computed: {},
  mounted() {
    this.refreshsiege();
  },
  methods: {
    telFormatter(tel) {
      return telFormatter(tel);
    },
    refreshsiege() {
      axios.get(`/api/web/siege`).then((res) => {
        this.siege = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss">
.infos {
  flex: 2;
}
.widget {
  height: 100%;
  width: 100%;
}
.info-wrapper {
  max-width: 250px;

  .address {
    justify-content: space-between;
  }
}
.horaires {
  flex: 1;
}
</style>