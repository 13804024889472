<template>
  <vuestic-modal
    class="contact-modal"
    ref="recipientContactModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
    ><div slot="title">
      <span class="center">{{ title }}</span>
    </div>
    <contact-form :recipient_id="recipient_id" ref="contactSimple" />
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import ContactForm from "../contacts/ContactForm.vue";
export default {
  name: "contact-modal",
  components: { VuesticModal, ContactForm },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      title: "",
    };
  },
  methods: {
    open(contact) {
      this.$refs.recipientContactModal.open();
      this.title = contact._id ? "Modification du contact" : "Nouveau contact";
      this.$refs.contactSimple.open(contact);
    },
    resetForm() {
      this.$refs.contactSimple.resetForm();
    },
    okAction() {
      return this.$refs.contactSimple.okAction();
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.contact-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>