<template>
  <vuestic-modal
    class="resource-modal"
    ref="resourceModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ title }}</span>
    </div>
    <form novalidate @submit.prevent="addResource">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('label')">
            <label for="resource-label">Ressource</label>
            <md-select
              name="resource-label"
              id="resource-label"
              v-model="currentResource.label"
            >
              <md-option
                v-for="(p, i) in [
                  'Salaire',
                  'Solidarité Transport',
                  'RSA',
                  'ARE',
                  'AAH',
                  'Allocation logement',
                  'Pension',
                  'CEJ',
                  'Autre',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentResource.label.required"
              >Le nom de la ressource est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('status')">
            <label for="resource-status">Statut</label>
            <md-select
              name="resource-status"
              id="resource-status"
              v-model="currentResource.status"
            >
              <md-option
                v-for="(p, i) in ['Oui', 'Non', 'En cours de demande']"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentResource.status.required"
              >Le status est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field>
            <label for="resource-salary">Montant(€)</label>
            <md-input
              name="resource-salary"
              id="resource-salary"
              v-model="currentResource.amount"
              type="number"
            />
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="resource-comment"
            v-model="currentResource.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
    <h4 class="p-4 d-flex">Lier un contact</h4>
    <contact-linker
      ref="contactLinker"
      :recipient_id="recipient_id"
      form_ref="resourceLinker"
      :contact_ids="currentResource.contact_ids"
      @change="changeContact"
    />
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ContactLinker from "../contacts/ContactLinker.vue";
export default {
  name: "resource-modal",
  components: { VuesticModal, ContactLinker },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      originResource: {},
      currentResource: {
        contact_ids: [],
      },
      resource: {},
    };
  },
  validations: {
    currentResource: {
      label: {
        required,
      },
      status: {
        required,
      },
    },
  },
  computed: {
    title() {
      return this.resource._id
        ? "Modifier la ressource"
        : "Ajouter une ressource";
    },
  },
  methods: {
    refreshResource() {
      if (this.resource._id) {
        axios.get(`/api/web/resource/${this.resource._id}`).then((res) => {
          this.originResource = this._.cloneDeep(res.data.data);
          this.currentResource = this._.cloneDeep(this.originResource);
        });
      } else {
        this.originResource = {};
        this.currentResource = {
          contact_ids: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentResource[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentResource[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(resource) {
      this.$refs.resourceModal.open();
      this.resource = resource;
      this.refreshResource();
      this.$refs.contactLinker.open();
    },
    resetForm() {
      this.currentResource = {
        contact_ids: [],
      };
      this.originResource = {};
    },
    formatResource(resource) {
      resource.amount = resource.amount
        ? parseFloat(resource.amount)
        : resource.amount;
      return resource;
    },
    createResource() {
      let body = {
        resource: this.formatResource(this.currentResource),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/resources`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "resource");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    saveResource() {
      let body = {
        current: this.formatResource(this.currentResource),
        origin: this.originResource,
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/resource/${this.resource._id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Ressources mises à jour").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "resource");
        } else {
          this.$toasted.error("Erreur inconnue").goAway(1500);
        }
      });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.resource._id) {
          this.saveResource();
        } else {
          this.createResource();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Ressources non mises à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
    changeContact(payload) {
      this.currentResource.contact_ids = payload;
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.resource-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>