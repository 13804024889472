<template>
  <vuestic-modal
    class="meeting-modal"
    ref="meetingModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ getTitle }}</span>
    </div>
    <form novalidate @submit.prevent="addMeeting">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <object-auto-complete
            :inputClass="getValidationClass('user_id')"
            v-model="currentMeeting.user_id"
            :options="users"
            field_of_value="_id"
            label="Fait par"
            ><span class="md-error" v-if="!$v.currentMeeting.user_id.required"
              >L'accueillant est requis</span
            >
          </object-auto-complete>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('presence')">
            <label for="meeting_presence">Présent</label>
            <md-select
              v-model="currentMeeting.presence"
              name="meeting_presence"
              id="meeting_presence"
              ><md-option
                v-for="(a, i) in ['Oui', 'Excusé', 'Non']"
                :key="i"
                :value="a"
              >
                {{ a }}
              </md-option></md-select
            >
            <span class="md-error" v-if="!$v.currentMeeting.presence.required"
              >Information nécessaire</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="meeting_type">Type d'entretien</label>
            <md-select
              v-model="currentMeeting.type"
              name="meeting_type"
              id="meeting_type"
              ><md-option
                v-for="(a, i) in ['Présentiel', 'Téléphonique', 'Visio']"
                :key="i"
                :value="a"
              >
                {{ a }}
              </md-option></md-select
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-checkbox
            v-model="currentMeeting.sid"
            :value="true"
            class="md-primary mt-4"
            >En détention</md-checkbox
          >
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field :class="getValidationClass('date')" class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date :
              <vuestic-date-picker
                v-model="currentMeeting.date"
                :config="dateConfig"
              />
            </div>
            <span class="md-error" v-if="!$v.currentMeeting.date.required"
              >La date est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Prochain entretien :
              <vuestic-date-picker
                v-model="currentMeeting.next_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <md-subheader>Thèmes abordés</md-subheader>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-checkbox
            v-model="currentMeeting.housing"
            :value="true"
            class="md-primary mt-2"
            >Hébergement</md-checkbox
          >
        </div>
        <div class="md-layout-item">
          <md-checkbox
            v-model="currentMeeting.resources"
            :value="true"
            class="md-primary mt-2"
            >Ressources</md-checkbox
          >
        </div>
        <div class="md-layout-item">
          <md-checkbox
            v-model="currentMeeting.work"
            :value="true"
            class="md-primary mt-2"
            >Emploi</md-checkbox
          >
        </div>
        <div class="md-layout-item">
          <md-checkbox
            v-model="currentMeeting.health"
            :value="true"
            class="md-primary mt-2"
            >Santé</md-checkbox
          >
        </div>
        <div class="md-layout-item">
          <md-checkbox
            v-model="currentMeeting.admin"
            :value="true"
            class="md-primary mt-2"
            >Administratif</md-checkbox
          >
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="meeting-comment"
            v-model="currentMeeting.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
      <md-subheader>Aides</md-subheader>
      <b-table
        v-if="currentMeeting.helps && currentMeeting.helps.length > 0"
        striped
        hover
        :fields="fields"
        :items="currentMeeting.helps"
      >
        <template #cell(actions)="row">
          <ion-icon
            name="trash"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Supprimer"
            @click="askDelete(row.index)"
          ></ion-icon>
        </template>
      </b-table>
      <md-checkbox v-model="isInBase" :value="true" class="md-primary mt-2"
        >Aide depuis la base</md-checkbox
      >

      <div v-if="isInBase" class="md-layout md-gutter">
        <div class="md-layout-item md-size-40">
          <md-field>
            <label for="selected_help">Aide à rajouter</label>
            <md-select
              name="selected_help"
              id="selected_help"
              v-model="selectedHelp"
            >
              <md-option v-for="(h, i) in helps" :key="i" :value="i">
                {{ h.label }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-20">
          <md-field>
            <label for="help_quantity">Quantité</label>
            <md-input
              name="help_quantity"
              id="help_quantity"
              v-model="helpQuantity"
              type="number"
            />
          </md-field>
        </div>
        <button
          class="btn"
          :class="selectedHelp == null ? 'btn-pale' : 'btn-secondary'"
          type="button"
          @click="addHelp"
        >
          Ajouter une aide
        </button>
      </div>
      <div v-else class="md-layout md-gutter">
        <div class="md-layout-item md-size-20">
          <md-field>
            <label for="selected_help">Label de l'aide</label>
            <md-input
              name="selected_help"
              id="selected_help"
              v-model="helpLabel"
            >
            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-20">
          <md-field>
            <label for="help_price">Prix unitaire</label>
            <md-input
              name="help_price"
              id="help_price"
              v-model="helpPrice"
              type="number"
            />
          </md-field>
        </div>
        <div class="md-layout-item md-size-20">
          <md-field>
            <label for="help_quantity">Quantité</label>
            <md-input
              name="help_quantity"
              id="help_quantity"
              v-model="helpQuantity"
              type="number"
            />
          </md-field>
        </div>
        <button
          class="btn"
          :class="selectedHelp == null ? 'btn-pale' : 'btn-secondary'"
          type="button"
          @click="addHelp"
        >
          Ajouter une aide
        </button>
      </div>
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import axios from "axios";
import { mapState } from "vuex";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "meeting-modal",
  components: { VuesticModal, VuesticDatePicker, ObjectAutoComplete },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isInBase: true,
      users: [],
      helps: [],
      helpLabel: "",
      helpPrice: 0,
      selectedHelp: null,
      currentMeeting: {},
      originMeeting: {},
      meeting: {},
      helpQuantity: null,
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      fields: [
        { key: "label", label: "Libellé" },
        { key: "quantity", label: "Quantité" },
        {
          key: "total_price",
          label: "Prix",
          formatter: (val) => val.toString() + " €",
        },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  validations() {
    const isDate = (val) => this.$moment(val).isValid();
    return {
      currentMeeting: {
        date: {
          required,
          isDate,
        },
        user_id: {
          required,
        },
        presence: { required },
      },
    };
  },
  mounted() {
    this.refreshLists();
  },
  computed: {
    ...mapState(["user"]),
    getTitle() {
      return this.meeting._id == undefined
        ? "Ajouter un entretien"
        : "Modification de l'entretien";
    },
  },
  methods: {
    refreshMeeting() {
      if (this.meeting._id) {
        axios.get(`/api/web/meeting/${this.meeting._id}`).then((res) => {
          this.originMeeting = this._.cloneDeep(res.data.data);
          this.currentMeeting = this._.cloneDeep(this.originMeeting);
        });
      } else {
        this.originMeeting = {
          helps: [],
        };
        this.currentMeeting = {
          user_id: this.user._id,
          date: this.$moment().format("YYYY-MM-DD"),
          next_date: this.$moment().add(7, "days").format("YYYY-MM-DD"),
          helps: [],
        };
      }
    },
    refreshLists() {
      axios.get(`/api/web/helps`).then((res) => {
        this.helps = res.data.data;
        this.helps = this.helps.filter(
          (h) => h.archived == false || h.archived == undefined
        );
      });
      if (
        this.user.habilitation == "volunteer" ||
        this.user.habilitation == "antenna_admin"
      ) {
        axios
          .get(`/api/web/users/antenna/${this.user.antenna_id}`)
          .then((res) => {
            this.users = res.data.data;
          });
      } else {
        axios.get(`/api/web/users`).then((res) => {
          this.users = res.data.data;
        });
      }
    },
    addHelp() {
      if (isNaN(this.helpQuantity)) {
        this.$toasted.show("Quantité d'aide invalide").goAway(2000);
      } else {
        if (this.isInBase) {
          let quant = parseFloat(this.helpQuantity).toFixed();
          let help = {
            label: this.helps[this.selectedHelp].label,
            category: this.helps[this.selectedHelp].category,
            quantity: quant,
            u_price: parseFloat(
              this.helps[this.selectedHelp].price.replace(",", ".")
            ),
            total_price:
              Math.round(
                quant *
                  parseFloat(
                    this.helps[this.selectedHelp].price.replace(",", ".")
                  ) *
                  100
              ) / 100,
          };
          this.currentMeeting.helps.push(help);
          (this.selectedHelp = null), (this.helpQuantity = 0);
        } else {
          let quant = parseFloat(this.helpQuantity).toFixed();
          let price = parseFloat(this.helpPrice.replace(",", ".")).toFixed(2);
          let help = {
            label: this.helpLabel,
            u_price: price,
            category: "Divers",
            quantity: quant,
            total_price: Math.round(quant * price * 100) / 100,
          };
          this.currentMeeting.helps.push(help);
          (this.helpPrice = 0), (this.helpQuantity = 0), (this.helpLabel = "");
        }
      }
    },
    askDelete(index) {
      this.currentMeeting.helps.splice(index, 1);
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentMeeting[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentMeeting[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    resetForm() {
      this.currentMeeting = {
        helps: [],
      };
      this.originMeeting = {
        helps: [],
      };
      this.selectedHelp = null;
    },
    open(meeting) {
      this.$refs.meetingModal.open();
      this.meeting = meeting;
      this.refreshLists();
      this.refreshMeeting();
    },
    formatMeeting(meeting) {
      return meeting;
    },
    createMeeting() {
      let body = {
        meeting: this.formatMeeting(this.currentMeeting),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/meetings`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Entretiens mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "meeting");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    saveMeeting() {
      let body = {
        current: this.formatMeeting(this.currentMeeting),
        origin: this.formatMeeting(this.originMeeting),
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/meeting/${this.meeting._id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Entretien mis à jour").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "meeting");
        } else {
          this.$toasted.error("Erreur inconnue").goAway(1500);
        }
      });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.meeting._id) {
          this.saveMeeting();
        } else {
          this.createMeeting();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Entretiens non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
  },
};
</script>

<style>
</style>