<template>
  <div class="help">
    <form novalidate class="md-layout p-4" @submit.prevent="validateHelp">
      <widget headerText="Informations sur l'aide">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('label')">
              <label for="label">Libellé</label>
              <md-input name="label" id="label" v-model="currentHelp.label" />
              <span class="md-error" v-if="!$v.currentHelp.label.required"
                >Le libellé est requis</span
              >
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="category">Catégorie</label>
              <md-select
                name="category"
                id="category"
                v-model="currentHelp.category"
              >
                <md-option
                  v-for="(a, i) in [
                    'Alimentaire',
                    'Transport',
                    'Communication',
                    'Divers',
                  ]"
                  :key="i"
                  :value="a"
                  >{{ a }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="price">Prix</label>
              <md-input name="price" id="price" v-model="currentHelp.price" />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-checkbox
              v-model="currentHelp.archived"
              :value="true"
              class="md-primary mt-4"
              >Archivé</md-checkbox
            >
          </div>
        </div>
        <div
          v-if="user.rights.indexOf('helps_management') > 0"
          class="d-flex justify-content-center"
        >
          <button class="btn" :class="'btn-primary'" type="submit">
            Enregistrer
          </button>
          <div class="p-2" />
          <button
            v-if="currentHelp._id"
            type="button"
            @click="askDelete"
            :class="'btn btn-pale'"
          >
            Supprimer
          </button>
        </div>
      </widget>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "help",
  components: { Widget },
  mixins: [validationMixin],
  data() {
    return {
      helpId: this.$route.params.help_id,
      originHelp: {},
      currentHelp: {},
    };
  },
  validations() {
    return {
      currentHelp: {
        label: required,
      },
    };
  },
  mounted() {
    this.refreshHelp();
  },
  watch: {
    helpId() {
      this.refreshHelp();
    },
    originHelp() {
      this.currentHelp = this._.cloneDeep(this.originHelp);
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.currentHelp[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshHelp() {
      if (this.helpId != "new")
        axios.get(`/api/web/help/${this.helpId}`).then((res) => {
          this.originHelp = this._.cloneDeep(res.data.data);
        });
      else {
        this.originHelp = {};
      }
    },
    formatHelp(help) {
      if (help.price instanceof String) {
        help.price = parseFloat(help.price.replace(",", "."));
      }
      if (help.archived == null) {
        help.archived = false;
      }
      return help;
    },
    createHelp() {
      let body = {
        help: this.formatHelp(this.currentHelp),
      };
      axios.put(`/api/web/help/new`, body).then(() => {
        this.$toasted.show("L'aide a été créée").goAway(2000);
        this.$router.push("/helps");
      });
    },
    updateHelp() {
      let body = {
        current: this.formatHelp(this.currentHelp),
        origin: this.formatHelp(this.originHelp),
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/help/${this.helpId}`, body).then(() => {
        this.$toasted.show("L'aide a été modifiée").goAway(2000);
        this.$router.push("/helps");
      });
    },
    validateHelp() {
      if (this.user.rights.indexOf("helps_management") > 0) {
        this.$v.$touch();

        if (!this.$v.invalid) {
          if (this.currentHelp._id == undefined) {
            this.createHelp();
          } else {
            this.updateHelp();
          }
        }
      } else {
        this.$toasted.show("Vous n'avez pas les droits").goAway(2000);
      }
    },
    askDelete() {
      this.$toasted
        .show("Souhaitez-vous supprimer cette aide", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(this.helpId);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(help_id) {
      axios
        .delete(`/api/web/help/${help_id}`)
        .then(() => {
          this.$toasted.show("L'aide a été supprimée").goAway(1500);
          this.$router.push("/helps");
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>