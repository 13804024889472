<template>
  <div>
    <md-autocomplete
      v-model="formattedValue"
      :md-options="formattedList"
      @md-selected="selectedValue"
      :md-fuzzy-search="false"
      :class="inputClass"
      v-bind="$attrs"
    >
      <label>{{ this.label }}</label>
      <template slot="md-autocomplete-item" slot-scope="{ item, term }">
        <md-highlight-text :md-term="term">{{
          item
        }}</md-highlight-text></template
      >
      <slot></slot>
    </md-autocomplete>
  </div>
</template>

<script>
export default {
  name: "object-auto-complete",
  props: {
    value: {
      required: true,
    },
    formatter: {
      type: Function,
      default: (item) => {
        return (
          (item.surname ? item.surname : "") +
          " " +
          (item.name ? item.name.toUpperCase() : "")
        );
      },
    },
    options: {
      required: true,
      type: Array,
    },
    field_of_value: {
      required: true,
      type: String,
    },
    label: {
      type: String,
      default: () => {
        return "";
      },
    },
    inputClass: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      formattedValue: "",
      formattedList: [],
    };
  },
  watch: {
    value(newVal) {
      this.changeFormattedValue(newVal);
    },
    options(newOptions) {
      this.formattedList = this._.cloneDeep(newOptions).map(this.formatter);
      this.changeFormattedValue(this.value);
    },
  },
  mounted() {
    this.changeFormattedValue(this.value);
  },
  methods: {
    changeFormattedValue(value) {
      var options = this._.cloneDeep(this.options);
      options = options.filter((a) => a[this.field_of_value] == value);
      if (options.length === 0) {
        this.formattedValue = "";
      } else {
        this.formattedValue = this.formatter(options[0]);
      }
    },
    selectedValue(value) {
      var options = this._.cloneDeep(this.options);
      options = options.filter((a) => this.formatter(a) == value);
      if (options.length === 0) {
        console.log("error");
      } else {
        this.$emit("input", options[0][this.field_of_value]);
      }
    },
  },
};
</script>

<style lang="scss">
</style>