<template>
  <vuestic-modal
    class="qualification-modal"
    ref="qualificationModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ title }}</span>
    </div>
    <form novalidate @submit.prevent="addQualification">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('domain')">
            <label for="qualification_domain">Domaine</label>
            <md-select
              name="qualification_domain"
              id="qualification_domain"
              v-model="currentQualification.domain"
            >
              <md-option v-for="(r, i) in domains" :key="i" :value="r._id">
                {{ r.label }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="!$v.currentQualification.domain.required"
              >La Domaine de la qualification est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="qualification-year">Année</label>
            <md-input
              name="qualification-year"
              id="qualification-year"
              v-model="currentQualification.year"
            />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('level')">
            <label for="qualification-level">Niveau</label>
            <md-select
              name="qualification-level"
              id="qualification-level"
              v-model="currentQualification.level"
            >
              <md-option
                v-for="(p, i) in [
                  'Non précisé',
                  '< BEPC',
                  'BEPC',
                  'CAP',
                  'BAC professionnel',
                  'BAC général',
                  'BTS',
                  'BAC +',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="!$v.currentQualification.level.required"
              >Le niveau est requis</span
            >
          </md-field>
        </div>
      </div>
      <md-checkbox
        v-model="currentQualification.professionnal"
        :value="true"
        class="md-primary mt-2"
        >Professionnelle</md-checkbox
      >
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="qualification-comment"
            v-model="currentQualification.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "qualification-modal",
  components: { VuesticModal },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      domains: [],
      originQualification: {},
      currentQualification: {},
      qualification: {},
    };
  },
  validations: {
    currentQualification: {
      domain: {
        required,
      },
      level: {
        required,
      },
    },
  },
  computed: {
    title() {
      return this.qualification._id
        ? "Modifier la qualification"
        : "Ajouter une qualification";
    },
  },
  methods: {
    refreshLists() {
      axios.get(`/api/web/choices/qualification_domain`).then((res) => {
        this.domains = res.data.data;
      });
    },
    refreshQualification() {
      if (this.qualification._id) {
        axios
          .get(`/api/web/qualification/${this.qualification._id}`)
          .then((res) => {
            this.originQualification = this._.cloneDeep(res.data.data);
            this.currentQualification = this._.cloneDeep(
              this.originQualification
            );
          });
      } else {
        this.originQualification = {};
        this.currentQualification = {};
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentQualification[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentQualification[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(qualification) {
      this.$refs.qualificationModal.open();
      this.qualification = qualification;
      this.refreshLists();
      this.refreshQualification();
    },
    resetForm() {
      this.currentQualification = {};
      this.originQualification = {};
    },
    createQualification() {
      let body = {
        qualification: this.formatQualification(this.currentQualification),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/qualifications`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "qualification");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    formatQualification(qualification) {
      return qualification;
    },
    saveQualification() {
      let body = {
        current: this.formatQualification(this.currentQualification),
        origin: this.originQualification,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/qualification/${this.qualification._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "qualification");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.qualification._id) {
          this.saveQualification();
        } else {
          this.createQualification();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Parcours non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.qualification-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>