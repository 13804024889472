<template>
  <div class="siege">
    <div class="siege">
      <form
        novalidate
        class="md-layout pl-4 pr-4 pb-4"
        @submit.prevent="validateSiege"
      >
        <widget :headerText="'Informations du siège'">
          <md-subheader>Informations sur l'association</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-10">
              <md-field>
                <label for="siege-acr">Acronyme</label>
                <md-input
                  name="siege-acr"
                  id="siege-acr"
                  v-model="currentSiege.acr"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-name">Nom</label>
                <md-input
                  name="siege-name"
                  id="siege-name"
                  v-model="currentSiege.long_name"
                />
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-object">Objet</label>
                <md-input
                  name="siege-object"
                  id="siege-object"
                  v-model="currentSiege.object"
                />
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-ape">Code APE</label>
                <md-input
                  name="siege-ape"
                  id="siege-ape"
                  v-model="currentSiege.ape"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-siret">SIRET</label>
                <md-input
                  name="siege-siret"
                  id="siege-siret"
                  v-model="currentSiege.siret"
                />
              </md-field>
            </div>
          </div>
          <md-subheader>Contact</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-tel">Téléphone</label>
                <md-input
                  name="siege-tel"
                  id="siege-tel"
                  v-model="currentSiege.tel"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-tel2">Téléphone 2</label>
                <md-input
                  name="siege-tel2"
                  id="siege-tel2"
                  v-model="currentSiege.tel2"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-fax">Fax</label>
                <md-input
                  name="siege-fax"
                  id="siege-fax"
                  v-model="currentSiege.fax"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-email">Email</label>
                <md-input
                  name="siege-email"
                  id="siege-email"
                  v-model="currentSiege.email"
                />
                <a
                  v-if="currentSiege.email"
                  :href="`mailto:${currentSiege.email}`"
                  ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
                ></a>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="siege-street">Adresse</label>
                <md-input
                  name="siege-street"
                  id="siege-street"
                  v-model="currentSiege.address.street"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field>
                <label for="siege-postal_code">Code postal</label>
                <md-input
                  name="siege-postal_code"
                  id="siege-postal_code"
                  v-model="currentSiege.address.postal_code"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field>
                <label for="siege-city">Ville</label>
                <md-input
                  name="siege-city"
                  id="siege-city"
                  v-model="currentSiege.address.city"
                />
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                ><label for="siege-access">Accès</label>
                <md-input
                  name="siege-access"
                  id="siege-access"
                  v-model="currentSiege.access"
              /></md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <b-form-textarea
                label="Remarque"
                id="siege-comment"
                v-model="currentSiege.comment"
                rows="3"
                max-rows="3"
              />
            </div>
          </div>

          <button
            class="btn"
            :class="siegeModifMade ? 'btn-primary' : 'btn-pale'"
            type="submit"
          >
            Enregistrer
          </button>
        </widget>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Widget from "../widgets/Widget.vue";
export default {
  name: "siege",
  components: { Widget },
  data() {
    return {
      currentSiege: { address: {} },
      originSiege: { address: {} },
      Sieges: [],
    };
  },
  computed: {
    ...mapState(["user"]),
    siegeModifMade() {
      return !this._.isEqual(this.originSiege, this.currentSiege);
    },
  },
  mounted() {
    this.refreshSiege();
  },
  watch: {
    originSiege() {
      if (this.originSiege.address == undefined) {
        this.originSiege.address = {};
      }
      this.currentSiege = this._.cloneDeep(this.originSiege);
    },
  },
  methods: {
    refreshSiege() {
      axios
        .get("/api/web/siege")
        .then((res) => {
          this.originSiege = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    saveSiege() {
      let body = {
        current: this.currentSiege,
        origin: this.originSiege,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/siege`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Modifications enregistrées").goAway(1500);
            this.refreshSiege();
          } else {
            this.$toasted.error("Modifications non enregistrée".goAway(1500));
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toasted.error("Modifications non enregistrée").goAway(1500);
        });
    },
    validateSiege() {
      if (this.siegeModifMade) {
        this.saveSiege();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-subheader {
  font-size: 1.2rem;
}

.horaire {
  .md-input {
    max-width: 120px;
  }
}
</style>