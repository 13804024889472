<template>
  <div class="vuestic-file-upload-undo">
    {{ "Supprimé" }}
    <button
      type="button"
      class="btn-text btn-text--primary"
      @click="$emit('recover')"
    >
      {{ "Défaire" }}?
    </button>
  </div>
</template>

<script>
export default {
  name: "vuestic-file-upload-undo",
};
</script>
