<template>
  <div class="recipient-detentions">
    <div class="list-dom pt-2 pl-4 pr-4">
      <widget headerText="Liste des suivis">
        <b-table
          v-if="follows.length > 0"
          striped
          hover
          :items="follows"
          :fields="followFields"
          :tbody-tr-class="followClass"
        >
          <template #cell(actions)="row">
            <ion-icon
              name="pencil"
              v-b-tooltip.hover
              style="cursor: pointer"
              title="Modifier les informations"
              @click="modifyFollow(row.item)"
            ></ion-icon>
            <ion-icon
              name="trash"
              v-b-tooltip.hover
              style="cursor: pointer"
              title="Supprimer le suivi"
              @click="askDeleteFollow(row.item)"
            ></ion-icon> </template
        ></b-table>
        <button class="btn btn-primary" type="button" @click="addFollow">
          Ajouter un suivi
        </button>
      </widget>
    </div>
    <div class="sid-info pt-2 pl-4 pr-4">
      <widget headerText="Information complémentaires">
        <div class="d-flex justify-content-around align-items-center">
          <div class="md-layout-item md-size-30">
            <md-field>
              <label for="previous-detention">Condamnations antérieures</label>
              <md-input
                name="previous-detention"
                id="previous-detention"
                type="number"
                v-model="recipient.condamnations"
                @input="changeCondamnations"
              />
            </md-field>
          </div>
          <md-switch v-model="sid" class="md-primary mt-2" @change="changeSid"
            >Suivi initié en détention</md-switch
          >
        </div>
        <div v-if="sid">
          <div class="d-flex">
            <form novalidate @submit.prevent="validateSid">
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-20">
                  <md-field>
                    <label for="sid-number">Écrou n°</label>
                    <md-input
                      name="sid-number"
                      id="sid-number"
                      v-model="sid_infos.number"
                    />
                  </md-field>
                </div>
              </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item">
                  <md-field :class="getValidationClass('place')">
                    <label for="place">Lieu de détention</label>
                    <md-select
                      name="place"
                      id="place"
                      v-model="sid_infos.place"
                    >
                      <md-option
                        v-for="(p, i) in detention_places"
                        :key="i"
                        :value="p._id"
                      >
                        {{ p.label }}
                      </md-option>
                    </md-select>
                    <span class="md-error" v-if="!$v.sid_infos.place.required"
                      >Le lieu est requis</span
                    >
                  </md-field>
                </div>
                <div class="md-layout-item d-flex align-items-center">
                  <md-field class="no-underline">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Date prévisible de libération :
                      <vuestic-date-picker
                        v-model="sid_infos.probable_release"
                        :config="dateConfig"
                      />
                    </div>
                  </md-field>
                </div>
                <div class="md-layout-item d-flex align-items-center">
                  <md-field class="no-underline">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Date effective de libération :
                      <vuestic-date-picker
                        v-model="sid_infos.effective_release"
                        :config="dateConfig"
                      />
                    </div>
                  </md-field>
                </div>
              </div>
            </form>
            <div>
              <md-field class="no-underline">
                <div
                  class="meetings d-flex align-items-center justify-content-center"
                >
                  Date des entretiens :
                </div>
              </md-field>
              <div class="d-flex flex-column">
                <span v-for="(m, i) in sid_meetings" :key="i">{{
                  $moment(m.date).format("DD/MM/YYYY")
                }}</span>
              </div>
            </div>
          </div>

          <h4 class="p-4 d-flex">Lier un contact</h4>

          <contact-linker
            ref="contactLinker"
            class="pb-2"
            form_ref="sidLinker"
            :recipient_id="recipient_id"
            :contact_ids="sid_infos.contact_ids"
            @change="changeContact"
          />
          <button type="button" class="btn btn-primary" @click="validateSid">
            Enregistrer
          </button>
        </div>
      </widget>
    </div>
    <div v-for="(detention, i) in detentions" :key="i" class="one-detention">
      <widget :headerText="getTitle(i)">
        <b-table striped hover :items="detention" :fields="fields">
          <template #cell(actions)="row">
            <ion-icon
              class="pl-2"
              name="link-outline"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Lier aux suivis"
              @click="linkFollow(row.item)"
            ></ion-icon>
            <ion-icon
              class="pl-2"
              name="call"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Fiche contact"
              @click="goToContact(row.item)"
            ></ion-icon>
            <ion-icon
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon>
            <ion-icon
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="modifyElement(row.item)"
            ></ion-icon> </template
        ></b-table>
        <button class="btn btn-primary" type="button" @click="addElement(i)">
          Ajouter un élément
        </button>
      </widget>
    </div>
    <button
      type="button"
      class="btn mb-4"
      :class="detentions.length > 0 ? 'btn-secondary' : 'btn-primary'"
      @click="addParcours"
    >
      Ajouter un parcours pénal
    </button>
    <comments
      :recipient_id="recipient_id"
      field="detention"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import Comments from "../../components/comment/Comments.vue";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import { French } from "flatpickr/dist/l10n/fr.js";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import ContactLinker from "../contacts/ContactLinker.vue";
export default {
  components: { Widget, Comments, VuesticDatePicker, ContactLinker },
  name: "recipient-detentions",
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      recipient: {},
      follows: [],
      detentions: [],
      sid_meetings: [],
      sid: false,
      sid_infos: {
        meetings: [],
        contact_ids: [],
      },
      detention_places: [],
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      meeting_date: null,
    };
  },
  validations() {
    return {
      sid_infos: {
        place: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapState(["refreshFlag", "user"]),
    followFields() {
      return [
        {
          key: "organism",
          label: "Organisme",
          formatter: (val) => {
            if (val) {
              return val.name;
            } else {
              return "";
            }
          },
        },
        {
          key: "start_date",
          label: "Début",
          sortable: true,
          formatter: (val) => {
            if (val) {
              return this.$moment(val).format("DD/MM/YYYY");
            } else {
              return null;
            }
          },
        },
        {
          key: "end_date",
          label: "Fin",
          sortable: true,
          formatter: (val) => {
            if (val) {
              return this.$moment(val).format("DD/MM/YYYY");
            } else {
              return null;
            }
          },
        },
        { key: "actions", label: "Actions" },
      ];
    },
    fields() {
      return [
        {
          key: "place",
          label: "Lieu de détention",
        },
        {
          key: "status",
          label: "Statut",
        },
        {
          key: "start_date",
          label: "Début",
          sortable: true,
          formatter: (val) => {
            if (val) {
              return this.$moment(val).format("DD/MM/YYYY");
            } else {
              return null;
            }
          },
        },
        {
          key: "end_date",
          label: "Fin",
          sortable: true,
          formatter: (val) => {
            if (val) {
              return this.$moment(val).format("DD/MM/YYYY");
            } else {
              return null;
            }
          },
        },
        {
          key: "exit_date",
          label: "Sortie",
          sortable: true,
          formatter: (val) => {
            if (val) {
              return this.$moment(val).format("DD/MM/YYYY");
            } else {
              return null;
            }
          },
        },
        {
          key: "duration",
          label: "Durée (mois)",
          sortable: true,
          formatter: (val) => {
            if (val) {
              return val;
            } else {
              return "NR";
            }
          },
        },
        {
          key: "contact.organism",
          label: "Organisme",
          formatter: (val) => {
            if (val) {
              return val.name;
            } else {
              return "";
            }
          },
        },
        {
          key: "contact.contact",
          label: "Contact",
          formatter: (val) => {
            if (val) {
              return (
                val.surname + " " + (val.name ? val.name.toUpperCase() : "")
              );
            } else {
              return "";
            }
          },
        },
        { key: "actions", label: "Actions" },
      ];
    },
  },
  mounted() {
    this.refreshRecipient();
    this.refreshDetentions();
    this.refreshLists();
    this.refreshSIDMeetings();
    this.refreshFollows();
  },
  watch: {
    recipient_id() {
      this.refreshRecipient();
      this.refreshDetentions();
      this.refreshSIDMeetings();
      this.refreshFollows();
    },
    refreshFlag() {
      if (this.refreshFlag == "detention") {
        this.refreshDetentions();
        this.$store.dispatch("setRefreshFlag", false);
      }
      if (this.refreshFlag == "follow") {
        this.refreshFollows();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    addMeetingDate() {
      if (this.meeting_date) {
        this.sid_infos.meetings.push(this.meeting_date);
        this.meeting_date = null;
      }
    },
    refreshLists() {
      axios.get(`/api/web/choices/detention_place`).then((res) => {
        this.detention_places = res.data.data;
      });
    },
    refreshSIDMeetings() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/sid_meetings`)
        .then((res) => {
          this.sid_meetings = res.data.data;
        });
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.sid_infos[fieldName.split(".")[0]][fieldName.split(".")[1]];
      } else {
        field = this.$v.sid_infos[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    followClass(follow, type) {
      if (type == "row") {
        if (
          this.$moment(follow.end_date) - this.$moment() > 0 &&
          this.$moment(follow.start_date) - this.$moment() < 0
        ) {
          return "table-success";
        }
      } else {
        return;
      }
    },
    changeSid(e) {
      axios.post(`/api/web/recipient/${this.recipient_id}/sid`, { sid: e });
      if (this.sid_infos == undefined) {
        this.sid_infos = {
          meetings: [],
          contact_ids: [],
        };
      }
    },
    changeCondamnations(input) {
      if (input) {
        let n = parseInt(input);
        axios.post(`/api/web/recipient/${this.recipient_id}/condamnations`, {
          condamnations: n,
        });
      }
    },
    setJustice() {
      axios
        .post(`/api/web/recipient/${this.recipient_id}/justice`, {
          justice: {
            start: this.recipient.justice_start_date,
            end: this.recipient.justice_end_date,
          },
        })
        .then(() => this.$emit("justiceChange"));
    },
    formatSID() {
      if (this.meeting_date) {
        this.sid_infos.meetings.push(this.meeting_date);
        this.meeting_date = null;
      }
    },
    validateSid() {
      this.formatSID();
      this.$v.$touch();

      if (!this.$v.$invalid) {
        axios.post(`/api/web/recipient/${this.recipient_id}/sid_infos`, {
          sid_infos: this.sid_infos,
        });
        this.$toasted.show("Informations SID enregistrées").goAway(2000);
      } else {
        this.$toasted.show("Des champs sont manquants").goAway(2000);
      }
    },
    refreshRecipient() {
      axios.get(`/api/web/recipient/${this.recipient_id}`).then((res) => {
        this.recipient = res.data.data;
        this.sid = this._.cloneDeep(this.recipient.sid);
        if (this.recipient.sid_infos) {
          this.sid_infos = this._.cloneDeep(this.recipient.sid_infos);
          if (this.sid_infos.contact_ids == undefined) {
            this.sid_infos.contact_ids = [];
          }
        }
      });
    },
    refreshDetentions() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/detentions`)
        .then((res) => {
          this.detentions = res.data.data;
        });
    },
    refreshFollows() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/follow`)
        .then((res) => {
          this.follows = res.data.data;
        });
    },
    addParcours() {
      let index = 0;
      if (this.detentions.length != 0) {
        index =
          Math.max(
            ...this.detentions.map((item) =>
              Math.max(...item.map((i) => i.detention_number))
            )
          ) + 1;
      }
      let payload = {
        recipient_id: this.recipient_id,
        detention_number: index,
        detention: {},
      };
      this.$emit("openDetentionModal", payload);
    },
    addElement(i) {
      let payload = {
        recipient_id: this.recipient_id,
        detention_number: this.detentions[i][0].detention_number,
        detention: {},
      };
      this.$emit("openDetentionModal", payload);
    },
    addFollow() {
      let payload = {
        recipient_id: this.recipient_id,
        follow: {},
      };
      this.$emit("openFollowModal", payload);
    },
    goToContact(detention) {
      if (detention.contact.contact) {
        this.$router.push(`/contact/${detention.contact.contact._id}`);
      } else {
        this.$toasted.show("Aucun contact associé").goAway(3000);
      }
    },
    modifyElement(detention) {
      let payload = {
        recipient_id: this.recipient_id,
        detention_number: detention.detention_number,
        detention: detention,
      };
      this.$emit("openDetentionModal", payload);
    },
    askDelete(detention) {
      this.$toasted
        .show("Souhaitez-vous supprimer cet élément du parcours", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(detention._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(detention_id) {
      axios
        .delete(`/api/web/detention/${detention_id}`)
        .then(() => {
          this.$toasted.show("L'élément a été supprimé").goAway(1500);
          this.refreshDetentions();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    getTitle(index) {
      if (this.detentions.length === 1) {
        return "Parcours pénal";
      } else {
        return "Parcours pénal n°" + (index + 1).toString();
      }
    },
    changeContact(payload) {
      this.sid_infos.contact_ids = payload;
    },
    modifyFollow(follow) {
      let payload = {
        recipient_id: this.recipient_id,
        follow: follow,
      };
      this.$emit("openFollowModal", payload);
    },
    askDeleteFollow(follow) {
      this.$toasted
        .show("Souhaitez-vous supprimer ce suivi", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.deleteFollow(follow._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    deleteFollow(follow_id) {
      axios
        .delete(`/api/web/follow/${follow_id}`)
        .then(() => {
          this.$toasted.show("Le suivi a été supprimé").goAway(1500);
          this.refreshFollows();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    linkFollow(detention) {
      axios
        .post(`/api/web/detention/${detention._id}/link_follow`)
        .then(() => {
          this.$toasted.show("Le suivi a été créé").goAway(1500);
          this.refreshFollows();
        })
        .catch((e) => {
          if (e.response.data.data.message == "OBJECT_CANNOT_BE_VALIDATED") {
            this.$toasted.error("Le suivi n'a pas pu être validé").goAway(5000);
            this.$toasted
              .show("Les dates de début et fin doivent être renseignées")
              .goAway(5000);
            this.$toasted
              .show("L'organisme doit être renseigné (seul ou via un contact)")
              .goAway(5000);
          }
          if (e.response.data.data.message == "CONFLICT_ERROR") {
            this.$toasted
              .error("Le suivi rentre en conflit avec un autre")
              .goAway(5000);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.one-detention {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &:first-child {
    padding-top: 1.5rem;
  }
}
.meetings {
  min-width: 150px;
  ion-icon {
    font-size: 1.2rem;
    cursor: pointer;
    color: $ulysse-blue;
  }
}
</style>