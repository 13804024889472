import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { user: {}, antennas: [], refreshFlag: false, refreshCommentFlag: false },
  mutations: {
    setUserProfile(state, val) {
      state.user = val
    },
    setAntennaList(state, val) {
      state.antennas = val
    },
    setRefreshFlag(state, val) {
      state.refreshFlag = val
    },
    setRefreshCommentFlag(state, val) {
      state.refreshCommentFlag = val
    }
  },
  actions: {
    async initUser({ commit }, user) {
      commit("setUserProfile", user)
    },
    async refreshUser({ commit }) {
      axios.get("/api/web/user/me").then(res => {
        commit("setUserProfile", res.data.data)
      })
    },
    async refreshAntennas({ commit }) {
      axios.get("/api/web/antennas").then(res => {
        commit("setAntennaList", res.data.data)
      })
    },
    async setRefreshFlag({ commit }, flag) {
      commit("setRefreshFlag", flag)
    },
    async setRefreshCommentFlag({ commit }, flag) {
      commit("setRefreshCommentFlag", flag)
    }
  },
  modules: {},
});
