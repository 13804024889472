<template>
  <div class="recipient-contacts">
    <div class="contact-list pt-2 pl-4 pr-4">
      <widget headerText="Organismes et intervenants">
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label for="oriented_by">Orienté par</label>
              <md-select
                name="oriented_by"
                id="oriented_by"
                v-model="oriented_by"
              >
                <md-option v-for="(o, i) in organisms" :key="i" :value="o._id">
                  {{ o.name }}
                </md-option>
                <md-option :value="null">Aucun</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="oriented_by">Suivi par</label>
              <md-select
                name="followed_by"
                id="followed_by"
                v-model="followed_by"
              >
                <md-option v-for="(o, i) in organisms" :key="i" :value="o._id">
                  {{ o.name }}
                </md-option>
                <md-option :value="null">Aucun</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <b-table
          v-if="contacts.length > 0"
          striped
          hover
          :items="contacts"
          :fields="fields"
        >
          <template #cell(actions)="row">
            <ion-icon
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon
            ><ion-icon
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="modifyElement(row.item)"
            ></ion-icon>
          </template>
          <template #cell(contact)="data">
            <a :href="`mailto:${data.value}`">{{ data.value }}</a>
          </template>
        </b-table>
        <button class="btn btn-primary" type="button" @click="addContact()">
          Ajouter un contact
        </button>
      </widget>
    </div>
    <comments
      :recipient_id="recipient_id"
      field="contacts"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import Comments from "../comment/Comments.vue";
import axios from "axios";
import { telFormatter } from "../../services/utils";
import { mapState } from "vuex";
export default {
  components: { Widget, Comments },
  name: "recipient-contacts",
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      contacts: [],
      oriented_by: null,
      followed_by: null,
      organisms: [],
      fields: [
        {
          key: "date",
          label: "Date",
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
          sortable: true,
        },
        {
          key: "organism.name",
          label: "Organisme",
          sortable: true,
        },
        {
          key: "organism.address.postal_code",
          label: "CP",
        },
        {
          key: "organism.address.city",
          label: "Ville",
        },
        {
          key: "contact.civility",
          label: "Civilité",
        },
        {
          key: "contact.surname",
          label: "Prénom",
        },
        {
          key: "contact.name",
          label: "Nom",
          formatter: (val) => (val ? val.toUpperCase() : ""),
        },
        {
          key: "contact.fonction",
          label: "Fonction",
        },
        {
          key: "contact.tel",
          label: "Téléphone",
          formatter: telFormatter,
        },
        {
          key: "contact",
          label: "Email",
          formatter: (val) => {
            return val ? val.email : "";
          },
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  computed: {
    ...mapState(["refreshFlag"]),
  },
  watch: {
    oriented_by() {
      this.updateOrientation();
    },
    followed_by() {
      this.updateFollowing();
    },
    recipient_id() {
      this.getOrientation();
      this.getFollowing();
    },
    refreshFlag() {
      if (this.refreshFlag == "contact") {
        this.refreshContacts();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  mounted() {
    this.refreshContacts();
    this.refreshOrganism();
    this.getOrientation();
    this.getFollowing();
  },
  methods: {
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    getOrientation() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/orientation`)
        .then((res) => {
          this.oriented_by = res.data.data;
        });
    },
    getFollowing() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/following`)
        .then((res) => {
          this.followed_by = res.data.data;
        });
    },
    refreshContacts() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/contacts`)
        .then((res) => {
          this.contacts = res.data.data;
        });
    },
    refreshOrganism() {
      axios.get(`/api/web/organisms`).then((res) => {
        this.organisms = res.data.data;
      });
    },
    updateOrientation() {
      axios.post(`/api/web/recipient/${this.recipient_id}/orientation`, {
        oriented_by: this.oriented_by,
      });
    },
    updateFollowing() {
      axios.post(`/api/web/recipient/${this.recipient_id}/following`, {
        followed_by: this.followed_by,
      });
    },
    askDelete(contact) {
      this.$toasted
        .show("Souhaitez-vous supprimer ce contact ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(contact._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(contact_id) {
      axios
        .delete(`/api/web/recipient/${this.recipient_id}/contact/${contact_id}`)
        .then(() => {
          this.$toasted.show("Le contact a été supprimé").goAway(1500);
          this.refreshContacts();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addContact() {
      let payload = {
        recipient_id: this.recipient_id,
        contact: {},
      };
      this.$emit("openRecipientContactModal", payload);
    },
    modifyElement(contact) {
      let payload = {
        recipient_id: this.recipient_id,
        contact: contact,
      };
      this.$emit("openRecipientContactModal", payload);
    },
  },
};
</script>

<style>
</style>