<template>
  <div class="user">
    <form novalidate class="md-layout p-4" @submit.prevent="validateUser">
      <widget headerText="Infomations utilisateur">
        <md-subheader>Contact</md-subheader>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-15">
            <md-field>
              <label for="initials">Initiales</label>
              <md-input
                name="initials"
                id="initials"
                v-model="currentUser.initials"
              />
            </md-field>
          </div>
          <div class="md-layout-item md-size-15">
            <md-field>
              <label for="gender">Sexe</label>
              <md-select v-model="currentUser.gender" name="gender" id="gender">
                <md-option value="Homme">Homme</md-option>
                <md-option value="Femme">Femme</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-20">
            <md-field :class="getValidationClass('surname')">
              <label for="first-name">Prénom</label>
              <md-input
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                v-model="currentUser.surname"
              />
              <span class="md-error" v-if="!$v.currentUser.surname.required"
                >Le prénom est requis</span
              >
              <span
                class="md-error"
                v-else-if="!$v.currentUser.surname.minlength"
                >Prénom invalide</span
              >
            </md-field>
          </div>

          <div class="md-layout-item md-size-20">
            <md-field :class="getValidationClass('name')">
              <label for="last-name">Nom</label>
              <md-input
                name="last-name"
                id="last-name"
                autocomplete="family-name"
                v-model="currentUser.name"
              />
              <span class="md-error" v-if="!$v.currentUser.name.required"
                >Le nom est requis</span
              >
              <span class="md-error" v-else-if="!$v.currentUser.name.minlength"
                >Nom invalide</span
              >
            </md-field>
          </div>
          <div class="md-layout-item d-flex align-items-center">
            <md-field class="no-underline">
              <div class="d-flex align-items-center justify-content-center">
                Date de naissance :
                <vuestic-date-picker
                  v-model="currentUser.birth_date"
                  :config="dateConfig"
                />
              </div>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="tel">Téléphone</label>
              <md-input
                name="tel"
                id="tel"
                autocomplete="tel"
                v-model="currentUser.tel"
              />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="tel2">Téléphone 2</label>
              <md-input name="tel2" id="tel2" v-model="currentUser.tel2" />
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('email')">
              <label for="email">Email asso</label>
              <md-input
                name="email"
                id="email"
                autocomplete="email"
                v-model="currentUser.email"
              />
              <a v-if="currentUser.email" :href="`mailto:${currentUser.email}`"
                ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
              ></a>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="email2">Email perso</label>
              <md-input
                name="email2"
                id="email2"
                autocomplete="email2"
                v-model="currentUser.email2"
              />
              <a
                v-if="currentUser.email2"
                :href="`mailto:${currentUser.email2}`"
                ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
              ></a>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="street">Adresse</label>
              <md-input
                name="street"
                id="street"
                autocomplete="street"
                v-model="currentUser.address_street"
              />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="postal_code">Code postal</label>
              <md-input
                name="postal_code"
                id="postal_code"
                autocomplete="postal_code"
                v-model="currentUser.address_postal_code"
              />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="city">Ville</label>
              <md-input
                name="city"
                id="city"
                autocomplete="city"
                v-model="currentUser.address_city"
              />
            </md-field>
          </div>
        </div>
        <md-divider></md-divider>
        <md-subheader>Droits et habilitations</md-subheader>

        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label for="habilitation">Droits</label>
              <md-select
                v-model="currentUser.habilitation"
                name="habilitation"
                id="habilitation"
              >
                <md-option
                  v-for="(hab, i) in habilitationList"
                  :key="i"
                  :value="hab"
                  :disabled="getHabilitationClass(hab) ? false : true"
                  >{{ habilitationTrad(hab) }}</md-option
                >
              </md-select>

              <span
                class="md-error"
                v-if="!$v.currentUser.habilitation.required"
                >L'habilitation est requise</span
              >
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="antenna">Antenne</label>
              <md-select
                v-model="currentUser.antenna_id"
                name="antenna"
                id="antenna"
              >
                <md-option
                  v-for="(a, i) in antennas"
                  :key="i"
                  :value="a._id"
                  :disabled="getAntennaClass(a) ? false : true"
                  >{{ a.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="role">Fonction</label>
              <md-input name="role" id="role" v-model="currentUser.role" />
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="role-spec">Fonction spécifique</label>
              <md-input
                name="role-spec"
                id="role-spec"
                v-model="currentUser.role_spec"
              />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="cotisation">Cotisation</label>
              <md-select
                v-model="currentUser.cotisation"
                name="cotisation"
                id="cotisation"
              >
                <md-option value="Réglée">Réglée</md-option>
                <md-option value="Expempté">Expempté</md-option>
                <md-option value="Non réglée">Non réglée</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="activity_time">Temps d'activité (h/semaine)</label>
              <md-input
                name="activity_time"
                id="activity_time"
                v-model="currentUser.activity_time"
                type="number"
              />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-checkbox
              v-model="currentUser.procuration"
              :value="true"
              class="md-primary mt-2"
              >Procuration</md-checkbox
            >
          </div>
          <div class="md-layout-item">
            <md-checkbox
              v-model="currentUser.delegation"
              :value="true"
              class="md-primary mt-2"
              >Délégation</md-checkbox
            >
          </div>
        </div>
        <md-divider></md-divider>
        <md-subheader>Activité au MRS</md-subheader>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-checkbox
              v-model="currentUser.actif"
              :value="true"
              class="md-primary mt-2"
              >Utilisateur actif</md-checkbox
            >
          </div>
          <div class="md-layout-item">
            <md-checkbox
              v-model="currentUser.convention"
              :value="true"
              class="md-primary mt-2"
              >Convention signée</md-checkbox
            >
          </div>
          <div class="md-layout-item">
            <md-checkbox
              v-model="currentUser.cni"
              :value="true"
              class="md-primary mt-2"
              >Copie CNI</md-checkbox
            >
          </div>
          <div class="md-layout-item d-flex align-items-center">
            <md-field
              :class="getValidationClass('activity.start_date')"
              class="no-underline"
            >
              <div class="d-flex align-items-center justify-content-center">
                Début :
                <vuestic-date-picker
                  v-model="currentUser.activity.start_date"
                  :config="dateConfig"
                />
              </div>
              <span
                class="md-error"
                v-if="!$v.currentUser.activity.start_date.required"
                >La date de début d'acivité est requise</span
              >
            </md-field>
          </div>
          <div class="md-layout-item">
            <div
              class="d-flex align-items-center justify-content-center period"
            >
              Fin :
              <vuestic-date-picker
                v-model="currentUser.activity.end_date"
                :config="dateConfig"
              />
            </div>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            Commentaire et/ou adresse :
            <b-form-textarea
              class="pt-2"
              id="user-comment"
              v-model="currentUser.comment"
              rows="3"
              max-rows="3"
            />
          </div>
        </div>
        <md-divider v-if="userId === 'new'"></md-divider>
        <md-subheader v-if="userId === 'new'"
          >Informations de connexion</md-subheader
        >
        <div v-if="userId === 'new'" class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('login')">
              <label for="login">Login</label>
              <md-input id="login" v-model="currentUser.login" />
              <span class="md-error" v-if="!$v.currentUser.login.required"
                >Le login est requis</span
              >
              <span class="md-error" v-else-if="!$v.currentUser.login.minlength"
                >Login trop court (6 car. minimum</span
              >
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('name')">
              <label for="password">Mot de passe</label>
              <md-input
                id="password"
                v-model="currentUser.password"
                type="password"
              />
              <span class="md-error" v-if="!$v.currentUser.password.required"
                >Le mot de passe est requis</span
              >
              <span
                class="md-error"
                v-else-if="!$v.currentUser.password.minlength"
                >Mot de passe trop court (8 car. minimum)</span
              >
            </md-field>
          </div>
        </div>
        <button
          class="btn"
          :class="modifMade ? 'btn-primary' : 'btn-pale'"
          type="submit"
        >
          Enregistrer
        </button>
        <div class="m-2" />
        <button
          v-if="
            this.user.habilitation == 'admin' ||
            this.user.habilitation == 'super'
          "
          class="btn"
          v-on:click="askDelete()"
          type="button"
        >
          Supprimer
        </button>
      </widget>
    </form>
    <form
      v-if="userId === user._id"
      novalidate
      class="md-layout p-4"
      @submit.prevent="validatePassword"
    >
      <widget headerText="Changement de mot de passe"
        ><div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClassPassword('old')">
              <label for="old">Ancien mot de passe</label>
              <md-input id="old" v-model="password.old" type="password" />
              <span class="md-error" v-if="!$v.password.old.required"
                >L'ancien mdp est requis</span
              >
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClassPassword('new')">
              <label for="new">Nouveau mot de passe</label>
              <md-input id="new" v-model="password.new" type="password" />
              <span class="md-error" v-if="!$v.password.new.required"
                >Le nouveau mdp est requis</span
              >
              <span class="md-error" v-else-if="!$v.password.new.minlength"
                >Mot de passe trop court (8 car. minimum)</span
              >
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClassPassword('newRepeated')">
              <label for="newRepeated">Répetez le nouveau mot de passe</label>
              <md-input
                id="newRepeated"
                v-model="password.newRepeated"
                type="password"
              />
              <span class="md-error" v-if="!$v.password.newRepeated.required"
                >Le nouveau mdp est requis</span
              >
              <span
                class="md-error"
                v-else-if="!$v.password.newRepeated.minlength"
                >Mot de passe trop court (8 car. minimum)</span
              >
            </md-field>
          </div>
        </div>
        <button
          class="btn"
          :class="modifMade ? 'btn-primary' : 'btn-pale'"
          type="submit"
        >
          Enregistrer
        </button>
      </widget>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Widget from "../../components/widgets/Widget.vue";
import { mapState } from "vuex";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { userTemplate } from "./userTemplate.js";
import { habilitationList, habilitationTrad } from "../../services/utils";

export default {
  components: { Widget, VuesticDatePicker },
  name: "user",
  mixins: [validationMixin],
  data() {
    return {
      habilitationList: habilitationList,
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      date: this.$moment(),
      antennas: [],
      currentUser: {
        name: null,
        surname: null,
        tel: null,
        email: null,
        role: null,
        actif: true,
        activity: {
          start_date: null,
          end_time: null,
        },
      },
      originUser: {
        name: null,
        surname: null,
        tel: null,
        email: null,
        role: null,
        actif: true,
        activity: {
          start_date: null,
          end_time: null,
        },
      },
      password: {
        old: null,
        new: null,
        newRepeated: null,
      },
      userSaved: false,
      sending: false,
      lastUser: null,
    };
  },
  validations() {
    let val = {
      currentUser: {
        surname: {
          required,
          minLength: minLength(1),
        },
        name: {
          required,
          minLength: minLength(1),
        },
        habilitation: {
          required,
        },
        activity: {
          start_date: {
            required,
          },
        },
      },
      password: {},
    };
    if (this.userId === "new") {
      val.currentUser.login = {
        required,
        minLength: minLength(6),
      };
      val.currentUser.password = {
        required,
        minLength: minLength(6),
      };
    }

    if (this.userId === this.user._id) {
      val.password = {
        old: {
          required,
        },
        new: {
          required,
          minLength: minLength(8),
        },
        newRepeated: {
          required,
          minLength: minLength(8),
        },
      };
    }
    return val;
  },
  computed: {
    ...mapState(["user"]),
    modifMade() {
      return !this._.isEqual(this.currentUser, this.originUser);
    },
    userId() {
      return this.$route.params.user_id;
    },
  },
  mounted() {
    this.refreshAntenna();
    this.refreshUser();
  },
  watch: {
    userId() {
      this.refreshAntenna();
      this.refreshUser();
    },
  },
  methods: {
    askDelete() {
      this.$toasted
        .show("Souhaitez-vous supprimer l'utilisateur", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(this.originUser._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(user_id) {
      axios.delete(`/api/web/user/${user_id}`).then(() => {
        this.$toasted.show("Utilisateur supprimé").goAway(2000);
        this.$router.push("/users");
      });
    },
    refreshAntenna() {
      axios.get("/api/web/antennas").then((res) => {
        this.antennas = res.data.data;
      });
    },
    refreshUser() {
      if (this.userId === "new") {
        this.initUser({});
      } else {
        axios
          .get(`/api/web/user/${this.userId}`)
          .then((res) => {
            this.initUser(res.data.data);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    initUser(user) {
      this.originUser = this._.cloneDeep(user);
      this.currentUser = this._.cloneDeep(
        this._.extend(this._.cloneDeep(userTemplate), user)
      );
    },
    habilitationTrad(hab) {
      return habilitationTrad[hab];
    },
    getHabilitationClass(hab) {
      return (
        habilitationList.indexOf(hab) >=
        habilitationList.indexOf(this.user.habilitation)
      );
    },
    getAntennaClass(antenna) {
      return (
        habilitationList.indexOf(this.user.habilitation) <= 3 ||
        this.user.antenna_id == antenna._id
      );
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentUser[fieldName.split(".")[0]][fieldName.split(".")[1]];
      } else {
        field = this.$v.currentUser[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    getValidationClassPassword(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.password[fieldName.split(".")[0]][fieldName.split(".")[1]];
      } else {
        field = this.$v.password[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    formatUser(user) {
      if (user.actif == null) {
        user.actif = false;
      }
      return user;
    },
    saveUser() {
      if (
        habilitationList.indexOf(this.originUser.habilitation) <
        habilitationList.indexOf(this.user.habilitation)
      ) {
        this.$toasted
          .error("Cet utilisateur est supérieur à vous hierarchiquement !")
          .goAway(3000);
      } else {
        let body = {
          origin: this.originUser,
          current: this.formatUser(this.currentUser),
          fail_on_concurrent: true,
          remove_absent_values: false,
        };
        axios
          .post(`/api/web/user/${this.userId}`, body)
          .then((res) => {
            if (res.data.success) {
              this.$toasted.show("Utilisateur modifié").goAway(1500);
              this.refreshUser();
            } else {
              this.$toasted
                .show("Impossible de modifier l'utilisateur")
                .goAway(1500);
            }
          })
          .catch((err) => {
            console.error(err);
            this.$toasted
              .show("Impossible de modifier l'utilisateur")
              .goAway(1500);
          });
      }
    },
    createUser() {
      let body = {
        user: this.formatUser(this.currentUser),
      };
      axios
        .put(`/api/web/user/new`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Utilisateur enregistré").goAway(1500);
            let id = res.data.data.user_id;
            this.$router.push(`/user/${id}`);
          } else {
            this.$toasted.error("Utilisateur non enregistré").goAway(1500);
          }
        })
        .catch((err) => {
          if (err.response.data.data.message == "USER_ALREADY_EXISTS") {
            this.$toasted.error("Le login est déjà utilisé").goAway(1500);
          }
          this.$toasted.error("Utilisateur non enregistré").goAway(1500);
        });
    },
    validateUser() {
      if (this.modifMade) {
        this.$v.currentUser.$touch();

        if (!this.$v.currentUser.$invalid) {
          this.sending = true;
          if (this.currentUser._id === undefined) {
            this.createUser();
          } else {
            this.saveUser();
          }
        } else {
          this.$toasted
            .error("Tous les champs nécessaires ne sont pas remplis")
            .goAway(2000);
        }
      }
    },
    validatePassword() {
      if (this.userId == this.user._id) {
        this.$v.password.$touch();
        if (this.$v.password.$invalid) {
          this.$toasted
            .error("Tous les champs nécessaires ne sont pas remplis")
            .goAway(2000);
        } else if (this.password.new !== this.password.newRepeated) {
          this.$toasted
            .error("Les nouveaux mdp ne correspondent pas")
            .goAway(2000);
        } else {
          this.savePassword();
        }
      }
    },
    savePassword() {
      let body = {
        password: this.password,
      };
      axios
        .post(`/api/web/user/${this.userId}/modify_password`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Mot de passe enregistré").goAway(1500);
            this.$router.push(`/user/${this.user.id}`);
          } else {
            this.$toasted.error("Mot de passe non enregistré").goAway(1500);
          }
        })
        .catch((err) => {
          if (err.response.data.data.message == "WRONG_PASSWORD") {
            this.$toasted
              .error("L'ancien mot de passe est invalide")
              .goAway(1500);
          }
          this.$toasted.error("Mot de passe non enregistré").goAway(1500);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-subheader {
  font-size: 1.2rem;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.md-checkbox {
  margin: 22px 16px 16px 0px !important;
}
.period {
  margin-top: 16px;
  margin-bottom: 16px;
}
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
</style>