<template>
  <div class="helps p-4">
    <widget headerText="Liste des aides">
      <b-table
        striped
        hover
        :items="helpList"
        :fields="fields"
        @row-clicked="handleRowClick"
      >
        <template #cell(actions)="row">
          <ion-icon
            name="trash"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Supprimer"
            @click="askDelete(row.item)"
          ></ion-icon>
        </template>
      </b-table>
      <button
        v-if="user.rights.indexOf('helps_management') > 0"
        class="btn btn-primary"
        v-on:click="addHelp"
      >
        Ajouter
      </button>
    </widget>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "helps",
  components: { Widget },
  data() {
    return {
      helpList: [],
      fields: [
        { key: "label", label: "Libellé", sortable: true },
        { key: "category", label: "Catégorie", sortable: true },
        { key: "price", label: "Prix", sortable: true },
        { key: "stock", label: "Stock", sortable: false },
        {
          key: "last_update",
          label: "Mis à jour le",
          sortable: false,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "archived",
          label: "Archivée",
          sortable: false,
          formatter: (archived) => (archived ? "Oui" : ""),
        },
      ],
    };
  },
  props: {
    searchInput: {
      type: String,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    this.refreshHelpList();
  },
  watch: {
    searchInput() {
      this.refreshHelpList();
    },
  },
  methods: {
    refreshHelpList() {
      axios
        .get("/api/web/helps", { params: { search: this.searchInput } })
        .then((res) => {
          this.helpList = res.data.data;
        });
    },
    handleRowClick(item) {
      this.$router.push(`/help/${item._id}`);
    },
    addHelp() {
      this.$router.push(`/help/new`);
    },
    askDelete(item) {
      this.$toasted
        .show("Souhaitez-vous supprimer cette aide", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(item._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(help_id) {
      axios
        .delete(`/api/web/help/${help_id}`)
        .then(() => {
          this.$toasted.show("L'aide a été supprimée").goAway(1500);
          this.refreshHelpList();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>