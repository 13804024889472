export function hasRights(user, ...rights) {
    if (user && user.rights) {
        if (rights.length <= 1 && typeof (rights[0]) === 'undefined') {
            return true
        } else {
            return rights.every(r => user.rights.indexOf(r) >= 0)
        }
    } else {
        return false
    }
}

export function telFormatter(tel) {
    if (typeof tel == String && tel.length == 10) {
        var newTel = tel.replace(" ", "")
        var telParts = [newTel.slice(0, 2), newTel.slice(2, 4), newTel.slice(4, 6), newTel.slice(6, 8), newTel.slice(8, 10)];
        return telParts.join(" ")
    }
    else {
        return tel
    }
}

export var habilitationList = ["super", "admin", "siege", "management", "antenna_admin", "volunteer"]

export var habilitationTrad = {
    "super": "Super administrateur",
    "admin": "Administrateur",
    "siege": "Siège",
    "management": "Comptabilité et gestion",
    "antenna_admin": "Gestion d'antenne",
    "volunteer": "Bénévole"
}

export function zoomOutMobile(window) {
    if (window.width <= 720) {
        var viewport = document.querySelector('meta[name="viewport"]');

        if (viewport) {
            viewport.content = "initial-scale=0.1";
            viewport.content = "width=1200";
        }
    }
}
