<template>
  <div class="choices p-4">
    <widget headerText="Tables de référence">
      <md-field>
        <label for="antenna-choice">Choix du type de table</label>
        <md-select v-model="choiceType" name="type-choice" id="type-choice">
          <md-option v-for="(t, i) in types" :key="i" :value="i">{{
            t.label
          }}</md-option>
        </md-select>
      </md-field>
      <b-table
        striped
        hover
        :items="choices"
        :fields="[
          { key: 'label', label: 'Libellé', sortable: true },
          {
            key: 'last_update',
            label: 'Mis à jour',
            formatter: (val) => (val ? $moment(val).format('DD/MM/YYYY') : ''),
            sortable: true,
          },
          { key: 'actions', label: 'Actions' },
        ]"
      >
        <template #cell(actions)="row">
          <ion-icon
            class="pr-2"
            name="trash"
            style="cursor: pointer"
            v-b-tooltip.hover="'Supprimer'"
            @click="askDelete(row.item)"
          ></ion-icon>
          <ion-icon
            name="pencil"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Modifier"
            @click="handleRowClick(row.item)"
          ></ion-icon>
        </template>
      </b-table>
      <button class="btn btn-primary" type="button" @click="addItem">
        Ajouter une entrée
      </button>
    </widget>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: { Widget },
  data() {
    return {
      types: [],
      selected_type: null,
      choiceType: null,
      choices: [],
    };
  },
  watch: {
    types() {
      if (this.types.length > 0) {
        this.choiceType = 0;
      }
    },
    choiceType() {
      this.selected_type = this.types[this.choiceType].key;
    },
    selected_type() {
      this.refreshChoices();
    },
    refreshFlag(flag) {
      if (flag == "choice") {
        this.refreshChoices();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  computed: {
    ...mapState(["user", "antennas", "refreshFlag"]),
  },
  mounted() {
    this.refreshTypes();
  },
  methods: {
    refreshTypes() {
      axios.get("/api/web/choices").then((res) => {
        this.types = res.data.data;
      });
    },
    refreshChoices() {
      axios.get(`api/web/choices/${this.selected_type}`).then((res) => {
        this.choices = res.data.data;
      });
    },
    handleRowClick(item) {
      let payload = {
        type: this.selected_type,
        item: item,
      };
      this.$emit("openChoiceModal", payload);
    },
    addItem() {
      let payload = {
        type: this.selected_type,
        item: {
          label: "",
        },
      };
      this.$emit("openChoiceModal", payload);
    },
    askDelete(item) {
      this.$toasted
        .show("Souhaitez-vous supprimer l'élément", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(item._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(item_id) {
      axios
        .delete(`/api/web/choice/${item_id}`)
        .then(() => {
          this.$toasted.show("L'élément a été supprimé").goAway(1500);
          this.refreshChoices();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>