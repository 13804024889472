<template>
  <vuestic-modal
    class="follow-modal"
    ref="followModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">Ajouter un élément de suivi</span>
    </div>
    <form novalidate @submit.prevent="addFollow">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-field :class="getValidationClass('organism_id')">
            <label for="organism_id">Suivi par</label>
            <md-select
              name="organism_id"
              id="organism_id"
              v-model="currentFollow.organism_id"
              ><md-option
                v-for="(organism, i) in organismList"
                :key="i"
                :value="organism._id"
              >
                {{ organism.name }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentFollow.organism_id.required"
              >L'organisme de suivi est requis</span
            >
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('start_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Date de début :
              <vuestic-date-picker
                v-model="currentFollow.start_date"
                :config="dateConfig"
              />
            </div>
            <span class="md-error" v-if="!$v.currentFollow.start_date.required"
              >La date de début de suivi est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('end_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Date de fin :
              <vuestic-date-picker
                v-model="currentFollow.end_date"
                :config="dateConfig"
              />
            </div>
            <span class="md-error" v-if="!$v.currentFollow.end_date.required"
              >La date de fin de suivi est requise</span
            >
          </md-field>
        </div>
      </div>
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "follow-modal",
  components: {
    VuesticModal,
    VuesticDatePicker,
  },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      organismList: [],
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      originFollow: {},
      currentFollow: {
        contact_ids: [],
      },
      follow: {},
    };
  },
  validations: {
    currentFollow: {
      organism_id: {
        required,
      },
      start_date: {
        required,
      },
      end_date: {
        required,
      },
    },
  },
  mounted() {
    this.refreshLists();
  },
  methods: {
    refreshFollow() {
      if (this.follow._id) {
        axios.get(`/api/web/follow/${this.follow._id}`).then((res) => {
          this.originFollow = this._.cloneDeep(res.data.data);
          this.currentFollow = this._.cloneDeep(this.originFollow);
        });
      } else {
        this.originFollow = {};
        this.currentFollow = {
          contact_ids: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentFollow[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentFollow[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshLists() {
      axios.get(`/api/web/organisms`).then((res) => {
        this.organismList = res.data.data.filter((organism) =>
          organism.name.startsWith("SPIP")
        );
      });
    },
    open(follow) {
      this.$refs.followModal.open();
      this.follow = follow;
      this.refreshLists();
      this.refreshFollow();
      if (this.currentFollow.start_date == undefined) {
        this.currentFollow.start_date = this.$moment().format("YYYY-MM-DD");
      }
    },
    resetForm() {
      this.currentFollow = {
        contact_ids: [],
      };
      this.originFollow = {};
    },
    createFollow() {
      let body = {
        follow: this.currentFollow,
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/follow`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Suivis mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "follow");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        })
        .catch((e) => {
          if (e.response.data.data.message == "OBJECT_CANNOT_BE_VALIDATED") {
            this.$toasted.error("Le suivi n'a pas pu être validé");
          }
          if (e.response.data.data.message == "CONFLICT_ERROR") {
            this.$toasted.error("Le suivi rentre en conflit avec un autre");
          }
        });
    },
    saveFollow() {
      let body = {
        current: this.currentFollow,
        origin: this.originFollow,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/follow/${this.follow._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Suivis mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "follow");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        })
        .catch((e) => {
          if (e.response.data.data.message == "OBJECT_CANNOT_BE_VALIDATED") {
            this.$toasted.error("Le suivi n'a pas pu être validé");
          }
          if (e.response.data.data.message == "CONFLICT_ERROR") {
            this.$toasted.error("Le suivi rentre en conflit avec un autre");
          }
        });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.follow._id) {
          this.saveFollow();
        } else {
          this.createFollow();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Parcours non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
    changeContact(payload) {
      this.currentFollow.contact_ids = payload;
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.follow-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>