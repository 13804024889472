<template>
  <vuestic-modal
    class="formation-modal"
    ref="formationModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ title }}</span>
    </div>
    <form novalidate @submit.prevent="addFormation">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('type')">
            <label for="formation_type">Type</label>
            <md-select
              name="formation_type"
              id="formation_type"
              v-model="currentFormation.type"
            >
              <md-option
                v-for="(r, i) in formationTypes"
                :key="i"
                :value="r._id"
              >
                {{ r.label }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="
                $v.currentFormation.type && !$v.currentFormation.type.required
              "
              >La type de la formation est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('domain')">
            <label for="formation_domain">Domaine</label>
            <md-select
              name="formation_domain"
              id="formation_domain"
              v-model="currentFormation.domain"
            >
              <md-option
                v-for="(r, i) in formationDomains"
                :key="i"
                :value="r._id"
              >
                {{ r.label }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="
                $v.currentFormation.domain &&
                !$v.currentFormation.domain.required
              "
              >La Domaine de la formation est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('name')">
            <label for="formation-name">Nom de la formation</label>
            <md-input
              name="formation-name"
              id="formation-name"
              v-model="currentFormation.name"
            />
            <span class="md-error" v-if="!$v.currentFormation.name.required"
              >Le nom de la formation est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('status')">
            <label for="formation-status">Statut</label>
            <md-select
              name="formation-status"
              id="formation-status"
              v-model="currentFormation.status"
            >
              <md-option
                v-for="(p, i) in [
                  'En cours de candidature',
                  'Prévue',
                  'En cours de formation',
                  'Réussie',
                  'Échouée',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentFormation.status.required"
              >Le status est requis</span
            >
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de candidature :
              <vuestic-date-picker
                v-model="currentFormation.application_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('start_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Date de début :
              <vuestic-date-picker
                v-model="currentFormation.start_date"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="!$v.currentFormation.start_date.required"
              >La date de début est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de fin prévue :
              <vuestic-date-picker
                v-model="currentFormation.end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de fin effective :
              <vuestic-date-picker
                v-model="currentFormation.effective_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field>
            <label for="formation-participation"
              >Participation du MRS (€)</label
            >
            <md-input
              name="formation-participation"
              id="formation-participation"
              v-model="currentFormation.participation"
              type="number"
            />
          </md-field>
        </div>
      </div>
      <md-checkbox
        v-model="currentFormation.terminated"
        :value="true"
        class="md-primary mt-2"
        >Formation terminée</md-checkbox
      >
      <md-checkbox
        v-model="currentFormation.in_detention"
        :value="true"
        class="md-primary mt-2"
        >En détention</md-checkbox
      >
      <md-checkbox
        v-model="currentFormation.drive_license"
        :value="true"
        class="md-primary mt-2"
        >Formation au permis de conduire</md-checkbox
      >
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="formation-comment"
            v-model="currentFormation.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
    <h4 class="p-4 d-flex">Lier un contact</h4>
    <contact-linker
      ref="contactLinker"
      :recipient_id="recipient_id"
      form_ref="formationLinker"
      :contact_ids="currentFormation.contact_ids"
      @change="changeContact"
    />
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ContactLinker from "../contacts/ContactLinker.vue";
export default {
  name: "formation-modal",
  components: { VuesticModal, VuesticDatePicker, ContactLinker },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      formationTypes: [],
      formationDomains: [],
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      originFormation: {},
      currentFormation: {
        contact_ids: [],
      },
      formation: {},
    };
  },
  validations() {
    const isDate = (val) => this.$moment(val).isValid();
    return {
      currentFormation: {
        name: {
          required,
        },
        status: {
          required,
        },
        domain: { required },
        start_date: {
          required,
          isDate,
        },
      },
    };
  },
  computed: {
    title() {
      return this.formation._id
        ? "Modifier la formation"
        : "Ajouter une formation";
    },
  },
  methods: {
    refreshLists() {
      axios.get(`/api/web/choices/formation_type`).then((res) => {
        this.formationTypes = res.data.data;
      });
      axios.get(`/api/web/choices/qualification_domain`).then((res) => {
        this.formationDomains = res.data.data;
      });
    },
    refreshFormation() {
      if (this.formation._id) {
        axios.get(`/api/web/formation/${this.formation._id}`).then((res) => {
          this.originFormation = this._.cloneDeep(res.data.data);
          this.currentFormation = this._.cloneDeep(this.originFormation);
        });
      } else {
        this.originFormation = {};
        this.currentFormation = {
          contact_ids: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentFormation[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentFormation[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(formation) {
      this.$refs.formationModal.open();
      this.formation = formation;
      this.refreshFormation();
      this.refreshLists();
      this.$refs.contactLinker.open();
    },
    resetForm() {
      this.currentFormation = {
        contact_ids: [],
      };
      this.originFormation = {};
    },
    createFormation() {
      let body = {
        formation: this.formatFormation(this.currentFormation),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/formations`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "formation");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    formatFormation(formation) {
      formation.participation = formation.participation
        ? parseFloat(formation.participation)
        : formation.participation;
      return formation;
    },
    saveFormation() {
      let body = {
        current: this.formatFormation(this.currentFormation),
        origin: this.originFormation,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/formation/${this.formation._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "formation");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.formation._id) {
          this.saveFormation();
        } else {
          this.createFormation();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Parcours non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
    changeContact(payload) {
      this.currentFormation.contact_ids = payload;
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.formation-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>