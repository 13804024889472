<template>
  <vuestic-modal
    class="room-modal"
    ref="roomModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    :footerShown="user.rights && user.rights.indexOf('hotel_management') > 0"
    large
    ><div slot="title">
      <span class="center">{{ getTitle() }}</span>
    </div>
    <form novalidate @submit.prevent="addRoom">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('number')">
            <label for="room-number">Numéro</label>
            <md-input
              :disabled="!hasRightRoom"
              name="room-number"
              id="room-number"
              v-model="currentRoom.number"
            />
            <span class="md-error" v-if="!$v.currentRoom.number.required"
              >La numéro est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field :class="getValidationClass('antenna_id')">
            <label for="room-antenna">Antenne</label>
            <md-select
              :disabled="!hasRightRoom"
              name="room-antenna"
              id="room-antenna"
              v-model="currentRoom.antenna_id"
            >
              <md-option
                v-for="(a, i) in antennas"
                :key="i"
                :value="a._id"
                :disabled="isDisabled(a)"
              >
                {{ a.name }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentRoom.antenna_id.required"
              >L'antenne est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="room-borrow">Prétée au</label>
            <md-select
              :disabled="!hasRightRoom"
              name="room-borrow"
              id="room-borrow"
              v-model="currentRoom.borrow_antenna_id"
            >
              <md-option
                v-for="(a, i) in antennasToBorrow"
                :key="i"
                :value="a._id"
              >
                {{ a.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="room-type">Type de chambre</label>
            <md-select
              :disabled="!hasRightRoom"
              name="room-type"
              id="room-type"
              v-model="currentRoom.type"
            >
              <md-option
                v-for="(a, i) in ['CH1', 'CH2', 'CH3+']"
                :key="i"
                :value="a"
              >
                {{ a }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field :class="getValidationClass('price')">
            <label for="room-price">Loyer mensuel</label>
            <md-input
              :disabled="!hasRightRoom"
              name="room-price"
              id="room-price"
              type="number"
              v-model="currentRoom.price"
            />
            <span class="md-error" v-if="!$v.currentRoom.price.required"
              >La prix est requis</span
            >
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Début de location :
              <vuestic-date-picker
                :disabled="!hasRightRoom"
                v-model="currentRoom.start_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Fin de location :
              <vuestic-date-picker
                :disabled="!hasRightRoom"
                v-model="currentRoom.end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-checkbox
            :disabled="!hasRightRoom"
            v-model="currentRoom.alt"
            :value="true"
            class="md-primary mt-2"
            >Financement ALT</md-checkbox
          >
        </div>
      </div>
      <div v-if="currentRoom.alt" class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="room-alt-dril">Financé par DRIHL</label>
            <md-select
              :disabled="!hasRightRoom"
              name="room-alt-dril"
              id="room-alt-dril"
              v-model="currentRoom.alt_drihl"
            >
              <md-option
                v-for="(a, i) in ['75', '92', '93', '94']"
                :key="i"
                :value="a"
              >
                {{ a }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Début de financement :
              <vuestic-date-picker
                :disabled="!hasRightRoom"
                v-model="currentRoom.alt_start_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Fin de financement :
              <vuestic-date-picker
                :disabled="!hasRightRoom"
                v-model="currentRoom.alt_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-checkbox
            v-model="currentRoom.archived"
            :value="true"
            class="md-primary mt-2"
            :disabled="!hasRightRoom"
            >Archivé</md-checkbox
          >
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            :disabled="!hasRightRoom"
            class="pt-2"
            id="room-comment"
            v-model="currentRoom.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { mapState } from "vuex";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "room-modal",
  components: { VuesticModal, VuesticDatePicker },
  mixins: [validationMixin],
  props: {
    hotel_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      currentRoom: {},
      originRoom: {},
      room: {},
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
    };
  },
  validations() {
    return {
      currentRoom: {
        number: {
          required,
        },
        price: {
          required,
        },
        antenna_id: {
          required,
        },
      },
    };
  },
  watch: {
    currentRoom: {
      deep: true,
      handler() {
        this.$v.$reset();
      },
    },
  },
  computed: {
    ...mapState(["antennas", "user"]),
    antennasToBorrow() {
      return this.antennas
        .filter((a) => a._id != this.currentRoom.antenna_id)
        .concat([{ _id: "Aucune", name: "Aucune" }]);
    },
    hasRightRoom() {
      return (
        this.user.rights.indexOf("room_management") > 0 ||
        (this.user.habilitation == "antenna_admin" &&
          (this.currentRoom._id == undefined ||
            this.currentRoom.antenna_id == this.user.antenna_id))
      );
    },
  },
  methods: {
    isDisabled(antenna) {
      return (
        this.user.rights.indexOf("room_management") < 0 &&
        antenna._id != this.user.antenna_id
      );
    },
    getTitle() {
      return this.room._id ? "Modification d'une chambre" : "Nouvelle chambre";
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentRoom[fieldName.split(".")[0]][fieldName.split(".")[1]];
      } else {
        field = this.$v.currentRoom[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(room) {
      this.$refs.roomModal.open();
      this.room = room;
      this.originRoom = this._.cloneDeep(this.room);
      this.currentRoom = this._.cloneDeep(this.room);
    },
    formatRoom(room) {
      if (typeof this.currentRoom.price == String) {
        this.currentRoom.price = parseFloat(
          this.currentRoom.price.replace(",", ".")
        );
      }
      return room;
    },
    resetForm() {
      this.originRoom = {};
      this.currentRoom = {};
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$error) {
        if (this.room._id) {
          this.saveRoom();
        } else {
          this.createRoom();
        }
        this.$v.$reset();
        this.resetForm();
        return true;
      } else {
        this.$toasted.show("Des champs sont manquants").goAway(1500);
        return false;
      }
    },
    createRoom() {
      let body = {
        room: this.formatRoom(this.currentRoom),
      };
      axios.put(`/api/web/hotel/${this.hotel_id}/rooms`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Chambre créée").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "room");
        } else {
          this.$toasted.error("Erreur inconnue").goAway(1500);
        }
      });
      return true;
    },
    saveRoom() {
      let body = {
        current: this.formatRoom(this.currentRoom),
        origin: this.room,
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/room/${this.room._id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Chambre mise à jour").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "room");
        } else {
          this.$toasted.error("Erreur inconnue").goAway(1500);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.room-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>