<template>
  <transition name="fade">
    <div class="vuestic-alert alert" :class="alertClass" v-if="!hidden">
      <slot></slot>
      <i
        class="far fa-times alert-close"
        v-if="withCloseBtn"
        @click="hide()"
      ></i>
    </div>
  </transition>
</template>

<script>
export default {
  name: "vuestic-alert",
  computed: {
    alertClass() {
      return {
        "with-close": this.withCloseBtn,
        "alert-success": this.type === "success",
        "alert-danger": this.type === "danger",
        "alert-warning": this.type === "warning",
        "alert-info": this.type === "info",
      };
    },
  },
  props: {
    type: {
      type: String,
      default: "success",
    },
    withCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hidden: false,
    };
  },
  methods: {
    hide() {
      this.hidden = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.alert {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  .badge {
    margin-right: 0.63rem;
  }
  &.with-close {
    padding-right: $with-close-pr;
  }
  .alert-close {
    position: absolute;
    right: 20px;
    font-size: $font-size-base;
    top: calc(50% - #{$font-size-base}/ 2);
    cursor: pointer;
  }
}

.alert-success {
  box-shadow: $alert-success-shadow;
  .alert-close {
    color: $brand-success;
  }
}

.alert-warning {
  box-shadow: $alert-warning-shadow;
  .alert-close {
    color: $brand-warning;
  }
}

.alert-danger {
  box-shadow: $alert-danger-shadow;
  .alert-close {
    color: $brand-danger;
  }
}

.alert-info {
  box-shadow: $alert-info-shadow;
  .alert-close {
    color: $brand-info;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
@include media-breakpoint-down(sm) {
  .alert {
    flex-direction: column;
  }
}
</style>
