<template>
  <div class="recipient-meetings">
    <div class="meeting-list pt-2 pl-4 pr-4">
      <widget headerText="Liste des entretiens"
        ><button type="button" class="btn btn-primary" @click="addMeeting">
          Ajouter un entretien
        </button>
        <b-table
          v-if="meetings"
          striped
          hover
          :fields="fields"
          :items="meetings"
          :tbody-tr-class="meetingClass"
          class="mt-3"
        >
          <template #cell(actions)="row">
            <ion-icon
              v-if="row.item.total == undefined"
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon
            ><ion-icon
              v-if="row.item.total == undefined"
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="modifyElement(row.item)"
            ></ion-icon>
          </template>
        </b-table>
      </widget>
    </div>
    <comments
      :recipient_id="recipient_id"
      field="meeting"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import axios from "axios";
import Comments from "../comment/Comments.vue";
import { mapState } from "vuex";
export default {
  name: "recipient-meetings",
  components: { Widget, Comments },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      meetings: [],
      fields: [
        {
          key: "date",
          label: "Date",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "TOTAUX",
          sortable: true,
        },
        {
          key: "sid",
          label: "En détention",
          formatter: (val) =>
            typeof val == "number" ? val : val ? "Oui" : "Non",
        },
        {
          key: "user",
          label: "Fait par",
          formatter: (user) => (user ? user.initials : ""),
          sortable: true,
        },
        {
          key: "presence",
          label: "Présent",
          formatter: (val) => (val ? val : "Oui"),
          tdClass: (value) => (value == "Non" ? "table-warning" : ""),
        },
        {
          key: "next_date",
          label: "Prochain entretien",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "housing",
          label: "Hébergement",
          formatter: (val) =>
            typeof val == "number" ? val : val ? "Oui" : "Non",
          tdClass: (value) => (value ? "table-info" : ""),
        },
        {
          key: "resources",
          label: "Ressources",
          formatter: (val) =>
            typeof val == "number" ? val : val ? "Oui" : "Non",
          tdClass: (value) => (value ? "table-info" : ""),
        },
        {
          key: "work",
          label: "Emploi",
          formatter: (val) =>
            typeof val == "number" ? val : val ? "Oui" : "Non",
          tdClass: (value) => (value ? "table-info" : ""),
        },
        {
          key: "health",
          label: "Santé",
          formatter: (val) =>
            typeof val == "number" ? val : val ? "Oui" : "Non",
          tdClass: (value) => (value ? "table-info" : ""),
        },
        {
          key: "admin",
          label: "Administratif",
          formatter: (val) =>
            typeof val == "number" ? val : val ? "Oui" : "Non",
          tdClass: (value) => (value ? "table-info" : ""),
        },
        {
          key: "helps",
          label: "Aides",
          formatter: (helps) =>
            (
              Math.round(
                helps
                  .map((h) => parseFloat(h.total_price))
                  .reduce((a, b) => a + b, 0) * 100
              ) / 100
            ).toString() + " €",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  mounted() {
    this.refreshMeetings();
  },
  computed: {
    ...mapState(["refreshFlag"]),
  },
  watch: {
    recipient_id() {
      this.refreshMeetings();
    },
    refreshFlag() {
      if (this.refreshFlag == "meeting") {
        this.refreshMeetings();
        this.$store.dispatch("setRefreshFlag", false);
        this.$store.dispatch("setRefreshCommentFlag", true);
      }
    },
  },
  methods: {
    refreshMeetings() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/meetings`)
        .then((res) => {
          this.meetings = res.data.data;
        });
    },
    meetingClass(meeting, type) {
      if (type == "row" && meeting.total) {
        return "table-info";
      } else {
        return;
      }
    },
    askDelete(meeting) {
      this.$toasted
        .show("Souhaitez-vous supprimer cet entretien ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(meeting._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(meeting_id) {
      axios
        .delete(`/api/web/meeting/${meeting_id}`)
        .then(() => {
          this.$toasted.show("L'entretien a été supprimé").goAway(1500);
          this.refreshMeetings();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addMeeting() {
      let payload = {
        recipient_id: this.recipient_id,
        meeting: {},
      };
      this.$emit("openMeetingModal", payload);
    },
    modifyElement(meeting) {
      let payload = {
        recipient_id: this.recipient_id,
        meeting: meeting,
      };
      this.$emit("openMeetingModal", payload);
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
  },
};
</script>

<style>
</style>