<template>
  <div id="tabs">
    <div class="row" style="margin: 0">
      <div class="col-md-12 tabs-wrapper">
        <div class="tabs-content">
          <div
            v-for="(tab, i) in tabs"
            v-on:click="() => selectTab(tab)"
            :key="i"
            :id="tab.id"
            class="content"
            :class="{ selected: tab.click }"
          >
            <div class="tab">
              {{ tab.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showTab: {},
    };
  },
  mounted() {},
  methods: {
    selectTab(tab) {
      this.showTab = tab;
      this.$emit("clickTab", tab);
    },
  },
};
</script>

<style lang="scss">
.tabs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 !important;

  .tabs-content {
    background-color: white;
    display: flex;
    align-items: center;
    margin: 5px 0 7px 0;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    min-height: 48px;
    border-radius: 5px;

    .content {
      @include tabs-content;
    }

    .selected {
      @include tabs-content-hover;
    }
  }
}
</style>