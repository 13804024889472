<template>
  <vuestic-modal
    class="mail-modal"
    ref="mailModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ title }}</span>
    </div>
    <form novalidate @submit.prevent="addMail">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <object-auto-complete
            :inputClass="getValidationClass('user_id')"
            v-model="currentMail.user_id"
            :options="users"
            field_of_value="_id"
            label="Accueillant receptionneur"
            ><span class="md-error" v-if="!$v.currentMail.user_id.required"
              >L'accueillant est requis</span
            >
          </object-auto-complete>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="mail-number">Nombre de plis</label>
            <md-input
              name="mail-number"
              id="mail-number"
              v-model="currentMail.number"
            />
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field :class="getValidationClass('date')" class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date d'arrivée :
              <vuestic-date-picker
                v-model="currentMail.date"
                :config="dateConfig"
              />
            </div>
            <span class="md-error" v-if="!$v.currentMail.date.required"
              >La date est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de reception:
              <vuestic-date-picker
                v-model="currentMail.received_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="mail-comment"
            v-model="currentMail.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import { mapState } from "vuex";
import { French } from "flatpickr/dist/l10n/fr.js";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "mail-modal",
  components: { VuesticModal, VuesticDatePicker, ObjectAutoComplete },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      users: [],
      originalMail: {},
      currentMail: {},
      mail: {},
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
    };
  },
  validations() {
    const isDate = (val) => this.$moment(val).isValid();
    return {
      currentMail: {
        user_id: {
          required,
        },
        date: {
          isDate,
          required,
        },
      },
    };
  },
  mounted() {
    this.refreshLists();
  },
  computed: {
    ...mapState(["user"]),
    title() {
      return this.mail._id ? "Modifier le courrier" : "Ajouter un courrier";
    },
  },
  methods: {
    refreshLists() {
      if (
        this.user.habilitation == "volunteer" ||
        this.user.habilitation == "antenna_admin"
      ) {
        axios
          .get(`/api/web/users/antenna/${this.user.antenna_id}`)
          .then((res) => {
            this.users = res.data.data;
          });
      } else {
        axios.get(`/api/web/users`).then((res) => {
          this.users = res.data.data;
        });
      }
    },
    refreshMail() {
      if (this.mail._id) {
        axios.get(`/api/web/mail/${this.mail._id}`).then((res) => {
          this.originalMail = this._.cloneDeep(res.data.data);
          this.currentMail = this._.cloneDeep(this.originalMail);
        });
      } else {
        this.originalMail = {};
        this.currentMail = {
          user_id: this.user._id,
          date: this.$moment().format("YYYY-MM-DD"),
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentMail[fieldName.split(".")[0]][fieldName.split(".")[1]];
      } else {
        field = this.$v.currentMail[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(mail) {
      this.$refs.mailModal.open();
      this.mail = mail;
      this.refreshMail();
      this.refreshLists();
    },
    resetForm() {
      this.currentMail = {};
      this.originalMail = {};
    },
    createMail() {
      let body = {
        mail: this.formatMail(this.currentMail),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/mails`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Courrier mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "mail");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    formatMail(mail) {
      return mail;
    },
    saveMail() {
      let body = {
        current: this.formatMail(this.currentMail),
        origin: this.originalMail,
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/mail/${this.mail._id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Courrier mis à jour").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "mail");
        } else {
          this.$toasted.error("Erreur inconnue").goAway(1500);
        }
      });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.mail._id) {
          this.saveMail();
        } else {
          this.createMail();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Courrier non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.mail-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>