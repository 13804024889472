export var newRecipientTemplate = {
    civility: "M.",
    name: null,
    surname: null,
    birth_date: null,
    birth_place: {
        city: null,
        country: null,
    },
    email: null,
    tel: null,
    nationality: "Français",
    nationality_status: null,
    france_entry: null,
    family_status: "Célibataire",
    children: 0,
    children_charge: 0,
    actif: true,
    user_ref: null,
    antenna_id: null
}