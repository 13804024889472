<template>
  <div class="activity-dashboard pl-4 pt-4 pr-4">
    <widget :headerText="getHeader()">
      <div class="d-flex align-items-center justify-content-around">
        <div class="kpi">
          <div class="kpi-header" :class="{ 'kpi-header-centered': centered }">
            Entretiens
          </div>
          <apexchart
            width="450"
            type="bar"
            :options="BarMeetings"
            :series="meetings"
          />
        </div>
        <div class="kpi">
          <div class="kpi-header" :class="{ 'kpi-header-centered': centered }">
            Nouvelles domiciliations
          </div>
          <apexchart
            width="450"
            type="bar"
            :options="BarMeetings"
            :series="meetings"
          />
        </div>
        <div class="kpi">
          <div class="kpi-header" :class="{ 'kpi-header-centered': centered }">
            Nouveaux emplois et formations
          </div>
          <apexchart
            width="450"
            type="bar"
            :options="BarMeetings"
            :series="meetings"
          />
        </div>
      </div>
    </widget>
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
export default {
  name: "activity-dashboard",
  components: { Widget },
  props: {
    user: {
      required: false,
      type: Object,
    },
    antenna: {
      required: false,
      type: Object,
    },
  },
  computed: {},
  methods: {
    getHeader() {
      if (this.user) {
        return (
          "Activité " + this.user.surname + " " + this.user.name.toUpperCase()
        );
      }
      if (this.antenna) {
        if (this.antenna.name == "Siège") {
          return "Activité globale";
        }
        return "Activité " + this.antenna.name;
      }
    },

    getCurrentColor() {
      if (this.user) {
        return "#00E396";
      }
      if (this.antenna) {
        return "#FF7F11";
      }
      return "#FF7F11";
    },
    getLastYearColor() {
      if (this.user) {
        return "#775DD0";
      }
      if (this.antenna) {
        return "#2E3532";
      }
      return "#2E3532";
    },
  },
  data: function () {
    return {
      BarMeetings: {
        chart: {
          id: "vuechart-example",
        },
        colors: [this.getCurrentColor()],
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["2024 (Total : 67)", "2023 (Total : 115)"],
          markers: {
            fillColors: [this.getCurrentColor(), this.getLastYearColor()],
          },
        },
      },
      meetings: [
        {
          name: "2024",
          data: [
            {
              x: "Janvier",
              y: 10,
              goals: [
                {
                  name: "2023",
                  value: 12,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Février",
              y: 6,
              goals: [
                {
                  name: "2023",
                  value: 5,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Mars",
              y: 14,
              goals: [
                {
                  name: "2023",
                  value: 12,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Avril",
              y: 16,
              goals: [
                {
                  name: "2023",
                  value: 15,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Mai",
              y: 21,
              goals: [
                {
                  name: "2023",
                  value: 12,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Juin",
              y: 0,
              goals: [
                {
                  name: "2023",
                  value: 18,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Juillet",
              y: 0,
              goals: [
                {
                  name: "2023",
                  value: 2,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Août",
              y: 0,
              goals: [
                {
                  name: "2023",
                  value: 0,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Septembre",
              y: 0,
              goals: [
                {
                  name: "2023",
                  value: 12,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Octobre",
              y: 0,
              goals: [
                {
                  name: "2023",
                  value: 20,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Novembre",
              y: 0,
              goals: [
                {
                  name: "2023",
                  value: 11,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
            {
              x: "Décembre",
              y: 0,
              goals: [
                {
                  name: "2023",
                  value: 6,
                  strokeHeight: 5,
                  strokeColor: this.getLastYearColor(),
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.kpi {
  .kpi-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    width: 100%;

    &.kpi-header-centered {
      text-align: center;
    }
  }

  .kpi-body {
    padding: 1.5rem;
  }
}
</style>