<template>
  <div class="new-recipient p-4">
    <form novalidate class="md-layout" @submit.prevent="validateRecipient">
      <widget :headerText="getTitle">
        <md-subheader>Référent</md-subheader>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <object-auto-complete
              v-model="currentRecipient.user_ref"
              :options="users"
              field_of_value="_id"
              label="Accueillant référent"
            >
            </object-auto-complete>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('antenna_id')">
              <label for="antenna-choice">Antenne d'accueil</label>
              <md-select
                v-model="currentRecipient.antenna_id"
                name="antenna-choice"
                id="antenna-choice"
              >
                <md-option
                  v-for="(a, i) in antennas"
                  :key="i"
                  :value="a._id"
                  :disabled="getAntennaClass(a) ? false : true"
                  >{{ a.name }}</md-option
                >
              </md-select>

              <span
                class="md-error"
                v-if="!$v.currentRecipient.antenna_id.required"
                >L'antenne d'accueil est requise</span
              >
            </md-field>
          </div>
          <div class="md-layout-item d-flex align-items-center">
            <md-field class="no-underline">
              <div class="d-flex align-items-center justify-content-center">
                Premier entretien :
                <vuestic-date-picker
                  v-model="currentRecipient.first_meeting"
                  :config="dateConfig"
                />
              </div>
            </md-field>
          </div>
        </div>
        <md-divider />
        <md-subheader>État civil</md-subheader>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="civility">Civilité</label>
              <md-select
                v-model="currentRecipient.civility"
                name="civility"
                id="civility"
              >
                <md-option value="M.">M.</md-option
                ><md-option value="Mme">Mme</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('surname')">
              <label for="first-name">Prénom</label>
              <md-input
                name="first-name"
                id="first-name"
                v-model="currentRecipient.surname"
              />
              <span
                class="md-error"
                v-if="!$v.currentRecipient.surname.required"
                >Le prénom est requis</span
              >
              <span
                class="md-error"
                v-else-if="!$v.currentRecipient.surname.minlength"
                >Prénom invalide</span
              >
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="first-name2">Prénom 2</label>
              <md-input
                name="first-name2"
                id="first-name2"
                v-model="currentRecipient.surname2"
              />
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('name')">
              <label for="last-name">Nom</label>
              <md-input
                name="last-name"
                id="last-name"
                v-model="currentRecipient.name"
              />
              <span class="md-error" v-if="!$v.currentRecipient.name.required"
                >Le nom est requis</span
              >
              <span
                class="md-error"
                v-else-if="!$v.currentRecipient.name.minlength"
                >Nom invalide</span
              >
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item d-flex align-items-center">
            <md-field
              :class="getValidationClass('birth_date')"
              class="no-underline"
            >
              <div class="d-flex align-items-center justify-content-center">
                Date de naissance :
                <vuestic-date-picker
                  v-model="currentRecipient.birth_date"
                  :config="dateConfig"
                />
              </div>
              <span
                class="md-error"
                v-if="!$v.currentRecipient.birth_date.required"
                >La date de naissance est requise</span
              >
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('birth_place.city')">
              <label for="birth-city">Ville de naissance</label>
              <md-input
                name="birth-city"
                id="birth-city"
                v-model="currentRecipient.birth_place.city"
              />
              <span
                class="md-error"
                v-if="!$v.currentRecipient.birth_place.city.required"
                >La ville de naissance est requise</span
              >
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-autocomplete
              :class="getValidationClass('birth_place.country')"
              v-model="currentRecipient.birth_place.country"
              :md-options="countries"
              :md-fuzzy-search="false"
            >
              <label>Pays de naissance</label>
              <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{
                  item
                }}</md-highlight-text></template
              >
              <span
                class="md-error"
                v-if="
                  $v.$error && !$v.currentRecipient.birth_place.country.required
                "
                >Le pays de naissance est requis</span
              >
            </md-autocomplete>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-autocomplete
              :class="getValidationClass('nationality')"
              v-model="currentRecipient.nationality"
              :md-options="nationalities"
              :md-fuzzy-search="false"
            >
              <label>Nationalité</label>
              <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{
                  item
                }}</md-highlight-text></template
              >
              <span
                class="md-error"
                v-if="$v.$error && !$v.currentRecipient.nationality.required"
                >La nationalitée est requis</span
              >
            </md-autocomplete>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('nationality_status')">
              <label for="nationality_status">Statut de nationalité</label>
              <md-select
                v-model="currentRecipient.nationality_status"
                id="nationality_status"
                name="nationality_status"
              >
                <md-option
                  v-for="(n, i) in [
                    'Nationalité Fr',
                    'Demandeur d\'asile',
                    'En attente d\'autorisation',
                    'Européen',
                    'Résident',
                    'Titre provisoire',
                    'Réfugié politique',
                    'Sans titre de séjour',
                    'Titre de séjour',
                  ]"
                  :key="i"
                  :value="n"
                  >{{ n }}</md-option
                ></md-select
              >
              <span
                class="md-error"
                v-if="!$v.currentRecipient.nationality_status.required"
                >Le statut est requis</span
              >
            </md-field>
          </div>
          <div
            v-if="statusHasValidity"
            class="md-layout-item d-flex align-items-center"
          >
            <div
              class="d-flex align-items-center justify-content-center period"
            >
              Fin de validité :
              <vuestic-date-picker
                v-model="currentRecipient.validity_end_date"
                :config="dateConfig"
              />
            </div>
          </div>
          <div class="md-layout-item d-flex align-items-center">
            <div
              class="d-flex align-items-center justify-content-center period"
            >
              En France depuis le :
              <vuestic-date-picker
                v-model="currentRecipient.france_entry"
                :config="dateConfig"
              />
            </div>
          </div>
        </div>
        <md-divider />
        <md-subheader>Famille</md-subheader>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="family_status">Situation familiale</label>
              <md-select
                v-model="currentRecipient.family_status"
                name="family_status"
                id="family_status"
              >
                <md-option
                  v-for="(f, i) in family_status"
                  :key="i"
                  :value="f"
                  >{{ f }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="children">Enfants</label>
              <md-input
                name="children"
                id="children"
                type="number"
                v-model="currentRecipient.children"
              />
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="children_charge">Dont à charge</label>
              <md-input
                name="children_charge"
                id="children_charge"
                type="number"
                v-model="currentRecipient.children_charge"
              />
            </md-field>
          </div>
        </div>
        <md-divider />
        <md-subheader>Contact accueilli</md-subheader>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="tel">Téléphone</label>
              <md-input
                name="tel"
                id="tel"
                autocomplete="tel"
                v-model="currentRecipient.tel"
              />
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="role">Email</label>
              <md-input
                name="email"
                id="email"
                autocomplete="email"
                v-model="currentRecipient.email"
              />
            </md-field>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn" :class="'btn-primary'" type="submit">
            Enregistrer
          </button>
        </div>
      </widget>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Widget from "../../components/widgets/Widget.vue";
import axios from "axios";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import { French } from "flatpickr/dist/l10n/fr.js";
import { newRecipientTemplate } from "./recipientTemplate.js";
import { mapState } from "vuex";
import { habilitationList } from "../../services/utils";
export default {
  components: { Widget, VuesticDatePicker, ObjectAutoComplete },
  name: "new-recipient",
  mixins: [validationMixin],
  data() {
    return {
      users: [],
      antennas: [],
      countries: ["France", "Espagne"],
      nationalities: ["Français", "Espagnol"],
      nationality_status: ["Nationalité Fr", "Réfugié"],
      family_status: ["Célibataire", "Marié"],
      currentRecipient: this._.cloneDeep(newRecipientTemplate),
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      fileNumber: 1,
    };
  },
  validations() {
    let val = {
      currentRecipient: {
        antenna_id: {
          required,
        },
        surname: {
          required,
          minLength: minLength(1),
        },
        name: {
          required,
          minLength: minLength(1),
        },
        nationality: {
          required,
        },
        nationality_status: {
          required,
        },
        birth_date: {
          required,
        },
        birth_place: {
          city: {
            required,
          },
          country: { required },
        },
        family_status: {
          required,
        },
      },
    };
    return val;
  },
  computed: {
    ...mapState(["user"]),
    getTitle() {
      return "Nouveau dossier (n°" + this.fileNumber.toString() + ")";
    },
    statusHasValidity() {
      return (
        ["Titre de séjour", "Titre provisoire", "Résident"].indexOf(
          this.currentRecipient.nationality_status
        ) >= 0
      );
    },
  },
  mounted() {
    this.refreshChoices();
    this.refreshUsers();
    this.refreshAntennas();
    this.getLastFile();
    this.currentRecipient.first_meeting = this.$moment().format("YYYY-MM-DD");
  },
  methods: {
    getLastFile() {
      axios.get(`/api/web/last_file`).then((res) => {
        this.fileNumber = res.data.data;
      });
    },
    refreshChoices() {
      axios.get(`/api/web/choices/country`).then((res) => {
        this.countries = res.data.data.map((item) => item.label);
      });
      axios.get(`/api/web/choices/nationality`).then((res) => {
        this.nationalities = res.data.data.map((item) => item.label);
      });
      axios.get(`/api/web/choices/nationality_status`).then((res) => {
        this.nationality_status = res.data.data.map((item) => item.label);
      });
      axios.get(`/api/web/choices/family_status`).then((res) => {
        this.family_status = res.data.data.map((item) => item.label);
      });
    },
    formatBody(rec) {
      if (rec.children) {
        rec.children = parseInt(rec.children);
      }
      if (rec.children_charge) {
        rec.children_charge = parseInt(rec.children_charge);
      }
      rec.file_number = this.fileNumber;
      return rec;
    },
    validateRecipient() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.sending = true;
        let body = {
          recipient: this.formatBody(this.currentRecipient),
        };
        axios
          .put("/api/web/recipient/new", body)
          .then((res) => {
            if (res.data.success) {
              this.$toasted.show("Nouvel accueilli enregistré").goAway(1500);
              let id = res.data.data.recipient_id;
              this.currentRecipient = newRecipientTemplate;
              this.$router.push(`/recipient/${id}#id`);
            } else {
              this.$toasted.error("Accueilli non enregistré").goAway(1500);
            }
          })
          .catch((e) => {
            if (e.response.data.data.message == "SIMILAR_RECIPIENT_EXISTS") {
              this.$toasted.error(
                "Un accueilli existe déjà (même prénom, nom et date de naissance) dans l'antenne " +
                  e.response.data.data.antenna.name
              );
            }
          });
      } else {
        this.$toasted
          .error("Tous les champs nécessaires ne sont pas remplis")
          .goAway(2000);
      }
    },
    getAntennaClass(antenna) {
      return (
        habilitationList.indexOf(this.user.habilitation) <= 3 ||
        this.user.antenna_id == antenna._id
      );
    },

    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentRecipient[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentRecipient[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshUsers() {
      axios
        .get("/api/web/users")
        .then((res) => {
          this.users = res.data.data;
          if (habilitationList.indexOf(this.user.habilitation) > 3) {
            this.users = this.users.filter((item) => {
              return item.antenna_id == this.user.antenna_id;
            });
          }
          this.currentRecipient.user_ref = this.user._id;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refreshAntennas() {
      axios
        .get("/api/web/antennas")
        .then((res) => {
          this.antennas = res.data.data.filter((a) => {
            return a.acr == undefined;
          });
          this.currentRecipient.antenna_id = this.user.antenna_id;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
.md-subheader {
  font-size: 1.2rem;
}
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}

.period {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>