<template>
  <vuestic-modal
    class="choice-modal"
    ref="choiceModal"
    :okText="'Enregistrer'"
    @ok="okAction"
  >
    <div slot="title">
      <span class="center">{{ modalTitle }}</span>
    </div>
    <form>
      <b-form-textarea id="choice" v-model="choiceText" rows="3" max-rows="6" />
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import axios from "axios";
export default {
  name: "choice-modal",
  components: { VuesticModal },
  props: {
    item: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      choiceText: null,
    };
  },
  watch: {
    item(val) {
      this.choiceText = val.label;
    },
  },
  computed: {
    modalTitle() {
      if (this.item._id) {
        return "Modification de l'entrée";
      } else {
        return "Nouveau choix dans la table " + this.type;
      }
    },
  },
  methods: {
    open() {
      this.$refs.choiceModal.open();
    },
    okAction() {
      if (this.item._id) {
        this.updateChoice();
      } else {
        this.createChoice();
      }
    },
    createChoice() {
      let body = {};
      body[this.type] = {
        label: this.choiceText,
      };
      axios.put(`/api/web/choices/${this.type}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Élément enregistré").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "choice");
        } else {
          this.$toasted.error("Élément non enregistré").goAway(1500);
        }
      });
    },
    updateChoice() {
      let origin = this._.cloneDeep(this.item);
      let current = this._.cloneDeep(this.item);
      current.label = this.choiceText;
      let body = {
        current: current,
        origin: origin,
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/choice/${this.item._id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Élément enregistré").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "choice");
        } else {
          this.$toasted.error("Élément non enregistré").goAway(1500);
        }
      });
    },
  },
};
</script>

<style>
</style>