<template>
  <vuestic-modal
    class="comment-modal"
    ref="commentModal"
    :okText="'Enregistrer'"
    @ok="okAction"
    @cancel="resetForm"
  >
    <div slot="title">
      <span v-if="currentComment._id != undefined" class="center"
        >Nouveau commentaire</span
      >
      <span v-else class="center">Modification du commentaire</span>
    </div>
    <form>
      <b-form-textarea
        id="comment"
        v-model="currentComment.text"
        rows="3"
        max-rows="6"
      />
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import axios from "axios";
export default {
  name: "comment-modal",
  components: { VuesticModal },
  data() {
    return {
      currentComment: {
        text: "",
      },
      originComment: {
        text: "",
      },
    };
  },
  methods: {
    open(comment = null) {
      this.$refs.commentModal.open();
      if (comment !== null) {
        this.originComment = this._.cloneDeep(comment);
        this.currentComment = this._.cloneDeep(comment);
      }
    },
    resetForm() {
      this.commentText = null;
    },
    okAction() {
      if (this.currentComment._id === undefined) {
        let body = {
          comment: this.currentComment,
        };
        axios.put(`/api/web/comment/new`, body).then((res) => {
          if (res.data.success) {
            this.$toasted.show("Commentaire enregistré").goAway(1500);
            this.$store.dispatch("setRefreshCommentFlag", true);
            this.resetForm();
          } else {
            this.$toasted.error("Commentaire non enregistré").goAway(1500);
          }
        });
      } else {
        let body = {
          current: this.currentComment,
          origin: this.originComment,
        };
        axios
          .post(`/api/web/comment/${this.currentComment._id}`, body)
          .then((res) => {
            if (res.data.success) {
              this.$toasted.show("Commentaire enregistré").goAway(1500);
              this.$store.dispatch("setRefreshCommentFlag", true);
              this.resetForm();
            } else {
              this.$toasted.error("Commentaire non enregistré").goAway(1500);
            }
          });
      }
    },
  },
};
</script>

<style>
</style>