import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import auth from "./services/auth"
import VueLodash from "vue-lodash";
import lodash from "lodash"
import Toasted from "vue-toasted"
import VCalendar from 'v-calendar';
import VueMaterial from 'vue-material'
import Vuelidate from 'vuelidate'
import vueDebounce from 'vue-debounce'

import DatePicker from 'vuejs-datepicker'
import BootstrapVue from "bootstrap-vue";
import Vuesax from 'vuesax'
import ToggleButton from 'vue-js-toggle-button'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#66CCFF',
      success: 'rgb(23, 201, 100)',
      danger: 'rgb(242, 19, 93)',
      warning: 'rgb(255, 130, 0)',
      dark: '#2E517B',
      light: 'rgb(255, 130, 0)'
    }
  }
})

Vue.use(ToggleButton)
Vue.use(VueMaterial)
Vue.use(Vuelidate)
Vue.use(vueDebounce)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.use(DatePicker)
Vue.use(BootstrapVue)

Vue.use(require("vue-moment"))
Vue.use(require("vue-loading-spinner"))

Vue.use(Toasted, { onComplete: () => { console.log("toast"); } })

Vue.config.ignoredElements = [/^ion-/];

Vue.use(VueLodash, { name: "custom", lodash: lodash })

Vue.config.productionTip = false;

window.$vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.parent.document.title = "Ulysse"

router.beforeEach((to, from, next) => {
  // Vérification que l'utilisateur est bien connecté
  if (!(auth.isLoggedIn() || to.path.startsWith('/auth'))) {
    next({
      path: '/auth/login',
      query: {
        redirect: to.path,
      },
    })
  } else {
    next()
  }
})


// Vérification que l'utilisateur est bien connecté
auth.checkTokenValidity()
setInterval(() => auth.checkTokenValidity(), 5 * 60 * 1000)