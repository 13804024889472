<template>
  <div class="vuestic-date-picker-container">
    <div class="d-flex flex-row justify-content-center">
      <vue-flatpickr-component
        ref="date-pick"
        class="vuestic-date-picker"
        :class="{ 'has-value': valueProxy && valueProxy.length }"
        v-model="valueProxy"
        @on-open="isOpen = true"
        @on-close="isOpen = false"
        v-on="$listeners"
        :config="fullConfig"
      />
      <!-- <button class="btn-today" type="button" @click="setToday" v-tooltip.right="$t('words.today')">
      <i class="fal fa-calendar-day"></i>
    </button> -->
    </div>
  </div>
</template>

<script>
import VueFlatpickrComponent from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { French } from "flatpickr/dist/l10n/fr";
import { Spanish } from "flatpickr/dist/l10n/es";
import { English } from "flatpickr/dist/l10n/default";
export default {
  name: "vuestic-date-picker",
  components: {
    VueFlatpickrComponent,
  },
  props: {
    value: {
      required: true,
    },
    config: {
      default: () => {
        return {};
      },
    },
  },
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    fullConfig() {
      return Object.assign({}, this.defaultConfig, this.config);
    },
    defaultConfig() {
      return {
        // allowInput: true, // false triggers `readonly` on input, which disables label animation.
        nextArrow:
          '<span aria-hidden="true" class="ion ion-ios-arrow-forward"></span>',
        prevArrow:
          '<span aria-hidden="true" class="ion ion-ios-arrow-back"></span>',
        locale: "fr",
        dateFormat: "d/m/Y",
        closeOnSelect: true,
        allowInput: true,
        allowInvalidPreload: true,
        altFormat: "d/m/Y",
        disableMobile: true,
      };
    },
    momentFormat() {
      let mapObj = {
        d: "DD",
        m: "MM",
        F: "MMMM",
        Y: "YYYY",
        H: "HH",
        i: "mm",
      };
      let re = new RegExp(Object.keys(mapObj).join("|"), "gi");
      return this.fullConfig.dateFormat.replace(
        re,
        (matched) => mapObj[matched]
      );
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    setLanguage(locale) {
      switch (locale) {
        case "fr_FR":
        case "fr":
          return French;

        case "en_GB":
        case "en_US":
        case "en":
          return English;

        case "es_ES":
          return Spanish;

        default:
          return French;
      }
    },
    setToday() {
      this.$emit(
        "input",
        this.$moment().startOf("day").format(this.momentFormat)
      );
    },
  },
};
</script>

<style lang="scss">
$datepickerActive: $ulysse-blue;
$datepickerBackground: #333333;
$datepickerText: $white;

$datepickerToday: lighten($datepickerBackground, 5);
$datepickerOtherMonth: darken($datepickerText, 40);
$datepickerWeekday: darken($datepickerText, 50);
$datepickerDayHover: darken($datepickerText, 70);
$datepickerDayRange: $almost-black;
$datepickerSeparatorColor: $almost-black;

$borderPadding: 0.5rem;
$dayPadding: 0.375rem;
$daySize: 1.75rem;

.vuestic-date-picker-container {
  &:not(:last-child) {
    margin-right: 1.08rem;
  }
  .btn-today {
    // border-radius: 5px;
    height: 25px;
    width: 25px;
    border: 1px solid lighten($datepickerBackground, 25);
    border-top-left-radius: 4px;
  }
}
input.vuestic-date-picker {
  font-size: 1rem;
}
.flatpickr-calendar {
  width: $daySize * 7 + $dayPadding * 6 + $borderPadding * 2 !important;
  background-color: $datepickerBackground;
  border-radius: 0;
  box-shadow: none;

  &.arrowTop {
    &:before {
      content: none;
      border-bottom-color: $datepickerBackground;
    }
    &:after {
      content: none;
      border-bottom-color: $datepickerBackground;
    }
  }

  &.arrowBottom {
    &:before {
      content: none;
      border-top-color: $datepickerBackground;
    }
    &:after {
      content: none;
      border-top-color: $datepickerBackground;
    }
  }

  .flatpickr-days {
    width: $daySize * 7 + $dayPadding * 6 + $borderPadding * 2 !important;
    padding: 0 $borderPadding $borderPadding;
    display: block;
    .dayContainer {
      width: ($daySize + $dayPadding) * 7;
      max-width: ($daySize + $dayPadding) * 7;
      min-width: ($daySize + $dayPadding) * 7;
      margin: -$dayPadding / 2;
      .flatpickr-day {
        height: $daySize;
        line-height: 1.7;
        font-size: 1rem;
        flex: 0 0 $daySize;
        margin: $dayPadding / 2;
        border: none;
        color: $datepickerText;
        &.today {
          background-color: $datepickerToday;
        }
        &:hover {
          background-color: $datepickerDayHover;
        }
        &.selected {
          background-color: $datepickerActive;
        }
        &.nextMonthDay,
        &.prevMonthDay {
          color: $datepickerOtherMonth;
        }
        &.disabled {
          color: $datepickerOtherMonth;
          &:hover {
            background-color: inherit;
          }
        }
        &.startRange,
        &.endRange,
        &.inRange {
          border-radius: 2rem;
          box-shadow: none;
        }
        &.startRange,
        &.endRange {
          background-color: $datepickerActive;
        }
        &.inRange {
          background-color: $datepickerDayRange;
        }
      }
    }
  }

  .flatpickr-months {
    height: 2.625rem;
    border-bottom: solid 0.125rem $datepickerSeparatorColor;
    .flatpickr-month {
      height: 100%;
      .flatpickr-current-month {
        padding-top: 0.625rem;
        .flatpickr-monthDropdown-months {
          &:hover {
            background-color: $datepickerDayHover;
          }
          cursor: pointer;
          color: $datepickerText;
          outline: none;
          overflow: hidden;
          text-indent: 0.01px;
          text-overflow: "";
          background: url("https://www.freeiconspng.com/uploads/white-down-arrow-png-2.png")
            no-repeat right 2px bottom 5px;
          background-size: auto;
          background-size: 13px;
          background-color: $datepickerBackground;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          /* width: 100%; */
          font-size: 0.9rem;
          height: 1.71rem;
          padding: 0.1125rem 0.1125rem 0.05625rem;
          padding-right: 0.1125rem;
          padding-right: 18px;
          border: none;
          line-height: 1.6;
          -webkit-box-shadow: none;
          box-shadow: none;
          text-align: right;
        }
        .cur-month {
          font-size: 1rem;
          font-weight: inherit;
          color: $datepickerText;
        }
        .cur-year {
          font-size: 1rem;
        }
        .numInputWrapper {
          .numInput.cur-year {
            color: $white;
          }

          .arrowUp {
            &:hover {
              &::after {
                border-bottom-color: $datepickerActive;
              }
            }
            &::after {
              border-bottom-color: $datepickerText;
            }
          }
          .arrowDown {
            &:hover {
              &::after {
                border-top-color: $datepickerActive;
              }
            }
            &::after {
              border-top-color: $datepickerText;
            }
          }
        }
      }
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      @include flex-center();
      height: 2.625rem;
      width: 1.625rem;
      padding: 0;
      color: $datepickerText;
      &:hover {
        color: $datepickerActive;
      }
    }
  }

  .flatpickr-weekdays {
    padding: 0 $borderPadding;
    .flatpickr-weekdaycontainer {
      justify-content: space-between;
      .flatpickr-weekday {
        letter-spacing: 0.0625rem;
        flex: 0 0 $daySize;
        color: $datepickerWeekday;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.6875rem;
      }
    }
  }

  .flatpickr-weekwrapper {
    .flatpickr-weekday {
      text-transform: uppercase;
      letter-spacing: 0.0625rem;
      color: $datepickerWeekday;
      font-weight: bold;
      font-size: 0.6875rem;
    }
    .flatpickr-weeks {
      box-shadow: 2px 0 0 $datepickerSeparatorColor;
      padding-bottom: $borderPadding;
      margin-top: -$dayPadding;
      .flatpickr-day {
        height: $daySize;
        line-height: 1.7;
        font-size: 1rem;
        flex: 0 0 $daySize;
        margin-top: $dayPadding;
        color: $datepickerOtherMonth;
      }
    }
  }

  &.showTimeInput.hasTime .flatpickr-time {
    border-top: solid 0.125rem $datepickerSeparatorColor;
    :hover {
      background-color: $datepickerDayHover;
    }
    .numInputWrapper {
      &:hover {
        background-color: $datepickerDayHover;
      }
      .numInput {
        color: $datepickerText;
      }
      .arrowUp {
        &::after {
          border-bottom-color: $datepickerText;
        }
        &:hover::after {
          border-bottom-color: $datepickerActive;
        }
      }
      .arrowDown {
        &::after {
          border-top-color: $datepickerText;
        }
        &:hover::after {
          border-top-color: $datepickerActive;
        }
      }
    }
    .flatpickr-time-separator {
      color: $datepickerText;
    }
    .flatpickr-am-pm {
      color: $datepickerText;
      &:hover {
        background-color: $datepickerDayHover;
      }
    }
  }
  .shortcut-buttons-flatpickr-button {
    color: white;
    border: none;
    background-color: $datepickerBackground;
    border-radius: 5px;
    margin-bottom: 5px;
    &:hover {
      background-color: $datepickerDayHover;
    }
  }
}
</style>
