<template>
  <div class="contact-form">
    <md-checkbox v-model="isInBase" :value="true" class="md-primary mt-2"
      >Contact depuis la base</md-checkbox
    >
    <form v-if="isInBase" novalidate @submit.prevent="addContact">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <object-auto-complete
            v-model="selectedOrganism"
            :options="organisms"
            field_of_value="_id"
            label="Organisme"
            :formatter="
              (d) => {
                return d.name;
              }
            "
          >
          </object-auto-complete>
        </div>
        <div class="md-layout-item">
          <object-auto-complete
            v-model="currentContact.contact_id"
            :options="contacts"
            field_of_value="_id"
            label="Contact"
          >
          </object-auto-complete>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date :
              <vuestic-date-picker
                v-model="currentContact.date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
    </form>
    <form v-else novalidate @submit.prevent="addContact">
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date :
              <vuestic-date-picker
                v-model="currentContact.date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <md-subheader>Organisme</md-subheader>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label for="organism_id">Organisme</label>
            <md-select
              name="organism_id"
              id="organism_id"
              v-model="selectedOrganismForNew"
            >
              <md-option :value="'new'">
                {{ "Nouvel organisme" }}
              </md-option>
              <md-option v-for="(o, i) in organisms" :key="i" :value="o._id">
                {{ o.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <div v-if="selectedOrganismForNew == 'new'" class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="name_org">Nom de l'organisme</label>
            <md-input
              name="name_org"
              id="name_org"
              v-model="currentContact.organism.name"
            />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="domain">Domaine</label>
            <md-select
              name="domain"
              id="domain"
              v-model="currentContact.organism.domain_id"
            >
              <md-option
                v-for="(p, i) in organism_domains"
                :key="i"
                :value="p._id"
              >
                {{ p.label }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="postal_org">Code postal</label>
            <md-input
              name="postal_org"
              id="postal_org"
              v-model="currentContact.organism.address.postal_code"
            />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="city_org">Ville</label>
            <md-input
              name="city_org"
              id="city_org"
              v-model="currentContact.organism.address.city"
            />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="address_org">Adresse</label>
            <md-input
              name="address_org"
              id="address_org"
              v-model="currentContact.organism.address.street"
            />
          </md-field>
        </div>
      </div>
      <md-subheader>Contact</md-subheader>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="contact_civ">Civilité</label>
            <md-select
              name="contact_civ"
              id="contact_civ"
              v-model="currentContact.contact.civility"
            >
              <md-option
                v-for="(c, i) in ['M.', 'Mme', 'Dr']"
                :key="i"
                :value="c"
                >{{ c }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="contact_name">Nom</label>
            <md-input
              name="contact_name"
              id="contact_name"
              v-model="currentContact.contact.name"
            />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="contact_surname">Prénom</label>
            <md-input
              name="contact_surname"
              id="contact_surname"
              v-model="currentContact.contact.surname"
            />
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="contact_fonction">Fonction</label>
            <md-select
              name="contact_fonction"
              id="contact_fonction"
              v-model="currentContact.contact.fonction_id"
            >
              <md-option
                v-for="(p, i) in contact_fonctions"
                :key="i"
                :value="p._id"
              >
                {{ p.label }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="contact_tel">Téléphone</label>
            <md-input
              name="contact_tel"
              id="contact_tel"
              v-model="currentContact.contact.tel"
            />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="contact_email">Email</label>
            <md-input
              name="contact_email"
              id="contact_email"
              v-model="currentContact.contact.email"
            />
            <a
              v-if="currentContact.email"
              :href="`mailto:${currentContact.email}`"
              ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
            ></a>
          </md-field>
        </div>
      </div>
    </form>
    <button
      v-if="emitButton"
      class="btn btn-secondary"
      type="button"
      @click="okAction"
    >
      Lier le contact
    </button>
  </div>
</template>

<script>
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
export default {
  name: "contact-form",
  components: { VuesticDatePicker, ObjectAutoComplete },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
    emitButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInBase: true,
      contacts: [],
      organisms: [],
      organism_domain: [],
      contact_fonctions: [],
      selectedOrganism: "new",
      selectedOrganismForNew: "new",
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      currentContact: {},
      originContact: {},
      contact: {},
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.refreshDomains();
    this.refreshOrganisms();
    this.refreshContacts();
  },
  watch: {
    selectedOrganism() {
      this.refreshContacts();
    },
    selectedOrganismForNew() {
      if (!this.isInBase) {
        this.refreshOrganism();
      }
    },
    isInBase() {
      if (this.isInBase) {
        this.currentContact = this._.cloneDeep(this.originContact);
      } else {
        if (this.currentContact.contact == undefined) {
          this.currentContact.contact = {};
        }
        if (this.currentContact.organism == undefined) {
          this.currentContact.organism = {
            address: {},
          };
        }
      }
      if (this.currentContact.date == undefined) {
        this.currentContact.date = this.$moment().format("YYYY-MM-DD");
      }
    },
  },
  methods: {
    getTitle() {
      return this.contact._id ? "Modification d'un contact" : "Nouveau contact";
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentContact[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentContact[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshFonctions() {
      axios.get(`/api/web/choices/contact_fonction`).then((res) => {
        this.contact_fonctions = res.data.data;
      });
    },
    refreshDomains() {
      axios.get(`/api/web/choices/organism_domain`).then((res) => {
        this.organism_domains = res.data.data;
      });
    },
    refreshOrganisms() {
      axios.get("/api/web/organisms").then((res) => {
        this.organisms = res.data.data;
      });
    },
    refreshOrganism() {
      if (this.selectedOrganismForNew != "new") {
        axios
          .get(`/api/web/organism/${this.selectedOrganismForNew}`)
          .then((res) => {
            this.currentContact.organism = res.data.data;
          });
      } else {
        this.currentContact.organism = {
          address: {},
        };
      }
    },
    refreshContacts() {
      let url = "";
      if (this.selectedOrganism != "new") {
        url = `/api/web/organism/${this.selectedOrganism}/contacts`;
      } else {
        url = "/api/web/contacts";
      }
      axios.get(url).then((res) => {
        this.contacts = res.data.data;
      });
    },
    open(contact) {
      this.contact = contact ? contact : {};
      this.originContact = this._.cloneDeep(this.contact);
      this.currentContact = this._.cloneDeep(this.contact);
      if (this.currentContact._id == undefined) {
        this.currentContact.date = this.$moment().format("YYYY-MM-DD");
      }
      if (this.contact.organism && this.contact.organism._id) {
        this.selectedOrganism = this.contact.organism._id;
      } else if (this.contact.organism_id) {
        this.selectedOrganism = this.contact.organism_id;
      } else {
        this.selectedOrganism = "new";
      }
      if (
        this.currentContact.contact_id == undefined &&
        this.currentContact.organism_id == undefined &&
        this.currentContact._id
      ) {
        this.isInBase = false;
      }
      this.refreshOrganisms();
      this.refreshContacts();
      this.refreshDomains();
      this.refreshFonctions();
    },
    formatContact(contact) {
      if (!contact.contact_id) {
        contact.organism_id = this.selectedOrganism;
      }
      if (this.selectedOrganismForNew != "new") {
        contact.organism_id = this.selectedOrganismForNew;
      }
      return contact;
    },
    resetForm() {
      this.originContact = {};
      this.currentContact = {};
      this.selectedOrganism = "new";
      this.selectedOrganismForNew = "new";
      this.isInBase = true;
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$error) {
        let contact_id = this.contact._id;
        if (this.contact._id) {
          this.saveContact();
        } else {
          let created_contact_id = this.createContact();
          contact_id = created_contact_id ? created_contact_id : contact_id;
        }
        this.$v.$reset();
        this.resetForm();
        return contact_id;
      } else {
        this.$toasted.show("Des champs sont manquants").goAway(1500);
        return false;
      }
    },
    createContact() {
      let body = {};
      if (this.isInBase) {
        body = {
          contact_ref: this.formatContact(this.currentContact),
        };
      } else {
        body = {
          contact_other: this.currentContact,
        };
        if (this.selectedOrganism != "new") {
          this.$toasted.show("Nouveau contact enregistré en base").goAway(1500);
        } else {
          this.$toasted
            .show("Nouveaux contacts et organismes enregistrés en base")
            .goAway(1500);
        }
      }
      let result = null;
      axios
        .put(`/api/web/recipient/${this.recipient_id}/contacts`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Contact ajouté").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "contact");
            result = res.data.data;
            this.$emit("contact_id", { contact_id: result });
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        })
        .catch((e) => {
          if (e.response.data.data.message == "ORGANISM_ALREADY_EXISTS") {
            this.$toasted
              .error(
                "L'organisme " +
                  body.contact_other.organism.name +
                  " existe déjà."
              )
              .goAway(2500);
          }
        });

      return true;
    },
    saveContact() {
      let body = {};

      if (this.isInBase) {
        body = {
          contact_ref: {
            current: this.formatContact(this.currentContact),
            origin: this.contact,
            fail_on_concurrent: false,
          },
        };
      } else {
        body = {
          contact_other: {
            current: this.currentContact,
            origin: this.contact,
            fail_on_concurrent: false,
          },
        };
      }
      axios
        .post(
          `/api/web/recipient/${this.recipient_id}/contact/${this.contact._id}`,
          body
        )
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Contact mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "contact");
            this.$emit("contact_id", { contact_id: this.contact._id });
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        })
        .catch((e) => {
          if (e.response.data.data.message == "ORGANISM_ALREADY_EXISTS") {
            this.$toasted
              .error(
                "L'organisme " +
                  body.contact_other.organism.name +
                  " existe déjà."
              )
              .goAway(2500);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.contact-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>