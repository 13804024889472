<template>
  <div class="contact">
    <div class="contact-info pt-4 pr-4 pl-4">
      <form novalidate class="md-layout" @submit.prevent="validateContact">
        <widget :headerText="getContactTitle">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="civility">Civilité</label>
                <md-select
                  :disabled="!hasRightsToModify"
                  name="civility"
                  id="civility"
                  v-model="currentContact.civility"
                >
                  <md-option
                    v-for="(p, i) in ['M.', 'Mme', 'Dr']"
                    :key="i"
                    :value="p"
                  >
                    {{ p }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field :class="getValidationClass('surname')">
                <label for="contact-surname">Prénom</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="contact-surname"
                  id="contact-surname"
                  v-model="currentContact.surname"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentContact.surname.required"
                  >Le prénom est requis</span
                >
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field :class="getValidationClass('name')">
                <label for="contact-name">Nom</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="contact-name"
                  id="contact-name"
                  v-model="currentContact.name"
                />
                <span class="md-error" v-if="!$v.currentContact.name.required"
                  >Le nom est requis</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="organism">Organisme</label>
                <md-select
                  :disabled="!hasRightsToModify"
                  name="organism"
                  id="organism"
                  v-model="currentContact.organism_id"
                >
                  <md-option
                    v-for="(p, i) in organisms"
                    :key="i"
                    :value="p._id"
                  >
                    {{ p.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="contact-fonction">Fonction</label>
                <md-select
                  :disabled="!hasRightsToModify"
                  name="contact-fonction"
                  id="contact-fonction"
                  v-model="currentContact.fonction_id"
                >
                  <md-option
                    v-for="(p, i) in contact_fonctions"
                    :key="i"
                    :value="p._id"
                  >
                    {{ p.label }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="contact-status">Statut</label>
                <md-select
                  :disabled="!hasRightsToModify"
                  name="contact-status"
                  id="contact-status"
                  v-model="currentContact.status_id"
                >
                  <md-option
                    v-for="(p, i) in contact_status"
                    :key="i"
                    :value="p._id"
                  >
                    {{ p.label }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <md-subheader>Contact</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field :class="getValidationClass('tel')">
                <label for="contact-tel">Téléphone</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="contact-tel"
                  id="contact-tel"
                  v-model="currentContact.tel"
                />
                <span class="md-error" v-if="!$v.currentContact.tel.required"
                  >Le Téléphone est requis</span
                >
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="contact-mobile">Mobile</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="contact-mobile"
                  id="contact-mobile"
                  v-model="currentContact.mobile"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="contact-email">Email</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="contact-email"
                  id="contact-email"
                  v-model="currentContact.email"
                />
                <a
                  v-if="currentContact.email"
                  :href="`mailto:${currentContact.email}`"
                  ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
                ></a>
              </md-field>
            </div>
          </div>
          <md-subheader>Autre</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-checkbox
                :disabled="!hasRightsToModify"
                v-model="currentContact.archived"
                :value="true"
                class="md-primary mt-2"
                >Archivé</md-checkbox
              >
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              Remarque :
              <b-form-textarea
                :disabled="!hasRightsToModify"
                class="pt-2"
                id="adress"
                v-model="currentContact.remarque"
                rows="3"
                max-rows="3"
              />
            </div>
          </div>
          <button
            v-if="hasRightsToModify"
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </widget>
      </form>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget.vue";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  name: "contact",
  components: { Widget },
  mixins: [validationMixin],
  data() {
    return {
      contact_fonctions: [],
      contact_status: [],
      organisms: [],
      originContact: {
        address: {},
        contacts: [],
      },
      currentContact: {
        address: {},
        contacts: [],
      },
    };
  },
  validations: {
    currentContact: {
      surname: {
        required,
      },
      name: {
        required,
      },
      tel: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["user", "antennas", "refreshFlag"]),
    contact_id() {
      return this.$route.params.contact_id;
    },
    getContactTitle() {
      return this.contact_id == "new"
        ? "Nouveau contact"
        : "Informations du contact";
    },
    isNewContact() {
      return this.contact_id == "new";
    },
    hasRightsToModify() {
      return (
        this.user.rights.indexOf("organism_management") > 0 ||
        this.user.antenna_id == this.originContact.antenna_id ||
        this.originContact._id == undefined
      );
    },
  },
  mounted() {
    this.refreshOrganisms();
    this.refreshFonctions();
    this.refreshContact();
  },
  watch: {
    contact_id() {
      this.refreshContact();
    },
    refreshFlag(flag) {
      if (flag == "contact") {
        this.refreshContact();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshFonctions() {
      axios.get(`/api/web/choices/contact_fonction`).then((res) => {
        this.contact_fonctions = res.data.data;
      });
      axios.get(`/api/web/choices/contact_status`).then((res) => {
        this.contact_status = res.data.data;
      });
    },
    refreshOrganisms() {
      axios.get(`/api/web/organisms`).then((res) => {
        this.organisms = res.data.data;
      });
    },
    refreshContact() {
      if (!this.isNewContact) {
        axios.get(`/api/web/contact/${this.contact_id}`).then((res) => {
          this.originContact = res.data.data;
          this.currentContact = this._.cloneDeep(this.originContact);
        });
      } else {
        this.currentContact = {};
        if (this.$route.query && this.$route.query.organism_id) {
          this.currentContact["organism_id"] = this.$route.query.organism_id;
        }
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentContact[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentContact[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    formatContact(contact) {
      if (contact.archived == null) {
        contact.archived = false;
      }
      if (contact.etiquette == null) {
        contact.etiquette = false;
      }
      return contact;
    },
    createContact() {
      let body = {
        contact: this.formatContact(this.currentContact),
      };
      axios.put("/api/web/contact/new", body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Contact enregistré").goAway(1500);
          let id = res.data.data.contact_id;
          this.$router.push(`/contact/${id}`);
        } else {
          this.$toasted.error("Contact non enregistré").goAway(1500);
        }
      });
    },
    saveContact() {
      let body = {
        current: this.formatContact(this.currentContact),
        origin: this.formatContact(this.originContact),
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/contact/${this.contact_id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Contact enregistré").goAway(1500);
          this.refreshContact();
        } else {
          this.$toasted.error("Contact non enregistré").goAway(1500);
        }
      });
    },
    validateContact() {
      if (this.hasRightsToModify) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.isNewContact) {
            this.createContact();
          } else {
            this.saveContact();
          }
        } else {
          this.$toasted.show("Des champs sont manquants").goAway(2000);
        }
      } else {
        this.$toasted
          .show("Vous n'avez pas les droits nécessaires")
          .goAway(2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>