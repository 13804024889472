<template>
  <div class="housing-help">
    <widget headerText="Aides au logement">
      <b-table
        v-if="housingHelps.length > 0"
        striped
        hover
        :items="housingHelps"
        :fields="fields"
      >
        <template #cell(actions)="row">
          <ion-icon
            name="pencil"
            v-b-tooltip.hover
            style="cursor: pointer"
            title="Modifier les informations"
            @click="edit(row.item)"
          ></ion-icon>
          <ion-icon
            name="trash"
            v-b-tooltip.hover
            style="cursor: pointer"
            title="Supprimer"
            @click="askDelete(row.item)"
          ></ion-icon> </template
      ></b-table>
      <button class="btn btn-primary" type="button" @click="addHousingHelp">
        Ajouter un élément
      </button>
    </widget>
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "housing-help",
  components: { Widget },
  props: {
    recipient_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      housingHelps: [],
      fields: [
        {
          key: "type",
          label: "Type de demande",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
        },
        {
          key: "id_number",
          label: "Identifiant",
        },
        {
          key: "ask_date",
          label: "Date de demande",
          sortable: true,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "answer_date",
          label: "Date de réponse",
          sortable: true,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "validity_start_date",
          label: "Début de validité",
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "validity_end_date",
          label: "Fin de validité",
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "contact.organism",
          label: "Organisme",
          formatter: (val) => {
            if (val) {
              return val.name;
            } else {
              return "";
            }
          },
        },
        {
          key: "contact.contact",
          label: "Contact",
          formatter: (val) => {
            if (val) {
              return (
                val.surname + " " + (val.name ? val.name.toUpperCase() : "")
              );
            } else {
              return "";
            }
          },
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  mounted() {
    this.refreshHousingHelps();
  },
  watch: {
    recipient_id: {
      handler: function () {
        this.refreshHousingHelps();
      },
    },
    refreshFlag() {
      if (this.refreshFlag == "housingHelp") {
        this.refreshHousingHelps();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  computed: {
    ...mapState(["refreshFlag"]),
  },
  methods: {
    refreshHousingHelps() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/housing_helps`)
        .then((res) => {
          this.housingHelps = res.data.data;
        });
    },
    askDelete(housingHelp) {
      this.$toasted
        .show("Souhaitez-vous supprimer cet élément ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(housingHelp._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(housingHelp_id) {
      axios
        .delete(`/api/web/housing_help/${housingHelp_id}`)
        .then(() => {
          this.$toasted.show("L'élément a été supprimé").goAway(1500);
          this.refreshHousingHelps();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    addHousingHelp() {
      let payload = {
        recipient_id: this.recipient_id,
        housingHelp: {},
      };
      this.$emit("openHousingHelpModal", payload);
    },

    edit(housingHelp) {
      let payload = {
        recipient_id: this.recipient_id,
        housingHelp: housingHelp,
      };
      this.$emit("openHousingHelpModal", payload);
    },
  },
};
</script>

<style>
</style>