<template>
  <div class="recipient-job pl-4 pr-4">
    <widget headerText="Pôle emploi">
      <form novalidate class="md-layout" @submit.prevent="savePoleEmploi">
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label for="pole_emploi_number">N° Pôle emploi</label>
              <md-input
                name="pole_emploi_number"
                id="pole_emploi_number"
                v-model="recipient.pole_emploi_number"
              />
            </md-field>
          </div>
          <div class="md-layout-item d-flex align-items-center">
            <md-field class="no-underline">
              <div class="d-flex align-items-center justify-content-center">
                Date d'inscription:
                <vuestic-date-picker
                  v-model="recipient.pole_emploi_date"
                  :config="dateConfig"
                />
              </div>
            </md-field>
          </div>
        </div>
      </form>
      <button type="button" class="btn btn-primary" @click="savePoleEmploi">
        Enregistrer
      </button>
    </widget>
    <widget headerText="Expériences précedentes">
      <div class="experiences mb-2">
        <b-table
          v-if="experiences && experiences.length > 0"
          striped
          hover
          :fields="fieldsExp"
          :items="experiences"
        >
          <template #cell(actions)="row">
            <ion-icon
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon
            ><ion-icon
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="modifyExperience(row.item)"
            ></ion-icon>
          </template>
        </b-table>
        <button type="button" class="btn btn-secondary" @click="addExperience">
          Ajouter une experience
        </button>
      </div>
    </widget>
    <widget headerText="Candidatures et emplois (MRS)">
      <b-table
        v-if="applications && applications.length > 0"
        striped
        hover
        :fields="fieldsApplication"
        :items="applications"
      >
        <template #cell(actions)="row">
          <ion-icon
            class="pl-2"
            name="call"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Fiche contact"
            @click="goToContact(row.item)"
          ></ion-icon>
          <ion-icon
            name="trash"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Supprimer"
            @click="askDeleteApplication(row.item)"
          ></ion-icon
          ><ion-icon
            class="pl-2"
            name="pencil"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Modifier"
            @click="modifyElement(row.item)"
          ></ion-icon>
          <ion-icon
            class="pl-2"
            name="archive"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Terminer l'emploi"
            @click="archiveElement(row.item)"
          ></ion-icon>
        </template>
      </b-table>
      <button type="button" class="btn btn-primary" @click="addApplication">
        Ajouter un emploi
      </button>
    </widget>
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { mapState } from "vuex";
export default {
  name: "recipient-job",
  components: { Widget, VuesticDatePicker },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      recipient: {},
      applications: [],
      experiences: [],
      fieldsExp: [
        {
          key: "name",
          label: "Emploi",
          sortable: true,
        },
        {
          key: "domain",
          label: "Domaine",
        },
        {
          key: "company",
          label: "Entreprise",
          sortable: true,
        },
        {
          key: "postal",
          label: "CP",
        },
        {
          key: "city",
          label: "Ville",
        },
        {
          key: "tel",
          label: "Tel",
        },
        {
          key: "duration",
          label: "Durée",
          sortable: true,
          formatter: this.durationFormatter,
        },
        {
          key: "year",
          label: "Année",
          sortable: true,
        },
        { key: "actions", label: "Actions" },
      ],
      fieldsApplication: [
        {
          key: "application_date",
          label: "Date de candidature",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
          sortable: true,
        },
        {
          key: "name",
          label: "Poste",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
        },
        {
          key: "type",
          label: "Type de candidature",
        },
        {
          key: "domain",
          label: "Domaine",
        },
        {
          key: "contract_type",
          label: "Contrat",
        },
        {
          key: "start_date",
          label: "Début",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
          sortable: true,
        },
        {
          key: "end_date",
          label: "Fin prévisionnelle",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
          sortable: true,
        },
        {
          key: "effective_end_date",
          label: "Fin effective",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
          sortable: true,
        },
        {
          key: "duration",
          label: "Durée",
          sortable: true,
        },
        {
          key: "in_detention",
          label: "En détention",
          formatter: (val) => (val ? "Oui" : "Non"),
        },
        {
          key: "terminated",
          label: "Terminé",
          formatter: (val) => (val ? "Oui" : "Non"),
        },
        {
          key: "company",
          label: "Entreprise",
          sortable: true,
        },
        {
          key: "contact.organism.name",
          label: "Organisme",
          formatter: (val) => (val ? val : ""),
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  mounted() {
    this.refreshRecipient();
    this.refreshApplications();
    this.refreshExperiences();
  },
  computed: {
    ...mapState(["refreshFlag"]),
  },
  watch: {
    recipient_id() {
      this.refreshRecipient();
      this.refreshApplications();
      this.refreshExperiences();
    },
    refreshFlag() {
      if (this.refreshFlag == "application") {
        this.refreshApplications();
        this.$store.dispatch("setRefreshFlag", false);
      }
      if (this.refreshFlag == "experience") {
        this.refreshExperiences();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshRecipient() {
      axios.get(`/api/web/recipient/${this.recipient_id}`).then((res) => {
        this.recipient = { ...{ experiences: [], ...res.data.data } };
      });
    },
    refreshExperiences() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/experiences`)
        .then((res) => {
          this.experiences = res.data.data;
        });
    },
    durationFormatter(val) {
      let result = "";
      if (val) {
        if (val.years) {
          result += val.years + (parseInt(val.years) > 1 ? " ans" : " an");
          if (val.months) {
            result += " et " + val.months + " mois";
          }
        } else {
          if (val.months) {
            result += val.months + " mois";
          }
        }
      }
      return result;
    },
    getDuration(application) {
      if (application.effective_end_date) {
        return (
          this.$moment(application.effective_end_date)
            .diff(this.$moment(application.start_date), "weeks")
            .toString() + " semaines"
        );
      } else if (application.end_date) {
        return (
          this.$moment(application.end_date)
            .diff(this.$moment(application.start_date), "weeks")
            .toString() + " semaines"
        );
      } else {
        return "Indeterminée";
      }
    },
    refreshApplications() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/applications`)
        .then((res) => {
          this.applications = res.data.data.map((a) => {
            a.duration = this.getDuration(a);
            return a;
          });
        });
    },
    addExperience() {
      let payload = {
        recipient_id: this.recipient_id,
        experience: {},
      };
      this.$emit("openExperienceModal", payload);
    },
    modifyExperience(experience) {
      let payload = {
        recipient_id: this.recipient_id,
        experience: experience,
      };
      this.$emit("openExperienceModal", payload);
    },
    askDelete(experience) {
      this.$toasted
        .show("Souhaitez-vous supprimer cette experience ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.deleteExperience(experience._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    deleteExperience(experience_id) {
      axios
        .delete(`/api/web/experience/${experience_id}`)
        .then(() => {
          this.$toasted.show("L'experience a été supprimée").goAway(1500);
          this.refreshExperiences();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    askDeleteApplication(application) {
      this.$toasted
        .show("Souhaitez-vous supprimer cette candidature ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(application._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(application_id) {
      axios
        .delete(`/api/web/application/${application_id}`)
        .then(() => {
          this.$toasted.show("La candidature a été supprimée").goAway(1500);
          this.refreshApplications();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    goToContact(application) {
      if (application.contact.contact) {
        this.$router.push(`/contact/${application.contact.contact._id}`);
      } else {
        this.$toasted.show("Aucun contact associé").goAway(3000);
      }
    },
    saveExperiences() {
      let body = {
        experiences: this.recipient.experiences,
      };
      axios
        .post(`/api/web/recipient/${this.recipient_id}/experiences`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Modification enregistrées").goAway(1500);
            this.refreshRecipient();
          } else {
            this.$toasted.error("Modifications non enregistrées").goAway(1500);
          }
        });
    },
    savePoleEmploi() {
      let body = {
        pole_emploi_number: this.recipient.pole_emploi_number,
        pole_emploi_date: this.recipient.pole_emploi_date,
      };
      axios
        .post(`/api/web/recipient/${this.recipient_id}/pole_emploi`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Modification enregistrées").goAway(1500);
            this.refreshRecipient();
          } else {
            this.$toasted.error("Modifications non enregistrées").goAway(1500);
          }
        });
    },
    addApplication() {
      let payload = {
        recipient_id: this.recipient_id,
        application: {},
      };
      this.$emit("openJobModal", payload);
    },
    modifyElement(application) {
      let payload = {
        recipient_id: this.recipient_id,
        application: application,
      };
      this.$emit("openJobModal", payload);
    },
    archiveElement(application) {
      axios
        .post(`/api/web/application/${application._id}/archive`)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Emploi archivé").goAway(1500);
            this.refreshRecipient();
            this.refreshApplications();
          } else {
            this.$toasted.error("Modifications non enregistrées").goAway(1500);
          }
        });
    },
  },
};
</script>

<style>
</style>