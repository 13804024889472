<template>
  <div class="search-box d-flex align-items-center">
    <ion-icon name="search" v-on:click="search" />
    <form href="#" class="searchbox sbx-custom pl-2">
      <div role="search" class="sbx-custom__wrapper">
        <input
          v-model="searchInput"
          placeholder="Rechercher"
          autocomplete="off"
          class="sbx-custom__input"
          type="search"
          @input="search"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { debounce } from "vue-debounce";
export default {
  name: "search-box",
  data() {
    return {
      searchInput: "",
    };
  },
  methods: {
    search: debounce(function () {
      this.$emit("search", { input: this.searchInput });
    }, 500),
  },
};
</script>

<style lang="scss">
.search-box {
  ion-icon {
    font-size: 1.6rem;
  }
  .sbx-custom {
    display: inline-block;
    position: relative;
    width: 30vw;
    height: 35px;
    white-space: nowrap;
    box-sizing: border-box;
    font-size: 14px;

    .sbx-custom__wrapper {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    .sbx-custom__input {
      display: inline-block;
      border: 0;
      border-radius: 1px;
      box-shadow: inset 0 0 0 1px #303030;
      background: aliceblue;
      padding-right: 25px;
      padding-left: 9px;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
}
</style>