<template>
  <div class="data-quality-dashboard pl-2 pt-4 pr-4">
    <widget headerText="Qualité des données">
      <b-table
        striped
        hover
        :items="alerts"
        :fields="fields"
        :tbody-tr-class="rowClass"
        ><template #cell(url)="data">
          <a
            class="action-cell"
            v-if="data.value.includes('@') || data.value == null"
            :href="`mailto:${data.value}`"
            ><ion-icon
              name="mail"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Envoyer un mail"
          /></a>
          <a
            class="action-cell"
            v-else
            v-on:click="() => handleActionClick(data.value)"
            ><ion-icon
              name="search"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Aller à la page"
          /></a>
          <a
            class="action-cell"
            v-on:click="() => handleNoShowClick(data.value)"
            ><ion-icon
              name="notifications-off-circle-outline"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Ne plus voir cette alerte"
          /></a>
          <a
            class="action-cell"
            v-on:click="() => handlePostponeClick(data.value, 1)"
            ><ion-icon
              name="hourglass-outline"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Reporter d'un jour"
          /></a>
          <a
            class="action-cell"
            v-on:click="() => handlePostponeClick(data.value, 7)"
            ><ion-icon
              name="hourglass"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Reporter d'une semaine"
          /></a>
        </template>
        <template #cell(status)="row">
          <a
            class="action-cell"
            v-if="row.item.status == 2"
            v-on:click="() => handleStatusClick(row.item)"
            ><ion-icon
              name="mail-unread"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Non lue"
          /></a>
          <a
            class="action-cell"
            v-else-if="row.item.status == 1"
            v-on:click="() => handleStatusClick(row.item)"
            ><ion-icon
              name="mail"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Lue"
          /></a>
          <a
            class="action-cell"
            v-else
            v-on:click="() => handleStatusClick(row.item)"
            ><ion-icon
              name="mail-open"
              style="font-size: 1.6rem"
              v-b-tooltip.hover
              title="Archivée"
          /></a> </template
      ></b-table>
    </widget>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { dataAlerts } from "./examples/DataAlerts.js";

import Widget from "../widgets/Widget.vue";
export default {
  name: "data-quality-dashboard",
  components: { Widget },
  data() {
    return {
      alerts: dataAlerts,
      fields: [
        {
          key: "type",
          label: "Type d'alerte",
          formatter: this.typeFormatter,
        },
        {
          key: "recipient",
          label: "Accueilli",
          formatter: (recipient) =>
            recipient.surname + " " + recipient.name.toUpperCase(),
        },
        {
          key: "url",
          label: "Action",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    this.refreshAlerts();
  },
  methods: {
    refreshAlerts() {
      axios
        .get("/api/web/alerts", {
          params: {
            page: 0,
            per_page: 10,
          },
        })
        .then((res) => {
          console.log(res);
          // this.alerts = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleActionClick(url) {
      this.$router.push(url);
    },
    handleStatusClick(alert) {
      axios
        .post(`/api/web/alert/${alert._id}`, {
          status: alert.status > 0 ? 0 : 1,
        })
        .then(() => {
          this.refreshAlerts();
          this.$store.dispatch("setRefreshFlag", "alerts");
        });
    },
    handleNoShowClick(alert) {
      this.$toasted
        .show("Souhaitez-vous désactiver cette alerte ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.noShow(alert);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    noShow(alert) {
      axios
        .post(`/api/web/alert_rule`, {
          alert_type: alert.type,
          recipient_id: alert.recipient_id,
          alert_rule_type: "no-show",
        })
        .then(() => {
          this.refreshAlerts();
          this.$store.dispatch("setRefreshFlag", "alerts");
        });
    },
    handlePostponeClick(alert, days) {
      axios
        .post(`/api/web/alert_rule`, {
          alert_type: alert.type,
          recipient_id: alert.recipient_id,
          alert_rule_type: "postpone",
          postpone_value: days,
        })
        .then(() => {
          this.refreshAlerts();
          this.$store.dispatch("setRefreshFlag", "alerts");
        });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.level > 1) return "table-danger";
      if (item.level > 0) return "table-warning";
    },
    typeFormatter(type) {
      return {
        "incomplete-detention": "Élément parcours pénal incomplet",
        "incomplete-formation": "Données de formation incomplètes",
        "no-meetings": "Aucun entretien",
        "unfinished-job": "Emploi (>1an) non terminé",
      }[type];
    },
  },
};
</script>