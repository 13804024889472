<template>
  <vuestic-modal
    class="job-modal"
    ref="jobModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ title }}</span>
    </div>
    <form novalidate @submit.prevent="addApplication">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('name')">
            <label for="application-name">Poste</label>
            <md-input
              name="application-name"
              id="application-name"
              v-model="currentApplication.name"
            />
            <span class="md-error" v-if="!$v.currentApplication.name.required"
              >Le nom de l'emploi est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('domain')">
            <label for="application-domain">Domaine</label>
            <md-select
              name="application-domain"
              id="application-domain"
              v-model="currentApplication.domain"
            >
              <md-option
                v-for="(r, i) in applicationDomains"
                :key="i"
                :value="r._id"
              >
                {{ r.label }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentApplication.domain.required"
              >Le Domaine est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('type')">
            <label for="application-type">Type de candidature</label>
            <md-select
              name="application-type"
              id="application-type"
              v-model="currentApplication.type"
            >
              <md-option
                v-for="(p, i) in ['IAE', 'Classique', 'Candidature spontanée']"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentApplication.type.required"
              >Le type est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('contract_type')">
            <label for="application-contract_type">Type de contrat</label>
            <md-select
              name="application-contract_type"
              id="application-contract_type"
              v-model="currentApplication.contract_type"
            >
              <md-option
                v-for="(p, i) in [
                  'À définir',
                  'CDD',
                  'CDDI (insertion)',
                  'CDI',
                  'CDIC (chantier)',
                  'CDII (interim)',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="!$v.currentApplication.contract_type.required"
              >Le type est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('status')">
            <label for="application-status">Statut</label>
            <md-select
              name="application-status"
              id="application-status"
              v-model="currentApplication.status"
            >
              <md-option
                v-for="(p, i) in [
                  'En cours de candidature',
                  'Embauché',
                  'Refusé',
                  'Rupture',
                  'Terminé',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currentApplication.status.required"
              >Le status est requis</span
            >
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label for="job_company">Entreprise</label>
            <md-input
              v-model="currentApplication.company"
              name="job_company"
              id="job_company"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="job_postal">CP</label>
            <md-input
              v-model="currentApplication.postal"
              name="job_postal"
              id="job_postal"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="job_city">Ville</label>
            <md-input
              v-model="currentApplication.city"
              name="job_city"
              id="job_city"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="job_tel">Téléphone</label>
            <md-input
              v-model="currentApplication.tel"
              name="job_tel"
              id="job_tel"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field>
            <label for="job-salary">Salaire net (€)</label>
            <md-input
              name="job-salary"
              id="job-salary"
              v-model="currentApplication.salary"
              type="number"
            />
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de candidature :
              <vuestic-date-picker
                v-model="currentApplication.application_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('start_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Date de début :
              <vuestic-date-picker
                v-model="currentApplication.start_date"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="
                $v.currentApplication.start_date &&
                !$v.currentApplication.start_date.required
              "
              >La date de début est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de fin prévue :
              <vuestic-date-picker
                v-model="currentApplication.end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de fin effective :
              <vuestic-date-picker
                v-model="currentApplication.effective_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <md-checkbox
        v-model="currentApplication.terminated"
        :value="true"
        class="md-primary mt-2"
        >Emploi terminé</md-checkbox
      >
      <md-checkbox
        v-model="currentApplication.in_detention"
        :value="true"
        class="md-primary mt-2"
        >En détention</md-checkbox
      >
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="job-comment"
            v-model="currentApplication.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
    <h4 class="p-4 d-flex">Lier un contact</h4>
    <contact-linker
      ref="contactLinker"
      :recipient_id="recipient_id"
      form_ref="applicationLinker"
      :contact_ids="currentApplication.contact_ids"
      @change="changeContact"
    />
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ContactLinker from "../contacts/ContactLinker.vue";
export default {
  name: "application-modal",
  components: { VuesticModal, VuesticDatePicker, ContactLinker },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      applicationDomains: [],
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      originApplication: {},
      currentApplication: {
        contact_ids: [],
      },
      application: {},
    };
  },
  validations() {
    const isDate = (val) => this.$moment(val).isValid();
    let val = {
      currentApplication: {
        name: {
          required,
        },
        status: {
          required,
        },
        type: {
          required,
        },
        domain: { required },
        contract_type: {
          required,
        },
      },
    };
    if (this.isHired(this.currentApplication)) {
      val.currentApplication.start_date = { required, isDate };
    }
    return val;
  },
  computed: {
    title() {
      return this.application._id ? "Modifier l'emploi" : "Ajouter un emploi";
    },
  },
  methods: {
    isHired(application) {
      return (
        ["Embauché", "Rupture", "Terminé"].indexOf(application.status) >= 0
      );
    },
    refreshLists() {
      axios.get(`/api/web/choices/qualification_domain`).then((res) => {
        this.applicationDomains = res.data.data;
      });
    },
    refreshApplication() {
      if (this.application._id) {
        axios
          .get(`/api/web/application/${this.application._id}`)
          .then((res) => {
            this.originApplication = this._.cloneDeep(res.data.data);
            this.currentApplication = this._.cloneDeep(this.originApplication);
          });
      } else {
        this.originApplication = {};
        this.currentApplication = {
          contact_ids: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentApplication[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentApplication[fieldName];
      }
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(application) {
      this.$refs.jobModal.open();
      this.application = application;
      this.refreshLists();
      this.refreshApplication();
      this.$refs.contactLinker.open();
    },
    resetForm() {
      this.currentApplication = {
        contact_ids: [],
      };
      this.originApplication = {};
    },
    formatApplication(application) {
      application.salary = application.salary
        ? parseFloat(application.salary)
        : application.salary;
      return application;
    },
    createApplication() {
      let body = {
        application: this.formatApplication(this.currentApplication),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/applications`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "application");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    saveApplication() {
      let body = {
        current: this.formatApplication(this.currentApplication),
        origin: this.originApplication,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/application/${this.application._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "application");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.application._id) {
          this.saveApplication();
        } else {
          this.createApplication();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Parcours non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
    changeContact(payload) {
      this.currentApplication.contact_ids = payload;
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.application-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>