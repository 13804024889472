<template>
  <div class="recipient-civil">
    <div class="pr-4 pl-4 pt-2">
      <form novalidate class="md-layout" @submit.prevent="validateRecipient">
        <widget headerText="État civil">
          <md-subheader>Référent</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <object-auto-complete
                v-model="currentRecipient.user_ref"
                :options="users"
                field_of_value="_id"
                label="Accueillant référent"
              >
              </object-auto-complete>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('antenna_id')">
                <label for="antenna-choice">Antenne d'accueil</label>
                <md-select
                  v-model="currentRecipient.antenna_id"
                  name="antenna-choice"
                  id="antenna-choice"
                >
                  <md-option
                    v-for="(a, i) in antennas"
                    :key="i"
                    :value="a._id"
                    :disabled="getAntennaClass(a) ? false : true"
                    >{{ a.name }}</md-option
                  >
                </md-select>

                <span
                  class="md-error"
                  v-if="!$v.currentRecipient.antenna_id.required"
                  >L'antenne d'accueil est requise</span
                >
              </md-field>
            </div>
            <div class="md-layout-item d-flex align-items-center">
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Premier entretien :
                  <vuestic-date-picker
                    v-model="currentRecipient.first_meeting"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
          </div>
          <md-divider />
          <md-subheader>État civil</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="civility">Civilité</label>
                <md-select
                  v-model="currentRecipient.civility"
                  name="civility"
                  id="civility"
                >
                  <md-option value="M.">M.</md-option
                  ><md-option value="Mme">Mme</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('surname')">
                <label for="first-name">Prénom</label>
                <md-input
                  name="first-name"
                  id="first-name"
                  v-model="currentRecipient.surname"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentRecipient.surname.required"
                  >Le prénom est requis</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.currentRecipient.surname.minlength"
                  >Prénom invalide</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="first-name2">Prénom 2</label>
                <md-input
                  name="first-name2"
                  id="first-name2"
                  v-model="currentRecipient.surname2"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('name')">
                <label for="last-name">Nom</label>
                <md-input
                  name="last-name"
                  id="last-name"
                  v-model="currentRecipient.name"
                />
                <span class="md-error" v-if="!$v.currentRecipient.name.required"
                  >Le nom est requis</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.currentRecipient.name.minlength"
                  >Nom invalide</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item d-flex align-items-center">
              <md-field
                :class="getValidationClass('birth_date')"
                class="no-underline"
              >
                <div class="d-flex align-items-center justify-content-center">
                  Date de naissance :
                  <vuestic-date-picker
                    v-model="currentRecipient.birth_date"
                    :config="dateConfig"
                  />
                  {{
                    $moment
                      .duration(
                        $moment() - $moment(currentRecipient.birth_date)
                      )
                      .years() + " ans"
                  }}
                </div>
                <span
                  class="md-error"
                  v-if="!$v.currentRecipient.birth_date.required"
                  >La date de naissance est requise</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('birth_place.city')">
                <label for="birth-city">Ville de naissance</label>
                <md-input
                  name="birth-city"
                  id="birth-city"
                  v-model="currentRecipient.birth_place.city"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentRecipient.birth_place.city.required"
                  >La ville de naissance est requise</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('birth_place.country')"
                v-model="currentRecipient.birth_place.country"
                :md-options="countries"
                :md-fuzzy-search="false"
              >
                <label>Pays de naissance</label>
                <template
                  slot="md-autocomplete-item"
                  slot-scope="{ item, term }"
                >
                  <md-highlight-text :md-term="term">{{
                    item
                  }}</md-highlight-text></template
                >
                <span
                  class="md-error"
                  v-if="
                    $v.$error &&
                    !$v.currentRecipient.birth_place.country.required
                  "
                  >Le pays de naissance est requis</span
                >
              </md-autocomplete>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('nationality')"
                v-model="currentRecipient.nationality"
                :md-options="nationalities"
                :md-fuzzy-search="false"
              >
                <label>Nationalité</label>
                <template
                  slot="md-autocomplete-item"
                  slot-scope="{ item, term }"
                >
                  <md-highlight-text :md-term="term">{{
                    item
                  }}</md-highlight-text></template
                >
                <span
                  class="md-error"
                  v-if="$v.$error && !$v.currentRecipient.nationality.required"
                  >La nationalitée est requis</span
                >
              </md-autocomplete>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('nationality_status')">
                <label for="nationality_status">Statut de nationalité</label>
                <md-select
                  v-model="currentRecipient.nationality_status"
                  id="nationality_status"
                  name="nationality_status"
                >
                  <md-option
                    v-for="(n, i) in [
                      'Nationalité Fr',
                      'Demandeur d\'asile',
                      'En attente d\'autorisation',
                      'Européen',
                      'Résident',
                      'Titre provisoire',
                      'Réfugié politique',
                      'Sans titre de séjour',
                      'Titre de séjour',
                    ]"
                    :key="i"
                    :value="n"
                    >{{ n }}</md-option
                  ></md-select
                >
                <span
                  class="md-error"
                  v-if="!$v.currentRecipient.nationality_status.required"
                  >Le statut est requis</span
                >
              </md-field>
            </div>
            <div
              v-if="statusHasValidity"
              class="md-layout-item d-flex align-items-center"
            >
              <div
                class="d-flex align-items-center justify-content-center period"
              >
                Fin de validité :
                <vuestic-date-picker
                  v-model="currentRecipient.validity_end_date"
                  :config="dateConfig"
                />
              </div>
            </div>
            <div class="md-layout-item d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-center period"
              >
                En France depuis le :
                <vuestic-date-picker
                  v-model="currentRecipient.france_entry"
                  :config="dateConfig"
                />
              </div>
            </div>
          </div>
          <md-divider />
          <md-subheader>Famille</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="family_status">Situation familiale</label>
                <md-select
                  v-model="currentRecipient.family_status"
                  name="family_status"
                  id="family_status"
                >
                  <md-option
                    v-for="(f, i) in family_status"
                    :key="i"
                    :value="f"
                    >{{ f }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="children">Enfants</label>
                <md-input
                  name="children"
                  id="children"
                  type="number"
                  v-model="currentRecipient.children"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="children_charge">Dont à charge</label>
                <md-input
                  name="children_charge"
                  id="children_charge"
                  type="number"
                  v-model="currentRecipient.children_charge"
                />
              </md-field>
            </div>
          </div>
          <md-divider />
          <md-subheader>Contact accueilli</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="tel">Téléphone</label>
                <md-input
                  name="tel"
                  id="tel"
                  autocomplete="tel"
                  v-model="currentRecipient.tel"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="role">Email</label>
                <md-input
                  name="email"
                  id="email"
                  autocomplete="email"
                  v-model="currentRecipient.email"
                />
                <a
                  v-if="currentRecipient.email"
                  :href="`mailto:${currentRecipient.email}`"
                  ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
                ></a>
              </md-field>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button
              class="btn"
              :class="modifMade ? 'btn-primary' : 'btn-pale'"
              type="submit"
            >
              Enregistrer
            </button>
            <div class="m-2" />
            <button
              v-if="user.habilitation != 'volunteer'"
              class="btn btn-secondary"
              type="button"
              @click="askDelete"
            >
              Supprimer
            </button>
          </div>
        </widget>
      </form>
    </div>
    <comments
      :recipient_id="recipient_id"
      field="civil"
      @openCommentModal="openCommentModal"
    />
    <div class="actions pr-4 pl-4">
      <form novalidate class="md-layout" @submit.prevent="validateActions">
        <widget headerText="Radiation et transfert">
          <div class="d-flex align-items-center">
            <md-switch v-model="radiated" class="md-primary m-4"
              >Radié</md-switch
            >
            <div v-if="radiated" class="pl-4">
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Radié le :
                  <vuestic-date-picker
                    v-model="radiated_on"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
            <div class="pl-4">
              <object-auto-complete
                v-model="currentRecipient.radiation_reason"
                :options="radiation_reasons"
                field_of_value="label"
                :formatter="(item) => item.label"
                label="Motif de radiation"
              />
            </div>
            <div class="pl-4">
              <object-auto-complete
                v-model="currentRecipient.radiation_status"
                :options="radiation_status"
                field_of_value="label"
                :formatter="(item) => item.label"
                label="Status post-radiation"
              />
            </div>
          </div>
          <b-table
            striped
            hover
            v-if="isTransfered"
            :items="transfers"
            :fields="fields"
          >
          </b-table>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="antenna-transfert">Tranférer au</label>
              <md-select
                v-model="transfert_to"
                name="antenna-transfert"
                id="antenna-transfert"
              >
                <md-option v-for="(a, i) in antennas" :key="i" :value="a._id">{{
                  a.name
                }}</md-option>
              </md-select>
            </md-field>
          </div>
          <button
            class="btn btn-primary"
            type="button"
            @click="validateActions"
          >
            Enregistrer
          </button>
        </widget>
      </form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Widget from "../../components/widgets/Widget.vue";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import Comments from "../../components/comment/Comments.vue";
import axios from "axios";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import { French } from "flatpickr/dist/l10n/fr.js";
import { newRecipientTemplate } from "../../views/recipient/recipientTemplate";
import { mapState } from "vuex";
import { habilitationList } from "../../services/utils";
export default {
  components: { Widget, VuesticDatePicker, Comments, ObjectAutoComplete },
  name: "recipient-civil",
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      users: [],
      antennas: [],
      radiation_reasons: [],
      radiation_status: [],
      nationalities: ["Français", "Espagnol"],
      countries: ["France", "Espagne"],
      nationality_status: ["Nationalité Fr", "Réfugié"],
      family_status: ["Célibataire", "Marié"],
      currentRecipient: this._.cloneDeep(newRecipientTemplate),
      originRecipient: this._.cloneDeep(newRecipientTemplate),
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      transfert_to: null,
      radiated: false,
      radiated_on: this.$moment().format("YYYY-MM-DD"),
    };
  },
  watch: {
    originRecipient() {
      this.currentRecipient = this._.cloneDeep(this.originRecipient);
      this.transfert_to = null;
    },
  },
  validations() {
    let val = {
      currentRecipient: {
        antenna_id: {
          required,
        },
        surname: {
          required,
          minLength: minLength(1),
        },
        name: {
          required,
          minLength: minLength(1),
        },
        nationality: {
          required,
        },
        nationality_status: {
          required,
        },
        birth_date: {
          required,
        },
        birth_place: {
          city: {
            required,
          },
          country: { required },
        },
        family_status: {
          required,
        },
      },
    };
    return val;
  },
  computed: {
    ...mapState(["user"]),
    modifMade() {
      return !this._.isEqual(this.currentRecipient, this.originRecipient);
    },
    statusHasValidity() {
      return (
        ["Titre de séjour", "Titre provisoire", "Résident"].indexOf(
          this.currentRecipient.nationality_status
        ) >= 0
      );
    },
    fields() {
      return [
        {
          key: "transfered_from_antenna",
          label: "Tranféré(e) depuis",
        },
        {
          key: "tranfered_on",
          label: "Tranféré(e) le",
          formatter: (val) => {
            return this.$moment(val).format("DD/MM/YYYY");
          },
        },
      ];
    },
    isTransfered() {
      if (this.currentRecipient.tranfered_from) {
        console.log(this.currentRecipient.tranfered_from);
        return true;
      } else {
        console.log(this.currentRecipient.tranfered_from);
        return false;
      }
    },
    transfers() {
      if (this.currentRecipient.tranfered_from) {
        return [this.currentRecipient];
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.refreshChoices();
    this.refreshUsers();
    this.refreshAntennas();
    this.refreshRecipient();
  },
  methods: {
    refreshChoices() {
      axios.get(`/api/web/choices/country`).then((res) => {
        this.countries = res.data.data.map((item) => item.label);
      });
      axios.get(`/api/web/choices/nationality`).then((res) => {
        this.nationalities = res.data.data.map((item) => item.label);
      });
      axios.get(`/api/web/choices/nationality_status`).then((res) => {
        this.nationality_status = res.data.data.map((item) => item.label);
      });
      axios.get(`/api/web/choices/family_status`).then((res) => {
        this.family_status = res.data.data.map((item) => item.label);
      });
      axios.get(`/api/web/choices/radiation_reasons`).then((res) => {
        this.radiation_reasons = res.data.data;
      });
      axios.get(`/api/web/choices/radiation_status`).then((res) => {
        this.radiation_status = res.data.data;
      });
    },
    formatRecipient(rec) {
      if (rec.children) {
        rec.children = parseInt(rec.children);
      }
      if (rec.children_charge) {
        rec.children_charge = parseInt(rec.children_charge);
      }
      return rec;
    },
    validateRecipient() {
      if (this.modifMade) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.sending = true;
          let body = {
            origin: this.formatRecipient(this.originRecipient),
            current: this.formatRecipient(this.currentRecipient),
            fail_on_concurrent: false,
          };
          axios
            .post(`/api/web/recipient/${this.recipient_id}`, body)
            .catch((e) => {
              if (e.response.data.data.message == "SIMILAR_RECIPIENT_EXISTS") {
                this.$toasted.error(
                  "Un accueilli existe déjà (même prénom, nom et date de naissance) dans l'antenne " +
                    e.response.data.data.antenna.name
                );
              }
            })
            .then((res) => {
              if (res.data.success) {
                this.$toasted.show("Modification enregistrées").goAway(1500);
                this.$toasted
                  .info("Pensez à renouveler le Cerfa si nécessaire")
                  .goAway(1500);
                this.refreshRecipient();
              } else {
                this.$toasted
                  .error("Modifications non enregistrées")
                  .goAway(1500);
              }
            });
        } else {
          this.$toasted
            .error("Tous les champs nécessaires ne sont pas remplis")
            .goAway(2000);
        }
      }
    },
    getAntennaClass(antenna) {
      return (
        habilitationList.indexOf(this.user.habilitation) <= 3 ||
        this.user.antenna_id == antenna._id
      );
    },

    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentRecipient[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentRecipient[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshUsers() {
      axios
        .get("/api/web/users")
        .then((res) => {
          this.users = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refreshAntennas() {
      axios
        .get("/api/web/antennas")
        .then((res) => {
          this.antennas = res.data.data.filter((a) => {
            return a.acr == undefined;
          });
          this.currentRecipient.antenna_id = this.user.antenna_id;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refreshRecipient() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}`)
        .then((res) => {
          this.originRecipient = this._.extend(
            this._.cloneDeep(newRecipientTemplate),
            res.data.data
          );
          this.radiated = this._.cloneDeep(this.originRecipient.radiated);
          if (this.originRecipient.radiated_on) {
            this.radiated_on = this._.cloneDeep(
              this.originRecipient.radiated_on
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    askDelete() {
      this.$toasted
        .show("Souhaitez-vous supprimer cet accueilli", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(this.recipient_id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(recipient_id) {
      axios
        .delete(`/api/web/recipient/${recipient_id}`)
        .then(() => {
          this.$toasted.show("L'accueilli a été supprimé").goAway(1500);
          this.$router.push("/recipients");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    validateActions() {
      if (this.transfert_to) {
        if (this.transfert_to == this.currentRecipient.antenna_id) {
          this.$toasted
            .show("L'antenne de destination est égale à l'antenne courante")
            .goAway(3000);
        } else {
          this.$toasted
            .show("Souhaitez-vous transférer cet accueilli", {
              action: [
                {
                  text: "Confimer",
                  onClick: (_, toastObject) => {
                    this.transfertRecipient(
                      this.recipient_id,
                      this.transfert_to
                    );
                    toastObject.goAway(0);
                  },
                },
                {
                  text: "Annuler",
                  onClick: (_, toastObject) => {
                    toastObject.goAway(0);
                  },
                },
              ],
            })
            .goAway(10000);
        }
      }
      if (this.radiated && !this.originRecipient.radiated) {
        if (this.radiated_on) {
          this.$toasted
            .show("Souhaitez-vous radier cet accueilli", {
              action: [
                {
                  text: "Confimer",
                  onClick: (_, toastObject) => {
                    this.radiateRecipient(this.recipient_id);
                    toastObject.goAway(0);
                  },
                },
                {
                  text: "Annuler",
                  onClick: (_, toastObject) => {
                    toastObject.goAway(0);
                  },
                },
              ],
            })
            .goAway(10000);
        } else {
          this.$toasted
            .error("Veuillez indiquer la date de radiation")
            .goAway(3000);
        }
      }
      if (!this.radiated && this.originRecipient.radiated) {
        this.$toasted
          .show(
            "Souhaitez-vous renouveler cet accueilli ? \n La date de radiation antérieure sera écrasée",
            {
              action: [
                {
                  text: "Confimer",
                  onClick: (_, toastObject) => {
                    this.unRadiateRecipient(this.recipient_id);
                    toastObject.goAway(0);
                  },
                },
                {
                  text: "Annuler",
                  onClick: (_, toastObject) => {
                    toastObject.goAway(0);
                  },
                },
              ],
            }
          )
          .goAway(10000);
      }
    },
    transfertRecipient(recipient_id, antenna_id) {
      axios
        .post(`/api/web/recipient/${recipient_id}/transfert/${antenna_id}`)
        .then((res) => {
          this.$toasted
            .show(
              `L'accueilli a bien été transféré à l'antenne ${res.data.data.name}`
            )
            .goAway(3000);
          this.$router.push("/recipients");
        });
    },
    radiateRecipient(recipient_id) {
      axios
        .post(`/api/web/recipient/${recipient_id}/radiation`, {
          on: this.radiated_on,
          radiation_reason: this.currentRecipient.radiation_reason,
          radiation_status: this.currentRecipient.radiation_status,
        })
        .then(() => {
          this.$toasted
            .show(
              `L'accueilli a bien été radié \n Veuillez contacter un administrateur si réouverture des droits`
            )
            .goAway(3000);
          this.refreshRecipient();
        });
    },
    unRadiateRecipient(recipient_id) {
      axios
        .post(`/api/web/recipient/${recipient_id}/unradiation`, {
          on: this.radiated_on,
        })
        .then(() => {
          this.$toasted.show(`L'accueilli a bien été renouvelé`).goAway(3000);
          this.refreshRecipient();
        });
    },
    modifyUserRef(user_id) {
      this.currentRecipient.user_ref = user_id;
    },
  },
};
</script>

<style lang="scss">
.md-subheader {
  font-size: 1.2rem;
}
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}

.period {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>