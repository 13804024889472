<template>
  <div class="login d-flex justify-content-center">
    <div class="mt-5 login-widget align-items-center">
      <form
        @submit.prevent="handleLogin"
        class="d-flex flex-column align-items-center"
      >
        <div class="form-group">
          <label for="username" class="pr-2">Identifiant :</label>
          <input
            type="text"
            id="username"
            required="required"
            autocomplete="username"
            v-model="username"
          />
        </div>
        <div class="form-group">
          <label for="password" class="pr-2">Mot de passe :</label>
          <input
            type="password"
            id="password"
            required="required"
            autocomplete="username"
            v-model="password"
          />
        </div>
        <div
          class="
            d-flex
            flex-column flex-lg-row
            align-items-center
            justify-content-around
            down-container
          "
        >
          <button class="btn btn-primary" type="submit">Connexion</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import auth from "../../services/auth";
// import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    handleLogin() {
      let redirect = this.$route.query.redirect || "/";
      auth
        .login(this.username, this.password)
        .then((data) => {
          this.$store.dispatch("initUser", data.user);
          this.$router.push(redirect);
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Impossible de se connecter").goAway(3000);
          this.$toasted
            .show(
              "Vérifiez login et mot de passe ou contacter un administrateur"
            )
            .goAway(3000);
        });
    },
  },
};
</script>

<style lang="scss">
.login-widget {
  background-color: $vue-lightest-blue;
  padding: 2rem;
}
</style>