<template>
  <div class="antenna-management">
    <antenna />
    <siege />
  </div>
</template>

<script>
import Antenna from "../../components/antenna/Antenna";
import Siege from "../../components/antenna/Siege";
export default {
  name: "antenna-management",
  components: {
    Antenna,
    Siege,
  },
};
</script>