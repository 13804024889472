<template>
  <div class="widget">
    <div
      class="widget-header"
      :class="{ 'widget-header-centered': centered }"
      v-if="headerText"
    >
      {{ headerText }}
    </div>
    <div class="widget-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget",
  props: {
    headerText: String,
    centered: Boolean,
  },
};
</script>

<style lang="scss">
.widget {
  background: white;
  box-shadow: 0px 4px 70px -18px rgba(112, 112, 112, 1);
  margin-bottom: 1.25rem;
  padding: 0;
  border-radius: 5px;
  width: 100%;

  .widget-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    width: 100%;

    &.widget-header-centered {
      text-align: center;
    }
  }

  .widget-body {
    padding: 1.5rem;
  }
}
</style>