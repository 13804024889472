<template>
  <div class="contacts">
    <div class="contact-list p-4">
      <widget headerText="Liste des Contacts">
        <b-table
          striped
          hover
          :items="contacts"
          :fields="fields"
          @row-clicked="handleRowClick"
          ><template #cell(email)="data">
            <a :href="`mailto:${data.value}`">{{ data.value }}</a>
          </template></b-table
        >
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
import { telFormatter } from "../../services/utils";
export default {
  name: "contacts",
  components: { Widget },
  data() {
    return {
      contacts: [],
    };
  },
  props: {
    searchInput: {
      type: String,
    },
  },
  computed: {
    ...mapState(["user", "antennas"]),
    fields() {
      return [
        {
          key: "organism_name",
          label: "Organisme",
          sortable: true,
        },
        {
          key: "organism_domain",
          label: "Domaine",
          sortable: true,
        },
        {
          key: "civility",
          label: "Civilité",
        },
        {
          key: "surname",
          label: "Prénom",
          sortable: true,
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
          formatter: (val) => val.toUpperCase(),
        },
        {
          key: "fonction",
          label: "Fonction",
          sortable: true,
        },
        {
          key: "tel",
          label: "Tel",
          formatter: telFormatter,
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "archived",
          label: "Archivé",
          formatter: (val) => (val ? "Oui" : ""),
        },
      ];
    },
  },
  mounted() {
    this.refreshContactList();
  },
  watch: {
    searchInput() {
      this.refreshContactList();
    },
  },
  methods: {
    refreshContactList() {
      axios
        .get("/api/web/contacts", { params: { search: this.searchInput } })
        .then((response) => {
          var contacts = response.data.data;
          contacts.map((h) => {
            h.organism_name = h.organism ? h.organism.name : "";
            h.organism_domain = h.organism ? h.organism.domain : "";
          });
          this.contacts = contacts;
        });
    },
    handleRowClick(item) {
      this.$router.push(`/contact/${item._id}`);
    },
  },
};
</script>