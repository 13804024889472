<template>
  <div class="comments">
    <div class="comment-list pr-4 pl-4">
      <widget headerText="Commentaires">
        <b-table striped hover :items="comments" :fields="fields">
          <template #cell(actions)="row">
            <ion-icon
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon
            ><ion-icon
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="editComment(row.item)"
            ></ion-icon>
          </template>
          <template #cell(text)="data">
            <p>
              <span v-for="(a, i) in data.value" :key="i" :value="a"
                >{{ a }}<br v-if="i != data.value.length - 1"
              /></span>
            </p>
          </template>
        </b-table>
        <button class="btn btn-primary" v-on:click="addComment">
          <ion-icon name="add-circle" />
          Ajouter un commentaire
        </button>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "comments",
  components: {
    Widget,
  },
  props: {
    field: {
      required: true,
      type: String,
    },
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      comments: [],
    };
  },
  mounted() {
    this.refreshComments();
  },
  watch: {
    refreshCommentFlag(flag) {
      if (flag) {
        this.refreshComments();
        this.$store.dispatch("setRefreshCommentFlag", false);
      }
    },
  },
  computed: {
    ...mapState(["user", "antennas", "refreshCommentFlag"]),
    fields() {
      return [
        {
          key: "created_on",
          label: "Date",
          sortable: true,
          formatter: (val) => {
            return this.$moment(val).format("DD/MM/YYYY");
          },
        },
        {
          key: "user",
          label: "Par",
          sortable: true,
          formatter: (val) => {
            return val.surname + " " + val.name.toUpperCase();
          },
        },
        {
          key: "text",
          label: "Commentaire",
          sortable: true,
          formatter: (val) => {
            return val.split("\n");
          },
        },
        {
          key: "actions",
          label: "Actions",
        },
      ];
    },
  },
  methods: {
    refreshComments() {
      axios
        .get(`/api/web/comments/${this.recipient_id}/${this.field}`)
        .then((res) => {
          this.comments = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    askDelete(comment) {
      this.$toasted
        .show("Souhaitez-vous supprimer le commentaire", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(comment._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(comment_id) {
      axios
        .delete(`/api/web/comment/${comment_id}`)
        .then(() => {
          this.$toasted.show("Le commentaire a été supprimé").goAway(1500);
          this.refreshComments();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addComment() {
      this.$emit("openCommentModal", {
        recipient_id: this.recipient_id,
        field: this.field,
        commentText: "",
      });
    },
    editComment(comment) {
      this.$emit("openCommentModal", comment);
      console.log(comment);
    },
  },
};
</script>

<style>
</style>