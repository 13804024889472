<template>
  <div class="hotel">
    <div class="hotel-info pt-4 pr-4 pl-4">
      <form novalidate class="md-layout" @submit.prevent="validateHotel">
        <widget :headerText="getHotelTitle">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field :class="getValidationClass('name')">
                <label for="hotel-name">Nom</label>
                <md-input
                  name="hotel-name"
                  id="hotel-name"
                  v-model="currentHotel.name"
                  :disabled="!hasRightHotel"
                />
                <span class="md-error" v-if="!$v.currentHotel.name.required"
                  >Le nom est requis</span
                >
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-responsible">Responsable</label>
                <md-input
                  name="hotel-responsible"
                  id="hotel-responsible"
                  v-model="currentHotel.responsible"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-bailleur">Bailleur</label>
                <md-input
                  name="hotel-bailleur"
                  id="hotel-bailleur"
                  v-model="currentHotel.bailleur"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
          </div>
          <md-subheader>Contact</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('address.street')">
                <label for="hotel-street">Adresse</label>
                <md-input
                  name="hotel-street"
                  id="hotel-street"
                  v-model="currentHotel.address.street"
                  :disabled="!hasRightHotel"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentHotel.address.street.required"
                  >L'adresse est requise</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field :class="getValidationClass('address.postal_code')">
                <label for="hotel-postal_code">Code postal</label>
                <md-input
                  name="hotel-postal_code"
                  id="hotel-postal_code"
                  v-model="currentHotel.address.postal_code"
                  :disabled="!hasRightHotel"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentHotel.address.postal_code.required"
                  >L'adresse est requise</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field :class="getValidationClass('address.city')">
                <label for="hotel-city">Ville</label>
                <md-input
                  name="hotel-city"
                  id="hotel-city"
                  v-model="currentHotel.address.city"
                  :disabled="!hasRightHotel"
                />
                <span
                  class="md-error"
                  v-if="!$v.currentHotel.address.city.required"
                  >L'adresse est requise</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-transport">Transport</label>
                <md-input
                  name="hotel-transport"
                  id="hotel-transport"
                  v-model="currentHotel.transport"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-horaires">Horaires</label>
                <md-input
                  name="hotel-horaires"
                  id="hotel-horaires"
                  v-model="currentHotel.horaires"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field :class="getValidationClass('tel')">
                <label for="hotel-tel">Téléphone</label>
                <md-input
                  name="hotel-tel"
                  id="hotel-tel"
                  v-model="currentHotel.tel"
                  :disabled="!hasRightHotel"
                /><span class="md-error" v-if="!$v.currentHotel.tel.required"
                  >Le téléphone est requise</span
                >
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-mobile">Mobile</label>
                <md-input
                  name="hotel-mobile"
                  id="hotel-mobile"
                  v-model="currentHotel.mobile"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-email">Email</label>
                <md-input
                  name="hotel-email"
                  id="hotel-email"
                  v-model="currentHotel.email"
                  :disabled="!hasRightHotel"
                />
                <a
                  v-if="currentHotel.email"
                  :href="`mailto:${currentHotel.email}`"
                  ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
                ></a>
              </md-field>
            </div>
          </div>
          <md-subheader>Paiement</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-paiement-delay">Délai</label>
                <md-input
                  name="hotel-paiement-delay"
                  id="hotel-paiement-delay"
                  v-model="currentHotel.paiement_delay"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-paiement-mode">Mode</label>
                <md-input
                  name="hotel-paiement-mode"
                  id="hotel-paiement-mode"
                  v-model="currentHotel.paiement_mode"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-paiement-rib">IBAN</label>
                <md-input
                  name="hotel-paiement-rib"
                  id="hotel-paiement-rib"
                  v-model="currentHotel.paiement_rib"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-paiement-rcs">n° RCS</label>
                <md-input
                  name="hotel-paiement-rcs"
                  id="hotel-paiement-rcs"
                  v-model="currentHotel.paiement_rcs"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-paiement-tva">n° TVA</label>
                <md-input
                  name="hotel-paiement-tva"
                  id="hotel-paiement-tva"
                  v-model="currentHotel.paiement_tva"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
          </div>
          <md-subheader>Autre</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="hotel-category">Catégorie</label>
                <md-input
                  name="hotel-category"
                  id="hotel-category"
                  v-model="currentHotel.category"
                  :disabled="!hasRightHotel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-checkbox
                v-model="currentHotel.covention"
                :value="true"
                class="md-primary mt-2"
                :disabled="!hasRightHotel"
                >Convention signée</md-checkbox
              >
            </div>
            <div class="md-layout-item">
              <md-checkbox
                v-model="currentHotel.archived"
                :value="true"
                class="md-primary mt-2"
                :disabled="!hasRightHotel"
                >Archivé</md-checkbox
              >
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              Remarque :
              <b-form-textarea
                class="pt-2"
                id="adress"
                v-model="currentHotel.remarque"
                rows="3"
                max-rows="3"
                :disabled="!hasRightHotel"
              />
            </div>
          </div>
          <button v-if="hasRightHotel" type="submit" class="btn btn-primary">
            Enregistrer
          </button>
        </widget>
      </form>
    </div>
    <div v-if="!isNewHotel" class="hotel-rooms pt-4 pr-4 pl-4">
      <widget headerText="Liste des chambres">
        <b-table
          v-if="currentHotel.rooms.length > 0"
          striped
          hover
          :items="currentHotel.rooms"
          :fields="fields"
          @row-clicked="modifyRoom"
        ></b-table>
        <button
          v-if="hasRightHotel"
          class="btn btn-secondary"
          type="button"
          @click="addRoom"
        >
          Ajouter une chambre
        </button>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget.vue";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  name: "Hotel",
  components: { Widget },
  mixins: [validationMixin],
  data() {
    return {
      originHotel: {
        address: {},
        rooms: [],
      },
      currentHotel: {
        address: {},
        rooms: [],
      },
      fields: [
        { key: "number", label: "Numéro" },
        {
          key: "antenna_id",
          label: "Antenne",
          formatter: (val) => this.antennas.filter((a) => a._id == val)[0].name,
        },
        {
          key: "borrow_antenna_id",
          label: "Prétée au",
          formatter: (val) =>
            val && val != "Aucune"
              ? this.antennas.filter((a) => a._id == val)[0].name
              : "",
        },
        {
          key: "price",
          label: "Prix",
          formatter: (val) => val.toString() + " €",
        },
        {
          key: "free",
          label: "Libre",
          formatter: (val) => (val ? "Oui" : "Non"),
        },
        {
          key: "free_date",
          label: "Libérée le",
          formatter: (val) => {
            if (val) {
              let valFormat = this.$moment(val).format("DD/MM/YYYY");
              return valFormat == this.$moment().format("DD/MM/YYYY")
                ? ""
                : valFormat;
            } else {
              return "";
            }
          },
        },
        {
          key: "archived",
          label: "Archivée",
          formatter: (val) => (val ? "Oui" : "Non"),
        },
      ],
    };
  },
  validations: {
    currentHotel: {
      name: {
        required,
      },
      address: {
        street: {
          required,
        },
        postal_code: {
          required,
        },
        city: {
          required,
        },
      },
      tel: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["user", "antennas", "refreshFlag"]),
    hotel_id() {
      return this.$route.params.hotel_id;
    },
    getHotelTitle() {
      return this.hotel_id == "new"
        ? "Nouvel Hôtel"
        : "Informations de l'hôtel";
    },
    isNewHotel() {
      return this.hotel_id == "new";
    },
    hasRightHotel() {
      return this.user.rights.indexOf("hotel_management") > 0;
    },
  },
  mounted() {
    this.refreshHotel();
  },
  watch: {
    hotel_id() {
      this.refreshHotel();
    },
    refreshFlag(flag) {
      if (flag == "room") {
        this.refreshHotel();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshHotel() {
      if (!this.isNewHotel) {
        axios.get(`/api/web/hotel/${this.hotel_id}`).then((res) => {
          this.originHotel = res.data.data;
          this.currentHotel = this._.cloneDeep(this.originHotel);
        });
      } else {
        this.currentHotel = {
          address: {},
          rooms: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentHotel[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentHotel[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    formatHotel(hotel) {
      if (hotel.archived == null) {
        hotel.archived = false;
      }
      if (hotel.convention == null) {
        hotel.convention = false;
      }
      return hotel;
    },
    createHotel() {
      let body = {
        hotel: this.formatHotel(this.currentHotel),
      };
      axios.put("/api/web/hotel/new", body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Hotel enregistré").goAway(1500);
          let id = res.data.data.hotel_id;
          this.$router.push(`/hotel/${id}`);
        } else {
          this.$toasted.error("Hotel non enregistré").goAway(1500);
        }
      });
    },
    saveHotel() {
      let body = {
        current: this.formatHotel(this.currentHotel),
        origin: this.formatHotel(this.originHotel),
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/hotel/${this.hotel_id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Hotel enregistré").goAway(1500);
          this.refreshHotel();
        } else {
          this.$toasted.error("Hotel non enregistré").goAway(1500);
        }
      });
    },
    validateHotel() {
      if (this.user.rights.indexOf("hotel_management") > 0) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.isNewHotel) {
            this.createHotel();
          } else {
            this.saveHotel();
          }
        } else {
          this.$toasted.show("Des champs sont manquants").goAway(2000);
        }
      } else {
        this.$toasted
          .show("Vous n'avez pas les droits nécessaires")
          .goAway(2000);
      }
    },
    addRoom() {
      let payload = {
        hotel_id: this.hotel_id,
        room: {},
      };
      this.$emit("openRoomModal", payload);
    },
    modifyRoom(room) {
      let payload = {
        hotel_id: this.hotel_id,
        room: room,
      };
      this.$emit("openRoomModal", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>