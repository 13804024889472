<template>
  <div class="users">
    <div class="user-list p-4">
      <widget headerText="Liste des utilisateurs">
        <b-table
          striped
          hover
          :items="users"
          :fields="fields"
          @row-clicked="handleRowClick"
          ><template #cell(email)="data">
            <a :href="`mailto:${data.value}`">{{ data.value }}</a>
          </template></b-table
        >
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
import { hasRights } from "../../services/utils";
import { telFormatter } from "../../services/utils";
export default {
  name: "users",
  components: { Widget },
  data() {
    return {
      users: [],
    };
  },
  props: {
    searchInput: {
      type: String,
    },
  },
  computed: {
    ...mapState(["user", "antennas"]),
    fields() {
      return [
        {
          key: "antenna",
          label: "Antenne",
          sortable: true,
        },
        {
          key: "initials",
          label: "Initiales",
          sortable: true,
        },
        {
          key: "surname",
          label: "Prénom",
          sortable: true,
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
          formatter: (val) => val.toUpperCase(),
        },
        {
          key: "role",
          label: "Fonction",
          sortable: true,
        },
        {
          key: "tel",
          label: "Téléphone",
          formatter: telFormatter,
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "activity_time",
          label: "Heure(s) par semaine",
          sortable: false,
        },
        {
          key: "activity.start_date",
          label: "Début d'activité",
          sortable: true,
          formatter: this.dateFormatter,
        },
        {
          key: "activity.end_date",
          label: "Fin d'activité",
          sortable: true,
          formatter: this.dateFormatter,
        },
        {
          key: "actif",
          label: "Actif",
          sortable: true,
        },
      ];
    },
  },
  mounted() {
    this.refreshUserList();
  },
  watch: {
    searchInput() {
      this.refreshUserList();
    },
  },
  methods: {
    dateFormatter(date) {
      if (date) {
        return this.$moment(date).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },
    refreshUserList() {
      axios
        .get("/api/web/users_full", { params: { search: this.searchInput } })
        .then((response) => {
          var users = response.data.data;
          users.map((u) => {
            if (!u.actif) {
              u.actif = "Non";
              u._cellVariants = { actif: "warning" };
            } else {
              u.actif = "Oui";
            }
            if (u.antenna_id) {
              let hisAntenna = this.antennas.filter(
                (antenna) => antenna._id == u.antenna_id
              );
              if (hisAntenna.length > 0) {
                u.antenna = hisAntenna[0].name;
              }
            }
          });
          this.users = users;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hasRights(...rights) {
      return hasRights(this.user, ...rights);
    },
    hasRightsOnUser(user) {
      return (
        this.hasRights("user_management", "user_absolute") ||
        (this.hasRights("user_management") &&
          user.antenna_id == this.user.antenna_id) ||
        this.user._id === user._id
      );
    },
    handleRowClick(item) {
      if (this.hasRightsOnUser(item)) {
        this.goToUser(item._id);
      } else {
        this.$toasted.show("Action interdite à votre profil").goAway(800);
      }
    },
    goToUser(userId) {
      this.$router.push(`/user/${userId}`);
    },
  },
};
</script>