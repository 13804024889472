export var antennaTemplate = {
    name: null,
    address: {
        street: null,
        street2: null,
        city: null,
        postal_code: null,
    },
    siret: null,
    tel: null,
    email: null,
    access: null,
    comment: null,
    opening: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
    },
    agreement: {},
}