var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"choices p-4"},[_c('widget',{attrs:{"headerText":"Tables de référence"}},[_c('md-field',[_c('label',{attrs:{"for":"antenna-choice"}},[_vm._v("Choix du type de table")]),_c('md-select',{attrs:{"name":"type-choice","id":"type-choice"},model:{value:(_vm.choiceType),callback:function ($$v) {_vm.choiceType=$$v},expression:"choiceType"}},_vm._l((_vm.types),function(t,i){return _c('md-option',{key:i,attrs:{"value":i}},[_vm._v(_vm._s(t.label))])}),1)],1),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.choices,"fields":[
        { key: 'label', label: 'Libellé', sortable: true },
        {
          key: 'last_update',
          label: 'Mis à jour',
          formatter: function (val) { return (val ? _vm.$moment(val).format('DD/MM/YYYY') : ''); },
          sortable: true,
        },
        { key: 'actions', label: 'Actions' } ]},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('ion-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Supprimer'),expression:"'Supprimer'",modifiers:{"hover":true}}],staticClass:"pr-2",staticStyle:{"cursor":"pointer"},attrs:{"name":"trash"},on:{"click":function($event){return _vm.askDelete(row.item)}}}),_c('ion-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"name":"pencil","title":"Modifier"},on:{"click":function($event){return _vm.handleRowClick(row.item)}}})]}}])}),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.addItem}},[_vm._v(" Ajouter une entrée ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }