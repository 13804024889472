<template>
  <vuestic-modal
    class="experience-modal"
    ref="experienceModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ title }}</span>
    </div>
    <form novalidate @submit.prevent="addExperience">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('name')">
            <label for="experience_name">Emploi</label>
            <md-input
              name="experience_name"
              id="experience_name"
              v-model="currentExperience.name"
            />
            <span class="md-error" v-if="!$v.currentExperience.name.required"
              >La nom de l'emploi est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="experience_company">Entreprise</label>
            <md-input
              v-model="currentExperience.company"
              name="experience_company"
              id="experience_company"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="experience_postal">CP</label>
            <md-input
              v-model="currentExperience.postal"
              name="experience_postal"
              id="experience_postal"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="experience_city">Ville</label>
            <md-input
              v-model="currentExperience.city"
              name="experience_city"
              id="experience_city"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="experience_tel">Téléphone</label>
            <md-input
              v-model="currentExperience.tel"
              name="experience_tel"
              id="experience_tel"
            ></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label for="experience_domain">Domaine</label>
            <md-select
              name="experience_domain"
              id="experience_domain"
              v-model="currentExperience.domain"
            >
              <md-option v-for="(r, i) in domains" :key="i" :value="r._id">
                {{ r.label }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="experience_years">Durée en années</label>
            <md-input
              v-model="currentExperience.duration.years"
              name="experience_years"
              id="experience_years"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="experience_months">Durée en mois</label>
            <md-input
              v-model="currentExperience.duration.months"
              name="experience_months"
              id="experience_months"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="experience_year">Année</label>
            <md-input
              v-model="currentExperience.year"
              name="experience_year"
              id="experience_year"
            ></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="experience-comment"
            v-model="currentExperience.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "experience-modal",
  components: { VuesticModal },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      domains: [],
      originExperience: {},
      currentExperience: {
        duration: {},
      },
      experience: {},
    };
  },
  validations: {
    currentExperience: {
      name: {
        required,
      },
    },
  },
  computed: {
    title() {
      return this.experience._id
        ? "Modifier l'experience"
        : "Ajouter une experience";
    },
  },
  methods: {
    refreshLists() {
      axios.get(`/api/web/choices/qualification_domain`).then((res) => {
        this.domains = res.data.data;
      });
    },
    refreshExperience() {
      if (this.experience._id) {
        axios.get(`/api/web/experience/${this.experience._id}`).then((res) => {
          this.originExperience = this._.cloneDeep(res.data.data);
          this.currentExperience = this._.cloneDeep(this.originExperience);
        });
      } else {
        this.originExperience = {};
        this.currentExperience = {
          duration: {},
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentExperience[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentExperience[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    open(experience) {
      this.$refs.experienceModal.open();
      this.experience = experience;
      this.refreshLists();
      this.refreshExperience();
    },
    resetForm() {
      this.currentExperience = {
        duration: {},
      };
      this.originExperience = {};
    },
    createExperience() {
      let body = {
        experience: this.formatExperience(this.currentExperience),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/experiences`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "experience");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    formatExperience(experience) {
      return experience;
    },
    saveExperience() {
      let body = {
        current: this.formatExperience(this.currentExperience),
        origin: this.originExperience,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/experience/${this.experience._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "experience");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.experience._id) {
          this.saveExperience();
        } else {
          this.createExperience();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Parcours non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.experience-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>