export var userTemplate = {
    name: "",
    surname: "",
    role: "",
    habilitation: "volunteer",
    email: "",
    tel: "",
    activity: {
        start_date: null,
        end_date: null
    },
    address_street: "",
    address_postal_code: "",
    address_city: "",
    actif: true,
    login: ""
}