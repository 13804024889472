export var tabList = [
  {
    title: "Identité",
    click: true,
    id: "id",
  },
  {
    title: "Domiciliation",
    click: false,
    id: "dom",
  },
  {
    title: "Situation Pénale",
    click: false,
    id: "sit-pen",
  },
  {
    title: "Contacts",
    click: false,
    id: "contact",
  },
  {
    title: "Formation/Emploi",
    click: false,
    id: "work",
  },
  {
    title: "Santé",
    click: false,
    id: "health",
  },
  {
    title: "Ressources",
    click: false,
    id: "resources",
  },
  {
    title: "Hébergement",
    click: false,
    id: "housing",
  },
  {
    title: "Entretiens",
    click: false,
    id: "meetings",
  },
  {
    title: "Porte document",
    click: false,
    id: "attachments",
  },
]