<template>
  <div class="organism">
    <div class="organism-info pt-4 pr-4 pl-4">
      <form novalidate class="md-layout" @submit.prevent="validateOrganism">
        <widget :headerText="getOrganismTitle">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field
                :disabled="!hasRightsToModify"
                :class="getValidationClass('name')"
              >
                <label for="organism-name">Nom</label>
                <md-input
                  name="organism-name"
                  id="organism-name"
                  v-model="currentOrganism.name"
                />
                <span class="md-error" v-if="!$v.currentOrganism.name.required"
                  >Le nom est requis</span
                >
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field :class="getValidationClass('domain')">
                <label for="domain">Domaine</label>
                <md-select
                  :disabled="!hasRightsToModify"
                  name="domain"
                  id="domain"
                  v-model="currentOrganism.domain_id"
                >
                  <md-option
                    v-for="(p, i) in organism_domains"
                    :key="i"
                    :value="p._id"
                  >
                    {{ p.label }}
                  </md-option>
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.currentOrganism.domain_id.required"
                  >Le domaine est requis</span
                >
              </md-field>
            </div>
            <div v-if="originOrganism._id !== undefined" class="md-layout-item">
              <md-field>
                <label for="visible">Visible par</label>
                <md-select
                  :disabled="!hasRightsToModifyVisibility"
                  name="visible"
                  id="visible"
                  v-model="currentOrganism.antenna_id"
                >
                  <md-option
                    v-for="(p, i) in antenna_list"
                    :key="i"
                    :value="p._id"
                  >
                    {{ p.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="organism-objet">Objet</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="organism-objet"
                  id="organism-objet"
                  v-model="currentOrganism.objet"
                />
              </md-field>
            </div>
          </div>
          <md-subheader>Contact</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="organism-street">Adresse</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="organism-street"
                  id="organism-street"
                  v-model="currentOrganism.address.street"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field>
                <label for="organism-postal_code">Code postal</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="organism-postal_code"
                  id="organism-postal_code"
                  v-model="currentOrganism.address.postal_code"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field>
                <label for="organism-city">Ville</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="organism-city"
                  id="organism-city"
                  v-model="currentOrganism.address.city"
                />
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="organism-tel">Téléphone</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="organism-tel"
                  id="organism-tel"
                  v-model="currentOrganism.tel"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="organism-fax">Telecopie</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="organism-fax"
                  id="organism-fax"
                  v-model="currentOrganism.fax"
                />
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="organism-email">Email</label>
                <md-input
                  :disabled="!hasRightsToModify"
                  name="organism-email"
                  id="organism-email"
                  v-model="currentOrganism.email"
                />
                <a
                  v-if="currentOrganism.email"
                  :href="`mailto:${currentOrganism.email}`"
                  ><ion-icon name="mail" style="font-size: 1.6rem"></ion-icon
                ></a>
              </md-field>
            </div>
          </div>
          <md-subheader>Autre</md-subheader>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-checkbox
                :disabled="!hasRightsToModify"
                v-model="currentOrganism.archived"
                :value="true"
                class="md-primary mt-2"
                >Archivé</md-checkbox
              >
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              Remarque :
              <b-form-textarea
                :disabled="!hasRightsToModify"
                class="pt-2"
                id="adress"
                v-model="currentOrganism.remarque"
                rows="3"
                max-rows="3"
              />
            </div>
          </div>
          <button
            v-if="hasRightsToModify"
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </widget>
      </form>
    </div>
    <div v-if="!isNewOrganism" class="organism-contacts pt-4 pr-4 pl-4">
      <widget headerText="Liste des contacts">
        <b-table
          v-if="currentOrganism.contacts.length > 0"
          striped
          hover
          :items="currentOrganism.contacts"
          :fields="fields"
          @row-clicked="modifyContact"
        ></b-table>
        <button
          v-if="hasRightsToModify"
          class="btn btn-secondary"
          type="button"
          @click="addContact"
        >
          Ajouter un contact
        </button>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget.vue";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { telFormatter } from "../../services/utils";
export default {
  name: "organism",
  components: { Widget },
  mixins: [validationMixin],
  data() {
    return {
      antenna_list: [],
      organism_domains: [],
      originOrganism: {
        address: {},
        contacts: [],
      },
      currentOrganism: {
        address: {},
        contacts: [],
      },
      fields: [
        { key: "civility", label: "Civilité" },
        {
          key: "surname",
          label: "Prénom",
          sortable: true,
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
          formatter: (val) => val.toUpperCase(),
        },
        {
          key: "fonction",
          label: "Fonction",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
        },
        {
          key: "tel",
          label: "Téléphone",
          formatter: telFormatter,
        },
        {
          key: "mobile",
          label: "Mobile",
        },
        {
          key: "archived",
          label: "Archivé",
          formatter: (val) => (val ? "Oui" : ""),
          sortable: true,
        },
      ],
    };
  },
  validations: {
    currentOrganism: {
      name: {
        required,
      },
      domain_id: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["user", "antennas", "refreshFlag"]),
    organism_id() {
      return this.$route.params.organism_id;
    },
    getOrganismTitle() {
      return this.organism_id == "new"
        ? "Nouvel organisme"
        : "Informations de l'organisme";
    },
    isNewOrganism() {
      return this.organism_id == "new";
    },
    hasRightsToModify() {
      return (
        this.user.rights.indexOf("organism_management") > 0 ||
        this.user.antenna_id == this.originOrganism.antenna_id ||
        this.originOrganism._id == undefined
      );
    },
    hasRightsToModifyVisibility() {
      return this.user.rights.indexOf("organism_management") > 0;
    },
  },
  mounted() {
    this.refreshDomains();
    this.refreshAntennas();
    this.refreshOrganism();
  },
  watch: {
    organism_id() {
      this.refreshOrganism();
    },
    refreshFlag(flag) {
      if (flag == "contact") {
        this.refreshOrganism();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshAntennas() {
      axios
        .get("/api/web/antennas")
        .then((res) => {
          this.antenna_list = res.data.data.filter((a) => a.acr == undefined);
          this.antenna_list.push({ name: "Tous", _id: "Tous" });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refreshDomains() {
      axios.get(`/api/web/choices/organism_domain`).then((res) => {
        this.organism_domains = res.data.data;
      });
    },
    refreshOrganism() {
      if (!this.isNewOrganism) {
        axios.get(`/api/web/organism/${this.organism_id}`).then((res) => {
          this.originOrganism = res.data.data;
          this.currentOrganism = this._.cloneDeep(this.originOrganism);
          if (
            this.currentOrganism.antenna_id == null ||
            this.currentOrganism.antenna_id == undefined
          ) {
            this.currentOrganism.antenna_id = "Tous";
          }
        });
      } else {
        this.currentOrganism = {
          address: {},
          contacts: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentOrganism[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentOrganism[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    formatOrganism(organism) {
      if (organism.archived == null) {
        organism.archived = false;
      }
      if (organism.source == null) {
        organism.source = false;
      }
      if (organism.destination == null) {
        organism.destination = false;
      }
      if (organism.antenna_id == "Tous") {
        organism.antenna_id = null;
      }
      return organism;
    },
    createOrganism() {
      let body = {
        organism: this.formatOrganism(this.currentOrganism),
      };
      axios
        .put("/api/web/organism/new", body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Organisme enregistré").goAway(1500);
            let id = res.data.data.organism_id;
            this.$router.push(`/organism/${id}`);
          } else {
            this.$toasted.error("Organisme non enregistré").goAway(1500);
          }
        })
        .catch((e) => {
          if (e.response.data.data.message == "ORGANISM_ALREADY_EXISTS") {
            this.$toasted
              .error(
                "L'organisme " + this.currentOrganism.name + " existe déjà."
              )
              .goAway(2500);
          }
        });
    },
    saveOrganism() {
      let body = {
        current: this.formatOrganism(this.currentOrganism),
        origin: this.formatOrganism(this.originOrganism),
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/organism/${this.organism_id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Organisme enregistré").goAway(1500);
          this.refreshOrganism();
        } else {
          this.$toasted.error("Organisme non enregistré").goAway(1500);
        }
      });
    },
    validateOrganism() {
      if (this.hasRightsToModify) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.isNewOrganism) {
            this.createOrganism();
          } else {
            this.saveOrganism();
          }
        } else {
          this.$toasted.show("Des champs sont manquants").goAway(2000);
        }
      } else {
        this.$toasted
          .show("Vous n'avez pas les droits nécessaires")
          .goAway(2000);
      }
    },
    addContact() {
      this.$router.push({
        path: "/contact/new",
        query: { organism_id: this.organism_id },
      });
    },
    modifyContact(contact) {
      this.$router.push(`/contact/${contact._id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>