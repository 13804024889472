<template>
  <div id="layout" class="layout d-flex flex-row">
    <sidebar class="sticky" :isReduced="isReduced" @reduce="changeReduce" />
    <div class="panel-conatiner">
      <div style="min-width: 100vw">
        <navbar :isReduced="isReduced" v-if="!isMobile" @search="search" />
      </div>
      <div class="main-panel" :class="{ reduced: isMobile || isReduced }">
        <router-view
          :searchInput="searchInput"
          @openCommentModal="openCommentModal"
          @openChoiceModal="openChoiceModal"
          @openDetentionModal="openDetentionModal"
          @openFollowModal="openFollowModal"
          @openDomModal="openDomModal"
          @openRoomModal="openRoomModal"
          @openRecipientContactModal="openRecipientContactModal"
          @openMeetingModal="openMeetingModal"
          @openHousingModal="openHousingModal"
          @openHousingHelpModal="openHousingHelpModal"
          @openFormationModal="openFormationModal"
          @openJobModal="openJobModal"
          @openResourceModal="openResourceModal"
          @openQualificationModal="openQualificationModal"
          @openExperienceModal="openExperienceModal"
          @openAttachmentModal="openAttachmentModal"
          @openMailModal="openMailModal"
        />
      </div>
    </div>
    <comment-modal
      ref="commentModal"
      :recipient_id="recipient_id"
      :field="field"
      :text="commentText"
    />
    <choice-modal ref="choiceModal" :item="choiceItem" :type="choiceType" />
    <follow-modal ref="followModal" :recipient_id="recipient_id"></follow-modal>
    <detention-modal
      ref="detentionModal"
      :recipient_id="recipient_id"
      :detention_number="detention_number"
    ></detention-modal>
    <domiciliation-modal
      ref="domiciliationModal"
      :recipient_id="recipient_id"
      :antenna_id="antenna_id"
      :domiciliation="domiciliation"
    >
    </domiciliation-modal>
    <room-modal ref="roomModal" :hotel_id="hotel_id" :room="room"> </room-modal>
    <recipient-contact-modal
      ref="recipientContactModal"
      :recipient_id="recipient_id"
      :contact="contact"
    >
    </recipient-contact-modal>
    <housing-modal
      ref="housingModal"
      :recipient_id="recipient_id"
      :housingType="housingType"
    />
    <meeting-modal
      ref="meetingModal"
      :recipient_id="recipient_id"
      :meeting="meeting"
    >
    </meeting-modal>
    <housing-help-modal ref="housingHelpModal" :recipient_id="recipient_id" />
    <formation-modal ref="formationModal" :recipient_id="recipient_id" />
    <job-modal ref="jobModal" :recipient_id="recipient_id" />
    <resource-modal ref="resourceModal" :recipient_id="recipient_id" />
    <qualification-modal
      ref="qualificationModal"
      :recipient_id="recipient_id"
    />
    <experience-modal ref="experienceModal" :recipient_id="recipient_id" />
    <mail-modal ref="mailModal" :recipient_id="recipient_id" />
    <attachment-modal ref="attachmentModal" />
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import Navbar from "./Navbar.vue";
import CommentModal from "../modals/CommentModal.vue";
import ChoiceModal from "../modals/ChoiceModal.vue";
import DetentionModal from "../modals/DetentionModal.vue";
import DomiciliationModal from "../modals/DomiciliationModal.vue";
import RoomModal from "../modals/RoomModal.vue";
import RecipientContactModal from "../modals/RecipientContactModal.vue";
import MeetingModal from "../modals/MeetingModal.vue";
import HousingModal from "../modals/HousingModal.vue";
import HousingHelpModal from "../modals/HousingHelpModal.vue";
import FormationModal from "../modals/FormationModal.vue";
import QualificationModal from "../modals/QualificationModal.vue";
import ExperienceModal from "../modals/ExperienceModal.vue";
import JobModal from "../modals/JobModal.vue";
import ResourceModal from "../modals/ResourceModal.vue";
import AttachmentModal from "../modals/AttachmentModal.vue";
import MailModal from "../modals/MailModal.vue";
import FollowModal from "../modals/FollowModal.vue";
export default {
  name: "layout",
  components: {
    Sidebar,
    Navbar,
    CommentModal,
    ChoiceModal,
    DetentionModal,
    DomiciliationModal,
    RoomModal,
    RecipientContactModal,
    MeetingModal,
    HousingModal,
    HousingHelpModal,
    FormationModal,
    JobModal,
    ResourceModal,
    QualificationModal,
    ExperienceModal,
    AttachmentModal,
    MailModal,
    FollowModal,
  },
  mounted() {
    this.$store.dispatch("refreshUser");
    this.$store.dispatch("refreshAntennas");
  },
  computed: {
    isMobile() {
      return screen.width <= 600;
    },
  },
  data() {
    return {
      isReduced: false,
      active: "home",
      recipient_id: "",
      field: "",
      commentText: "",
      choiceItem: {
        label: "",
      },
      choiceType: "",
      detention_number: 0,
      detention: {},
      follow: {},
      antenna_id: "",
      domiciliation: {},
      hotel_id: "",
      room: {},
      contact: {},
      meeting: {},
      searchInput: null,
      housingType: "Hébergement MRS",
    };
  },
  methods: {
    search(payload) {
      this.searchInput = payload.input ? payload.input : null;
    },
    openCommentModal(payload) {
      this.$refs.commentModal.open(payload);
      console.log(payload);
    },
    openChoiceModal(payload) {
      this.choiceItem = payload.item;
      this.choiceType = payload.type;
      this.$refs.choiceModal.open();
    },
    openFollowModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.follow = payload.follow;
      this.$refs.followModal.open(this.follow);
    },
    openDetentionModal(payload) {
      this.detention_number = payload.detention_number;
      this.recipient_id = payload.recipient_id;
      this.detention = payload.detention;
      this.$refs.detentionModal.open(this.detention);
    },
    openDomModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.antenna_id = payload.antenna_id;
      this.domiciliation = payload.domiciliation;
      this.$refs.domiciliationModal.open(this.domiciliation);
    },
    openMailModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.$refs.mailModal.open(payload.mail);
    },
    openRoomModal(payload) {
      this.hotel_id = payload.hotel_id;
      this.room = payload.room;
      this.$refs.roomModal.open(this.room);
    },
    openRecipientContactModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.contact = payload.contact;
      this.$refs.recipientContactModal.open(this.contact);
    },
    openMeetingModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.meeting = payload.meeting;
      this.$refs.meetingModal.open(this.meeting);
    },
    openHousingModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.housingType = payload.housingType;
      this.$refs.housingModal.open({
        housing: payload.housing,
        housingType: payload,
      });
    },
    openHousingHelpModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.$refs.housingHelpModal.open(payload.housingHelp);
    },
    openFormationModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.$refs.formationModal.open(payload.formation);
    },
    openJobModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.$refs.jobModal.open(payload.application);
    },
    openResourceModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.$refs.resourceModal.open(payload.resource);
    },
    openQualificationModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.$refs.qualificationModal.open(payload.qualification);
    },
    openExperienceModal(payload) {
      this.recipient_id = payload.recipient_id;
      this.$refs.experienceModal.open(payload.experience);
    },
    openAttachmentModal(payload) {
      this.$refs.attachmentModal.open(payload);
    },
    changeReduce() {
      this.isReduced = !this.isReduced;
    },
  },
};
</script>

<style lang="scss">
.layout {
  min-width: 100%;
  .panel-conatiner {
    background-color: #f5f1f5;
    .main-panel {
      margin-left: 260px;
      &.reduced {
        margin-left: 50px !important;
      }
    }
  }
}
.vs-sidebar-content {
  //   --vs-light: rgb(163, 224, 255) !important;
  .vs-sidebar__logo img {
    max-width: 200px !important;
    max-height: 100px !important;
  }
}
</style>>
