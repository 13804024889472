<template>
  <vuestic-modal
    class="detention-modal"
    ref="detentionModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">Ajouter un élément au parcours pénal</span>
    </div>
    <form novalidate @submit.prevent="addDetention">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-big-size-100">
          <object-auto-complete
            :inputClass="getValidationClass('place')"
            v-model="currentDetention.place"
            :options="detention_places"
            :formatter="
              (d) => {
                return d.label;
              }
            "
            field_of_value="_id"
            label="Lieu de détention"
          >
            <span class="md-error" v-if="!$v.currentDetention.place.required"
              >Le lieu de détention est requis</span
            >
          </object-auto-complete>
        </div>
        <div class="md-layout-item md-big-size-100">
          <object-auto-complete
            :inputClass="getValidationClass('status')"
            v-model="currentDetention.status"
            :options="detention_status"
            :formatter="
              (d) => {
                return d.label;
              }
            "
            field_of_value="_id"
            label="Statut pénal"
          >
            <span class="md-error" v-if="!$v.currentDetention.status.required"
              >Le status est requis</span
            >
          </object-auto-complete>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="followed_by">Suivi par</label>
            <md-select
              name="followed_by"
              id="followed_by"
              v-model="currentDetention.organism_id"
            >
              <md-option v-for="(o, i) in organismList" :key="i" :value="o._id">
                {{ o.name }}
              </md-option>
              <md-option :value="null">Aucun</md-option>
            </md-select>
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de début :
              <vuestic-date-picker
                v-model="currentDetention.start_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de fin :
              <vuestic-date-picker
                v-model="currentDetention.end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de sortie :
              <vuestic-date-picker
                v-model="currentDetention.exit_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="detention-duration">Durée (en mois)</label>
            <md-input
              name="detention-duration"
              id="detention-duration"
              v-model="currentDetention.duration"
            />
          </md-field>
        </div>
      </div>
    </form>
    <h4 class="p-4 d-flex">Lier un contact</h4>
    <contact-linker
      ref="contactLinker"
      :recipient_id="recipient_id"
      form_ref="detentionLinker"
      :contact_ids="currentDetention.contact_ids"
      @change="changeContact"
    />
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ContactLinker from "../contacts/ContactLinker.vue";
export default {
  name: "detention-modal",
  components: {
    VuesticModal,
    VuesticDatePicker,
    ContactLinker,
    ObjectAutoComplete,
  },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
    detention_number: {
      required: false,
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      organismList: [],
      detention_places: [],
      detention_status: [],
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      originDetention: {},
      currentDetention: {
        contact_ids: [],
      },
      detention: {},
    };
  },
  validations: {
    currentDetention: {
      place: {
        required,
      },
      status: {
        required,
      },
    },
  },
  mounted() {
    this.refreshLists();
  },
  methods: {
    refreshDetention() {
      if (this.detention._id) {
        axios.get(`/api/web/detention/${this.detention._id}`).then((res) => {
          this.originDetention = this._.cloneDeep(res.data.data);
          this.currentDetention = this._.cloneDeep(this.originDetention);
        });
      } else {
        this.originDetention = {};
        this.currentDetention = {
          contact_ids: [],
        };
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentDetention[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentDetention[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshLists() {
      axios.get(`/api/web/choices/detention_place`).then((res) => {
        this.detention_places = res.data.data;
      });
      axios.get(`/api/web/choices/detention_status`).then((res) => {
        this.detention_status = res.data.data;
      });
      axios.get(`/api/web/organisms`).then((res) => {
        this.organismList = res.data.data.filter((organism) =>
          organism.name.startsWith("SPIP")
        );
      });
    },
    open(detention) {
      this.$refs.detentionModal.open();
      this.detention = detention;
      this.refreshLists();
      this.refreshDetention();
      this.$refs.contactLinker.open();
    },
    formatDetention(detention) {
      if (typeof detention.last == String) {
        detention.last = parseInt(detention.last);
      }
      detention.detention_number = this.detention_number;
      return detention;
    },
    resetForm() {
      this.currentDetention = {
        contact_ids: [],
      };
      this.originDetention = {};
    },
    createDetention() {
      let body = {
        detention: this.formatDetention(this.currentDetention),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/detentions`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "detention");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    saveDetention() {
      let body = {
        current: this.currentDetention,
        origin: this.originDetention,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/detention/${this.detention._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Parcours mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "detention");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.detention._id) {
          this.saveDetention();
        } else {
          this.createDetention();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Parcours non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
    changeContact(payload) {
      this.currentDetention.contact_ids = payload;
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.detention-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>