export var dataAlerts = [
    {
        type: "incomplete-detention",
        recipient: {
            surname: "Robert",
            name: "Mourbier"
        },
        level: 2
    },
    {
        type: "incomplete-formation",
        recipient: {
            surname: "Alfred",
            name: "Pommard"
        },
        level: 1
    },
    {
        type: "no-meetings",
        recipient: {
            surname: "Jean",
            name: "Guibard"
        },
        level: 1
    },
    {
        type: "unfinished-job",
        recipient: {
            surname: "Alfred",
            name: "Pommard"
        },
        level: 0
    },
]