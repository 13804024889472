<template>
  <div class="organisms">
    <div class="organism-list p-4">
      <widget headerText="Liste des organismes tiers">
        <b-table
          striped
          hover
          :items="organisms"
          :fields="fields"
          @row-clicked="handleRowClick"
          ><template #cell(email)="data">
            <a :href="`mailto:${data.value}`">{{ data.value }}</a>
          </template></b-table
        >
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
import { telFormatter } from "../../services/utils";
export default {
  name: "organisms",
  components: { Widget },
  data() {
    return {
      organisms: [],
    };
  },
  props: {
    searchInput: {
      type: String,
    },
  },
  computed: {
    ...mapState(["user", "antennas"]),
    fields() {
      return [
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "domain",
          label: "Domaine",
          sortable: true,
        },
        {
          key: "postal_code",
          label: "Code postal",
          sortable: true,
        },
        {
          key: "city",
          label: "Ville",
          sortable: true,
        },
        {
          key: "tel",
          label: "Tel",
          formatter: telFormatter,
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "antenna.name",
          label: "Visible par",
          tdClass: (value) => (value == "Tous" ? "table-success" : ""),
        },
        {
          key: "archived",
          label: "Archivé",
          formatter: (val) => (val ? "Oui" : ""),
          tdClass: (value) => (value ? "table-warning" : ""),
        },
      ];
    },
  },
  mounted() {
    this.refreshOrganismList();
  },
  watch: {
    searchInput() {
      this.refreshOrganismList();
    },
  },
  methods: {
    refreshOrganismList() {
      axios
        .get("/api/web/organisms/plain", {
          params: { search: this.searchInput },
        })
        .then((response) => {
          var organisms = response.data.data;
          organisms.map((h) => {
            h.city = h.address ? h.address.city : "";
            h.postal_code = h.address ? h.address.postal_code : "";
          });
          this.organisms = organisms;
        });
    },
    handleRowClick(item) {
      this.$router.push(`/organism/${item._id}`);
    },
  },
};
</script>