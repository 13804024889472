<template>
  <vuestic-modal
    class="attachment-modal"
    ref="attachmentModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ modalTitle }}</span>
    </div>
    <form novalidate>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('type')">
            <label for="attachment_type">Type</label>
            <md-select
              v-model="currentAttachment.type"
              name="attachment_type"
              id="attachment_type"
              ><md-option
                v-for="(a, i) in attachmentTypes"
                :key="i"
                :value="a._id"
              >
                {{ a.label }}
              </md-option></md-select
            >
            <span class="md-error" v-if="!$v.currentAttachment.type.required"
              >Le type de document est requis</span
            >
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire :
          <b-form-textarea
            class="pt-2"
            id="meeting-comment"
            v-model="currentAttachment.comment"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
    <vuestic-file-upload
      class="p-4"
      style="flex: 1"
      type="single"
      v-model="files"
    />
  </vuestic-modal>
</template>

<script>
import VuesticFileUpload from "../widgets/vuestic-file-upload/VuesticFileUpload";
import VuesticModal from "../widgets/VuesticModal";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  name: "attachment-modal",
  components: { VuesticModal, VuesticFileUpload },
  mixins: [validationMixin],
  data() {
    return {
      attachment: {},
      originAttachment: {},
      currentAttachment: {},
      attachmentTypes: [],
      files: [],
    };
  },
  validations: {
    currentAttachment: {
      type: {
        required,
      },
    },
  },
  computed: {
    modalTitle() {
      return this.attachment._id == undefined
        ? "Nouveau document"
        : "Modifier le document";
    },
  },
  methods: {
    refreshLists() {
      if (this.attachment.meta_type == "recipient") {
        axios
          .get(`/api/web/choices/attachment_types_for_recipient`)
          .then((res) => {
            this.attachmentTypes = res.data.data;
          });
      } else if (this.attachment.meta_type == "antenna") {
        axios
          .get(`/api/web/choices/attachment_types_for_antenna`)
          .then((res) => {
            this.attachmentTypes = res.data.data;
          });
      }
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentAttachment[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentAttachment[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshAttachment() {
      if (this.attachment._id) {
        axios.get(`/api/web/attachment/${this.attachment._id}`).then((res) => {
          this.originAttachment = this._.cloneDeep(res.data.data);
          this.currentAttachment = this._.cloneDeep(this.originAttachment);
        });
      } else {
        this.originAttachment = this._.cloneDeep(this.attachment);
        this.currentAttachment = this._.cloneDeep(this.attachment);
      }
    },
    formatBody() {
      var formData = new FormData();
      formData.append("type", this.currentAttachment.type);
      if (this.files.length > 0) {
        this.currentAttachment.name = this.files[0].name;
        this.currentAttachment.content_length = this.files[0].size;
        formData.append("attachment", this.files[0]);
        formData.append("metadata", this.currentAttachment);
      }
      if (this.currentAttachment.recipient_id != undefined) {
        formData.append("recipient_id", this.currentAttachment.recipient_id);
      }
      if (this.currentAttachment.antenna_id != undefined) {
        formData.append("antenna_id", this.currentAttachment.antenna_id);
      }
      return formData;
    },
    createAttachment() {
      axios
        .put(`/api/web/attachment/new`, this.formatBody(), {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Documents mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "attachments");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    saveAttachment() {
      axios
        .post(
          `/api/web/attachment/${this.currentAttachment._id}`,
          this.formatBody(),
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Documents mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "attachments");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
    open(payload) {
      this.$refs.attachmentModal.open();
      this.attachment = payload.attachment;
      if (payload.recipient_id != undefined) {
        this.attachment.meta_type = "recipient";
        this.attachment.recipient_id = payload.recipient_id;
      } else if (payload.antenna_id != undefined) {
        this.attachment.meta_type = "antenna";
        this.attachment.antenna_id = payload.antenna_id;
      }
      this.refreshLists();
      this.refreshAttachment();
    },
    resetForm() {
      this.fileType = "pdf";
      this.currentAttachment = {};
      this.originalAttachment = {};
      this.files = [];
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.files.length > 0) {
          if (this.currentAttachment._id) {
            this.saveAttachment();
          } else {
            this.createAttachment();
          }
          this.resetForm();
          return true;
        } else {
          this.$toasted.show("Document inexistant").goAway(1500);
          return false;
        }
      } else {
        this.$toasted
          .show("Documents non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
  },
};
</script>

<style>
</style>