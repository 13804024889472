<template>
  <div class="recipient-formation pl-4 pr-4">
    <widget headerText="Qualifications">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label for="schoolLevel">Niveau de scolarité pré MRS</label>
            <md-select
              name="schoolLevel"
              id="schoolLevel"
              v-model="recipient.school_level"
            >
              <md-option
                v-for="(p, i) in [
                  'Non précisé',
                  '< BEPC',
                  'BEPC',
                  'CAP',
                  'BAC professionnel',
                  'BAC général',
                  'BTS',
                  'BAC +',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="actual_schoolLevel">Niveau de scolarité actuel</label>
            <md-select
              name="actual_schoolLevel"
              id="actual_schoolLevel"
              v-model="recipient.actual_school_level"
            >
              <md-option
                v-for="(p, i) in [
                  'Non précisé',
                  '< BEPC',
                  'BEPC',
                  'CAP',
                  'BAC professionnel',
                  'BAC général',
                  'BTS',
                  'BAC +',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="frenchLevel">Niveau de Français</label>
            <md-select
              name="frenchLevel"
              id="frenchLevel"
              v-model="recipient.french_level"
            >
              <md-option
                v-for="(p, i) in [
                  'Non précisé',
                  'Débutant',
                  'Courant',
                  'Bilingue',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <button type="button" class="btn btn-primary" @click="saveQualifications">
        Enregistrer
      </button>
      <md-subheader>Qualifications</md-subheader>
      <div class="domains mb-2">
        <b-table
          v-if="qualifications && qualifications.length > 0"
          striped
          hover
          :fields="fieldsQualif"
          :items="qualifications"
        >
          <template #cell(actions)="row">
            <ion-icon
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon
            ><ion-icon
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="modifyQualification(row.item)"
            ></ion-icon>
          </template>
        </b-table>
        <button
          type="button"
          class="btn btn-secondary"
          @click="addQualification"
        >
          Ajouter une qualification
        </button>
      </div>
    </widget>
    <widget headerText="Formations">
      <b-table
        v-if="formations && formations.length > 0"
        striped
        hover
        :fields="fieldsFormation"
        :items="formations"
      >
        <template #cell(actions)="row">
          <ion-icon
            class="pl-2"
            name="call"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Fiche contact"
            @click="goToContact(row.item)"
          ></ion-icon>
          <ion-icon
            name="trash"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Supprimer"
            @click="askDeleteFormation(row.item)"
          ></ion-icon
          ><ion-icon
            class="pl-2"
            name="pencil"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Modifier"
            @click="modifyElement(row.item)"
          ></ion-icon>
        </template>
      </b-table>
      <button type="button" class="btn btn-primary" @click="addFormation">
        Ajouter une formation
      </button>
    </widget>
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "recipient-formation",
  components: { Widget },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      recipient: {},
      formations: [],
      qualifications: [],
      fieldsQualif: [
        {
          key: "domain",
          label: "Domaine",
          sortable: true,
        },
        {
          key: "level",
          label: "Niveau de qualification",
          sortable: true,
        },
        {
          key: "year",
          label: "Année",
          sortable: true,
        },
        { key: "actions", label: "Actions" },
      ],
      fieldsFormation: [
        {
          key: "start_date",
          label: "Début",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
          sortable: true,
        },
        {
          key: "computed_end_date",
          label: "Fin",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
          sortable: true,
        },
        {
          key: "duration",
          label: "Durée",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "domain",
          label: "Domaine",
          sortable: true,
        },
        {
          key: "name",
          label: "Formation",
          sortable: true,
        },
        {
          key: "in_detention",
          label: "En détention",
          formatter: (val) => (val ? "Oui" : "Non"),
        },
        {
          key: "terminated",
          label: "Terminée",
          formatter: (val) => (val ? "Oui" : "Non"),
        },
        {
          key: "status",
          label: "Statut",
        },
        {
          key: "contact.organism.name",
          label: "Organisme",
          formatter: (val) => (val ? val : ""),
        },
        {
          key: "contact.contact",
          label: "Contact",
          formatter: (val) =>
            val ? val.surname + " " + val.name.toUpperCase() : "",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  mounted() {
    this.refreshRecipient();
    this.refreshFormations();
    this.refreshQualifications();
  },
  computed: {
    ...mapState(["refreshFlag"]),
  },
  watch: {
    recipient_id() {
      this.refreshRecipient();
      this.refreshFormations();
      this.refreshQualifications();
    },
    refreshFlag() {
      if (this.refreshFlag == "formation") {
        this.refreshFormations();
        this.$store.dispatch("setRefreshFlag", false);
      }
      if (this.refreshFlag == "qualification") {
        this.refreshQualifications();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshRecipient() {
      axios.get(`/api/web/recipient/${this.recipient_id}`).then((res) => {
        this.recipient = res.data.data;
      });
    },
    refreshQualifications() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/qualifications`)
        .then((res) => {
          this.qualifications = res.data.data;
        });
    },
    getDuration(formation) {
      if (formation.effective_end_date) {
        return (
          this.$moment(formation.effective_end_date)
            .diff(this.$moment(formation.start_date), "weeks")
            .toString() + " semaines"
        );
      } else if (formation.end_date) {
        return (
          this.$moment(formation.end_date)
            .diff(this.$moment(formation.start_date), "weeks")
            .toString() + " semaines"
        );
      } else {
        return "Indeterminée";
      }
    },
    refreshFormations() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/formations`)
        .then((res) => {
          this.formations = res.data.data.map((f) => {
            f.duration = this.getDuration(f);
            return f;
          });
        });
    },
    addQualification() {
      let payload = {
        recipient_id: this.recipient_id,
        qualification: {},
      };
      this.$emit("openQualificationModal", payload);
    },
    modifyQualification(qualification) {
      let payload = {
        recipient_id: this.recipient_id,
        qualification: qualification,
      };
      this.$emit("openQualificationModal", payload);
    },
    askDelete(qualification) {
      this.$toasted
        .show("Souhaitez-vous supprimer cette qualification ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.deleteQualification(qualification._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    deleteQualification(qualification_id) {
      axios
        .delete(`/api/web/qualification/${qualification_id}`)
        .then(() => {
          this.$toasted.show("La qualification a été supprimée").goAway(1500);
          this.refreshQualifications();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    askDeleteFormation(formation) {
      this.$toasted
        .show("Souhaitez-vous supprimer cette formation ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(formation._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(formation_id) {
      axios
        .delete(`/api/web/formation/${formation_id}`)
        .then(() => {
          this.$toasted.show("La formation a été supprimée").goAway(1500);
          this.refreshFormations();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    goToContact(formation) {
      if (formation.contact.contact) {
        this.$router.push(`/contact/${formation.contact.contact._id}`);
      } else {
        this.$toasted.show("Aucun contact associé").goAway(3000);
      }
    },
    saveQualifications() {
      let body = {
        school_level: this.recipient.school_level,
        actual_school_level: this.recipient.actual_school_level,
        french_level: this.recipient.french_level,
      };
      axios
        .post(`/api/web/recipient/${this.recipient_id}/school_level`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Modification enregistrées").goAway(1500);
            this.refreshRecipient();
          } else {
            this.$toasted.error("Modifications non enregistrées").goAway(1500);
          }
        });
    },
    addFormation() {
      let payload = {
        recipient_id: this.recipient_id,
        formation: {},
      };
      this.$emit("openFormationModal", payload);
    },
    modifyElement(formation) {
      let payload = {
        recipient_id: this.recipient_id,
        formation: formation,
      };
      this.$emit("openFormationModal", payload);
    },
  },
};
</script>

<style>
</style>