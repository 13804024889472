<template>
  <div class="antenna-fiche pl-4 pt-4 pr-2">
    <widget v-if="antenna != null" :headerText="antennaTitle">
      <div class="d-flex">
        <div class="infos">
          <div class="info-wrapper">
            <h5 class="d-flex">Informations :</h5>
            <div class="address d-flex pb-1">
              <div class="pr-1">Adresse :</div>
              <div>
                <div>{{ antenna.address.street }}</div>
                <div>
                  {{
                    antenna.address.postal_code + "  " + antenna.address.city
                  }}
                </div>
              </div>
            </div>
            <div class="address d-flex pb-1">
              <div class="pr-1">Téléphone :</div>
              <div>{{ " " + telFormatter(antenna.tel) }}</div>
            </div>
            <div class="address d-flex pb-1">
              <div class="pr-1">Téléphone 2 :</div>
              <div>
                {{ " " + (antenna.tel2 ? telFormatter(antenna.tel2) : "") }}
              </div>
            </div>
            <div class="address d-flex pb-1">
              <div class="pr-1">Email :</div>
              <div>{{ " " + antenna.email }}</div>
            </div>
            <div class="address d-flex pb-1">
              <div class="pr-1">Responsable :</div>
              <div>
                {{
                  " " +
                  boss.surname +
                  " " +
                  (boss.name ? boss.name.toUpperCase() : "")
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="horaires" v-if="antenna.name != 'Siège'">
          <h5 class="d-flex">Horaires :</h5>
          <div v-if="getOpening('monday')" class="d-flex">
            {{ "Lundi : " + getOpening("monday") }}
          </div>
          <div v-if="getOpening('tuesday')" class="d-flex">
            {{ "Mardi : " + getOpening("tuesday") }}
          </div>
          <div v-if="getOpening('wednesday')" class="d-flex">
            {{ "Mercredi : " + getOpening("wednesday") }}
          </div>
          <div v-if="getOpening('thursday')" class="d-flex">
            {{ "Jeudi : " + getOpening("thursday") }}
          </div>
          <div v-if="getOpening('friday')" class="d-flex">
            {{ "Vendredi : " + getOpening("friday") }}
          </div>
          <div v-if="getOpening('saturday')" class="d-flex">
            {{ "Samedi : " + getOpening("saturday") }}
          </div>
          <div v-if="getOpening('sunday')" class="d-flex">
            {{ "Dimanche : " + getOpening("sunday") }}
          </div>
        </div>
      </div>
    </widget>
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import axios from "axios";
import { mapState } from "vuex";
import { telFormatter } from "../../services/utils";
export default {
  name: "antenna-fiche",
  components: { Widget },
  data() {
    return {
      antenna: {
        address: {},
        opening: {},
      },
      boss: {},
    };
  },
  computed: {
    ...mapState(["user"]),
    antennaTitle() {
      return this.antenna.name ? this.antenna.name : "Mon antenne";
    },
  },
  watch: {
    user() {
      this.refreshAntenna();
    },
  },
  mounted() {
    this.refreshAntenna();
  },
  methods: {
    telFormatter(tel) {
      return telFormatter(tel);
    },
    getOpening(day) {
      if (this.antenna.opening != undefined && this.antenna.opening[day]) {
        return this.antenna.opening[day];
      } else {
        return null;
      }
    },
    refreshAntenna() {
      axios.get(`/api/web/antenna/${this.user.antenna_id}`).then((res) => {
        this.antenna = res.data.data;
        this.refreshBoss();
      });
    },
    refreshBoss() {
      if (this.antenna) {
        axios
          .get(`/api/web/antenna/${this.antenna._id}/boss`)
          .then((res) => (this.boss = res.data.data));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.antenna-fiche {
  flex: 2;
}
.widget {
  height: 100%;
}
.infos {
  flex: 2;
}
.info-wrapper {
  max-width: 400px;
  .address {
    justify-content: space-between;
  }
}
.horaires {
  flex: 1;
}
</style>