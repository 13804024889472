<template>
  <vuestic-modal
    class="housing-modal"
    ref="housingModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
  >
    <div slot="title">
      <span class="center">{{ getTitle }}</span>
    </div>
    <form
      v-if="housingType == 'Hébergements MRS'"
      novalidate
      @submit.prevent="addHousing"
    >
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <object-auto-complete
            :inputClass="getValidationClass('user_id')"
            v-model="currentHousing.user_id"
            :options="users"
            field_of_value="_id"
            label="Fait par"
            ><span
              class="md-error"
              v-if="
                $v.currentHousing.user_id && !$v.currentHousing.user_id.required
              "
              >L'accueillant est requis</span
            >
          </object-auto-complete>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('hotel_id', 'selectedHotelId')">
            <label for="housing_hotel">Hotel</label>
            <md-select
              name="housing_hotel"
              id="housing_hotel"
              v-model="selectedHotelId"
            >
              <md-option v-for="(h, i) in hotels" :key="i" :value="h._id">
                {{ h.name }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="$v.selectedHotelId && !$v.selectedHotelId.required"
              >L'hotel est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('room_id')">
            <label for="housing_room">Chambre</label>
            <md-select
              name="housing_room"
              id="housing_room"
              v-model="currentHousing.room_id"
            >
              <md-option
                v-for="(r, i) in availableRooms"
                :key="i"
                :value="r._id"
              >
                {{ formatRoom(r) }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.room_id && !$v.currentHousing.room_id.required
              "
              >La chambre est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_type">Type</label>
            <md-select
              name="housing_type"
              id="housing_type"
              v-model="currentHousing.type"
            >
              <md-option
                v-for="(r, i) in ['Classique', 'Relais', 'PSE']"
                :key="i"
                :value="r"
              >
                {{ r }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('start_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Du soir du :
              <vuestic-date-picker
                v-model="currentHousing.start_date"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.start_date &&
                !$v.currentHousing.start_date.required
              "
              >La date de début est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('end_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Au matin du :
              <vuestic-date-picker
                v-model="currentHousing.end_date"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.end_date &&
                !$v.currentHousing.end_date.required
              "
              >La date de fin est requise</span
            >
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de sortie prévue :
              <vuestic-date-picker
                v-model="currentHousing.estimated_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de sortie effective :
              <vuestic-date-picker
                v-model="currentHousing.free_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field :class="getValidationClass('price')">
            <label for="housing-price">Quote part</label>
            <md-input
              name="housing-price"
              id="housing-price"
              type="number"
              v-model="currentHousing.price"
            />
            <span
              class="md-error"
              v-if="
                $v.currentHousing.price && !$v.currentHousing.price.required
              "
              >La quote part est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_caution">Caution</label>
            <md-select
              name="housing_caution"
              id="housing_caution"
              v-model="currentHousing.caution"
            >
              <md-option
                v-for="(c, i) in ['Non payée', 'Payée', 'Rendue']"
                :key="i"
                :value="c"
              >
                {{ c }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_leave_motive">Statut post hébergement</label>
            <md-select
              name="housing_leave_motive"
              id="housing_leave_motive"
              v-model="currentHousing.leave_motive"
            >
              <md-option
                v-for="(r, i) in housingLeaveMotives"
                :key="i"
                :value="r._id"
              >
                {{ r.label }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label for="housing_meeting">Entretien pré-admission</label>
            <md-select
              name="housing_meeting"
              id="housing_meeting"
              v-model="currentHousing.meeting"
            >
              <md-option v-for="(c, i) in ['Oui', 'Non']" :key="i" :value="c">
                {{ c }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_signin">Signature à l'entrée</label>
            <md-select
              name="housing_signin"
              id="housing_signin"
              v-model="currentHousing.signin"
            >
              <md-option v-for="(c, i) in ['Oui', 'Non']" :key="i" :value="c">
                {{ c }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_signout">Signature à la sortie</label>
            <md-select
              name="housing_signout"
              id="housing_signout"
              v-model="currentHousing.signout"
            >
              <md-option v-for="(c, i) in ['Oui', 'Non']" :key="i" :value="c">
                {{ c }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <md-subheader>Paiements</md-subheader>
      <b-table
        v-if="currentHousing.payments && currentHousing.payments.length > 0"
        striped
        hover
        :fields="fields"
        :items="currentHousing.payments"
      >
        <template #cell(actions)="row">
          <ion-icon
            name="trash"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Supprimer"
            @click="askDelete(row.index)"
          ></ion-icon>
        </template>
      </b-table>

      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-50">
          <md-field>
            <label for="payment">Paiement à ajouter (en €)</label>
            <md-input
              name="payment"
              id="payment"
              type="number"
              v-model="payment"
            />
          </md-field>
        </div>
        <button
          class="btn"
          :class="
            payment == null || payment == '' ? 'btn-pale' : 'btn-secondary'
          "
          type="button"
          @click="addPayment"
        >
          Ajouter un paiement
        </button>
      </div>
    </form>

    <!-- Formulaire hébergement d'urgence -->

    <form
      v-else-if="housingType == 'Hébergements d\'urgence'"
      novalidate
      @submit.prevent="addHousing"
    >
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('user_id')">
            <label for="housing_user">Fait par</label>
            <md-select
              name="housing_user"
              id="housing_user"
              v-model="currentHousing.user_id"
            >
              <md-option v-for="(u, i) in users" :key="i" :value="u._id">
                {{ u.surname + " " + u.name.toUpperCase() }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.user_id && !$v.currentHousing.user_id.required
              "
              >L'accueillant est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="getValidationClass('hotel_info')">
            <label for="housing_hotel_info">Informations sur l'hôtel</label>
            <md-input
              name="housing_hotel_info"
              id="housing_hotel_info"
              v-model="currentHousing.hotel_info"
            />
            <span
              class="md-error"
              v-if="
                $v.currentHousing.hotel_info &&
                !$v.currentHousing.hotel_info.required
              "
              >L'hotel est requis</span
            >
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('start_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Du soir du :
              <vuestic-date-picker
                v-model="currentHousing.start_date"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.start_date &&
                !$v.currentHousing.start_date.required
              "
              >La date de début est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('end_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Au matin du :
              <vuestic-date-picker
                v-model="currentHousing.end_date"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.end_date &&
                !$v.currentHousing.end_date.required
              "
              >La date de fin est requise</span
            >
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de sortie prévue :
              <vuestic-date-picker
                v-model="currentHousing.estimated_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de sortie effective :
              <vuestic-date-picker
                v-model="currentHousing.free_end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field :class="getValidationClass('price')">
            <label for="housing-price">Prix par nuit</label>
            <md-input
              name="housing-price"
              id="housing-price"
              type="number"
              v-model="currentHousing.price"
            />
            <span
              class="md-error"
              v-if="
                $v.currentHousing.price && !$v.currentHousing.price.required
              "
              >La prix est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_leave_motive">Statut post hébergement</label>
            <md-select
              name="housing_leave_motive"
              id="housing_leave_motive"
              v-model="currentHousing.leave_motive"
            >
              <md-option
                v-for="(r, i) in housingLeaveMotives"
                :key="i"
                :value="r._id"
              >
                {{ r.label }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <md-subheader>Paiements</md-subheader>
      <b-table
        v-if="currentHousing.payments && currentHousing.payments.length > 0"
        striped
        hover
        :fields="fields"
        :items="currentHousing.payments"
      >
        <template #cell(actions)="row">
          <ion-icon
            name="trash"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Supprimer"
            @click="askDelete(row.index)"
          ></ion-icon>
        </template>
      </b-table>

      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-50">
          <md-field>
            <label for="payment">Paiement à ajouter (en €)</label>
            <md-input
              name="payment"
              id="payment"
              type="number"
              v-model="payment"
            />
          </md-field>
        </div>
        <button
          class="btn"
          :class="
            payment == null || payment == '' ? 'btn-pale' : 'btn-secondary'
          "
          type="button"
          @click="addPayment"
        >
          Ajouter un paiement
        </button>
      </div>
    </form>
    <form v-else novalidate @submit.prevent="addHousing">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="getValidationClass('nature')">
            <label for="housing_nature">Nature</label>
            <md-select
              name="housing_nature"
              id="housing_nature"
              v-model="currentHousing.nature"
            >
              <md-option
                v-for="(r, i) in housingNatures"
                :key="i"
                :value="r._id"
              >
                {{ r.label }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.nature && !$v.currentHousing.nature.required
              "
              >La nature de l'hébergement est requis</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('start_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Du :
              <vuestic-date-picker
                v-model="currentHousing.start_date"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="
                $v.currentHousing.start_date &&
                !$v.currentHousing.start_date.required
              "
              >La date de début est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Au :
              <vuestic-date-picker
                v-model="currentHousing.end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label for="housing_address_street">Adresse</label>
            <md-input
              name="housing_address_street"
              id="housing_address_street"
              v-model="currentHousing.address_street"
            />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_address_postal_code">Code postal</label>
            <md-input
              name="housing_address_postal_code"
              id="housing_address_postal_code"
              v-model="currentHousing.address_postal_code"
            />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_address_city">Ville</label>
            <md-input
              name="housing_address_city"
              id="housing_address_city"
              v-model="currentHousing.address_city"
            />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="housing_tel">Téléphone</label>
            <md-input
              name="housing_tel"
              id="housing_tel"
              v-model="currentHousing.tel"
            />
          </md-field>
        </div>
      </div>

      <h4 class="p-4 d-flex">Lier un contact</h4>
      <contact-linker
        ref="contactLinker"
        :recipient_id="recipient_id"
        form_ref="housingLinker"
        :contact_ids="currentHousing.contact_ids"
        @change="changeContact"
      />
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";
import { mapState } from "vuex";
import ContactLinker from "../contacts/ContactLinker.vue";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "housing-modal",
  components: {
    VuesticModal,
    VuesticDatePicker,
    ContactLinker,
    ObjectAutoComplete,
  },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
    housingType: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      users: [],
      rooms: [],
      hotels: [],
      housingNatures: [],
      housingLeaveMotives: [],
      payment: null,
      currentHousing: {},
      originHousing: {},
      housing: {},
      selectedHotelId: null,
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      selectedStartDate: null,
      fields: [
        { key: "amount", label: "Montant", formatter: (val) => val + " €" },
        {
          key: "date",
          label: "Date",
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
      ],
    };
  },
  validations() {
    const isDate = (val) => this.$moment(val).isValid();
    if (this.housingType == "Hébergements MRS") {
      return {
        selectedHotelId: {
          required: true,
        },
        currentHousing: {
          room_id: { required },
          start_date: {
            required,
            isDate,
          },
          end_date: {
            required,
            isDate,
          },
          user_id: {
            required,
          },
          price: {
            required,
          },
        },
      };
    } else if (this.housingType == "Hébergements d'urgence") {
      return {
        currentHousing: {
          hotel_info: {
            required,
          },
          start_date: {
            required,
            isDate,
          },
          end_date: {
            required,
            isDate,
          },
          user_id: {
            required,
          },
          price: {
            required,
          },
        },
      };
    } else {
      return {
        currentHousing: {
          nature: {
            required,
          },
          start_date: {
            required,
            isDate,
          },
        },
      };
    }
  },
  mounted() {
    this.refreshLists();
  },
  watch: {
    selectedHotelId() {
      this.refreshRooms();
    },
    currentHousing: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.selectedStartDate = newVal.start_date;
      },
    },
    selectedStartDate() {
      this.refreshLists();
    },
  },
  computed: {
    ...mapState(["user"]),
    assumedSelectedDate() {
      return this.currentHousing.start_date
        ? this.$moment(this.currentHousing.start_date).format("YYYY-MM-DD")
        : this.$moment().format("YYYY-MM-DD");
    },
    getTitle() {
      return this.housing._id == undefined
        ? "Ajouter un hébergement"
        : "Modification de l'hébergement";
    },
    availableRooms() {
      let availableRooms = this._.cloneDeep(this.rooms);
      if (
        this.originHousing.room &&
        this.originHousing.hotel_id == this.selectedHotelId
      ) {
        availableRooms.push(this.originHousing.room);
      }
      return availableRooms;
    },
  },
  methods: {
    refreshHousingNature() {
      axios.get(`/api/web/choices/housing_nature`).then((res) => {
        this.housingNatures = res.data.data;
      });
    },
    refreshHousingLeaveMotives() {
      axios.get(`/api/web/choices/housing_liberation`).then((res) => {
        this.housingLeaveMotives = res.data.data;
      });
    },
    refreshHousing() {
      if (this.housing._id) {
        axios.get(`/api/web/housing/${this.housing._id}`).then((res) => {
          this.originHousing = this._.cloneDeep(res.data.data);
          this.currentHousing = this._.cloneDeep(this.originHousing);
          this.selectedHotelId = this.originHousing.hotel_id;
        });
      } else {
        if (this.housingType == "Hébergements hors MRS") {
          this.originHousing = {
            address: {},
            contact_ids: [],
          };
          this.currentHousing = {
            address: {},
            start_date: this.$moment().format("YYYY-MM-DD"),
            contact_ids: [],
          };
        } else {
          this.originHousing = {
            payments: [],
            user_id: this.user._id,
          };
          this.currentHousing = {
            payments: [],
            start_date: this.$moment().format("YYYY-MM-DD"),
            user_id: this.user._id,
          };
        }
      }
    },
    refreshLists() {
      this.refreshHousingLeaveMotives();
      this.refreshHousingNature();
      if (
        this.user.habilitation == "volunteer" ||
        this.user.habilitation == "antenna_admin"
      ) {
        axios
          .get(`/api/web/hotels`, {
            params: {
              free: this.assumedSelectedDate,
              antenna: this.user.antenna_id,
            },
          })
          .then((res) => {
            this.hotels = res.data.data;
          });
        axios
          .get(`/api/web/users/antenna/${this.user.antenna_id}`)
          .then((res) => {
            this.users = res.data.data;
          });
      } else {
        axios
          .get(`/api/web/hotels`, {
            params: {
              free: this.assumedSelectedDate,
            },
          })
          .then((res) => {
            this.hotels = res.data.data;
          });
        axios.get(`/api/web/users`).then((res) => {
          this.users = res.data.data;
        });
      }
      this.refreshRooms();
    },
    refreshRooms() {
      if (this.selectedHotelId) {
        if (
          this.user.habilitation == "volunteer" ||
          this.user.habilitation == "antenna_admin"
        ) {
          axios
            .get(`/api/web/hotel/${this.selectedHotelId}/rooms`, {
              params: {
                free: this.assumedSelectedDate,
                archived: false,
                antenna: this.user.antenna_id,
              },
            })
            .then((res) => {
              this.rooms = res.data.data;
            });
        } else {
          axios
            .get(`/api/web/hotel/${this.selectedHotelId}/rooms`, {
              params: {
                free: this.assumedSelectedDate,
                archived: false,
              },
            })
            .then((res) => {
              this.rooms = res.data.data;
            });
        }
      } else {
        if (
          this.user.habilitation == "volunteer" ||
          this.user.habilitation == "antenna_admin"
        ) {
          axios
            .get(`/api/web/rooms`, {
              params: {
                free: this.assumedSelectedDate,
                antenna: this.user.antenna_id,
              },
            })
            .then((res) => {
              if (this.selectedHotelId === null) {
                this.rooms = res.data.data;
              }
            });
        } else {
          axios
            .get(`/api/web/rooms`, {
              params: {
                free: this.assumedSelectedDate,
              },
            })
            .then((res) => {
              if (this.selectedHotelId === null) {
                this.rooms = res.data.data;
              }
            });
        }
      }
    },
    addPayment() {
      this.currentHousing.payments.push({
        amount: parseFloat(this.payment),
        date: this.$moment().format("YYYY-MM-DD"),
      });
      this.payment = null;
    },
    changeContact(payload) {
      this.currentHousing.contact_ids = payload;
    },
    askDelete(index) {
      this.currentHousing.payments.splice(index, 1);
    },
    getValidationClass(fieldName, obj = null) {
      if (obj != null) {
        return { "md-invalid": this.$v[obj].$invalid && this.$v[obj].$dirty };
      } else {
        var field;
        if (fieldName.indexOf(".") >= 0) {
          field =
            this.$v.currentHousing[fieldName.split(".")[0]][
              fieldName.split(".")[1]
            ];
        } else {
          field = this.$v.currentHousing[fieldName];
        }

        if (field) {
          return {
            "md-invalid": field.$invalid && field.$dirty,
          };
        }
      }
    },
    resetForm() {
      this.currentHousing = {
        payments: [],
      };
      this.originHousing = {
        payments: [],
      };
      this.payment = null;
      this.selectedHotelId = null;
    },
    open(payload) {
      this.housing = payload.housing;
      this.refreshLists();
      this.refreshRooms();
      this.refreshHousing();
      this.$refs.housingModal.open();
      this.$refs.contactLinker.open();
    },
    formatHousing(housing) {
      housing.housing_type = this.housingType;
      if (this.housingType == "Hébergements MRS") {
        housing.hotel_id = this.selectedHotelId;
      }
      return housing;
    },
    formatRoom(room) {
      let formatedRoom = room.number;
      if (room.archived) {
        formatedRoom += " (archivée)";
      }
      return formatedRoom;
    },
    createHousing() {
      let body = {
        housing: this.formatHousing(this.currentHousing),
        housingType: this.housingType,
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/housings`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Hébergements mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "housing");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        })
        .catch((e) => {
          if (e.response.data.data.message) {
            this.$toasted
              .show("L'hébergement est déjà occupé", {
                action: [
                  {
                    text: "Voir l'accueilli occupant",
                    onClick: (_, toastObject) => {
                      this.$router.go(
                        `/recipient/${e.response.data.data.message}`
                      );
                      toastObject.goAway(0);
                    },
                  },
                  {
                    text: "Annuler",
                    onClick: (_, toastObject) => {
                      toastObject.goAway(0);
                    },
                  },
                ],
              })
              .goAway(10000);
          }
        });
    },
    saveHousing() {
      let body = {
        current: this.formatHousing(this.currentHousing),
        origin: this.formatHousing(this.originHousing),
        fail_on_concurrent: false,
      };
      axios.post(`/api/web/housing/${this.housing._id}`, body).then((res) => {
        if (res.data.success) {
          this.$toasted.show("Hébergement mis à jour").goAway(1500);
          this.$store.dispatch("setRefreshFlag", "housing");
        } else {
          this.$toasted.error("Erreur inconnue").goAway(1500);
        }
      });
    },
    okAction() {
      this.$v.$touch();
      if (
        this.selectedHotelId === null &&
        this.housingType == "Hébergements MRS"
      ) {
        this.$toasted
          .show("Hébergements non mis à jour. Sélectionnez l'hôtel")
          .goAway(1500);
        return false;
      }
      if (!this.$v.$invalid) {
        if (this.housing._id) {
          this.saveHousing();
        } else {
          this.createHousing();
        }
        this.resetForm();
        return true;
      } else {
        this.$toasted
          .show("Hébergements non mis à jour. Des champs sont manquants")
          .goAway(1500);
        return false;
      }
    },
  },
};
</script>

<style>
</style>