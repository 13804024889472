<template>
  <div class="recipient-resources pt-2">
    <div class="info-resources pl-4 pr-4">
      <widget headerText="Informations CAF">
        <form novalidate class="md-layout" @submit.prevent="saveCAF">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="caf_number">N° CAF</label>
                <md-input
                  name="caf_number"
                  id="caf_number"
                  v-model="recipient.caf_number"
                />
              </md-field>
            </div>
            <div class="md-layout-item d-flex align-items-center">
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Date d'inscription:
                  <vuestic-date-picker
                    v-model="recipient.caf_date"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
          </div>
        </form>
        <button type="button" class="btn btn-primary" @click="saveCAF">
          Enregistrer
        </button>
      </widget>
      <widget headerText="Liste des Ressources">
        <b-table
          v-if="resources"
          striped
          hover
          :fields="fields"
          :items="resources"
        >
          <template #cell(actions)="row">
            <ion-icon
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon
            ><ion-icon
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="modifyElement(row.item)"
            ></ion-icon>
          </template>
        </b-table>
        <button type="button" class="btn btn-primary" @click="addResource">
          Ajouter une ressource
        </button>
      </widget>
    </div>
    <comments
      :recipient_id="recipient_id"
      field="resource"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import Comments from "../comment/Comments.vue";
import { mapState } from "vuex";
export default {
  name: "recipient-resources",
  components: { Widget, Comments, VuesticDatePicker },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      recipient: {},
      resources: [],
      fields: [
        {
          key: "date",
          label: "Date",
          formatter: (date) => this.$moment(date).format("DD/MM/YYYY"),
          sortable: true,
        },
        {
          key: "label",
          label: "Ressource",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
        },
        {
          key: "comment",
          label: "Commentaire",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  mounted() {
    this.refreshResources();
    this.refreshRecipient();
  },
  computed: {
    ...mapState(["refreshFlag"]),
  },
  watch: {
    recipient_id() {
      this.refreshResources();
      this.refreshRecipient();
    },
    refreshFlag() {
      if (this.refreshFlag == "resource") {
        this.refreshResources();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshRecipient() {
      axios.get(`/api/web/recipient/${this.recipient_id}`).then((res) => {
        this.recipient = { ...{ experiences: [], ...res.data.data } };
      });
    },
    refreshResources() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/resources`)
        .then((res) => {
          this.resources = res.data.data;
        });
    },
    askDelete(resource) {
      this.$toasted
        .show("Souhaitez-vous supprimer cette ressource ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(resource._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(resource_id) {
      axios
        .delete(`/api/web/resource/${resource_id}`)
        .then(() => {
          this.$toasted.show("La ressource a été supprimé").goAway(1500);
          this.refreshResources();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    saveCAF() {
      let body = {
        caf_number: this.recipient.caf_number,
        caf_date: this.recipient.caf_date,
      };
      axios
        .post(`/api/web/recipient/${this.recipient_id}/caf`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Modification enregistrées").goAway(1500);
            this.refreshRecipient();
          } else {
            this.$toasted.error("Modifications non enregistrées").goAway(1500);
          }
        });
    },
    addResource() {
      let payload = {
        recipient_id: this.recipient_id,
        resource: {},
      };
      this.$emit("openResourceModal", payload);
    },
    modifyElement(resource) {
      let payload = {
        recipient_id: this.recipient_id,
        resource: resource,
      };
      this.$emit("openResourceModal", payload);
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
  },
};
</script>

<style>
</style>