<template>
  <div class="rattachments">
    <div class="attachment-list pt-2 pl-4 pr-4">
      <widget headerText="Documents de l'antenne">
        <b-table
          v-if="attachments.length > 0"
          striped
          hover
          :items="attachments"
          :fields="fields"
          ><template #cell(actions)="row">
            <ion-icon
              name="newspaper"
              style="cursor: pointer"
              @click="downloadAttachment(row.item)"
              v-b-tooltip.hover
              title="Télécharger le document"
            ></ion-icon>
            <ion-icon
              name="trash"
              v-b-tooltip.hover
              style="cursor: pointer"
              title="Supprimer le document"
              @click="askDelete(row.item)"
            ></ion-icon> </template
        ></b-table>
        <button type="button" class="btn btn-primary mt-2" @click="addFile">
          Ajouter un document
        </button>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { Widget },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      attachments: [],
      fields: [
        {
          key: "type",
          label: "Type de fichier",
          sortable: true,
        },
        {
          key: "file.filename",
          label: "Nom",
        },
        {
          key: "loaded_on",
          label: "Date",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
        },
        {
          key: "loaded_by",
          label: "Ajouté par",
          formatter: (user) => (user ? user.initials : ""),
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  computed: {
    ...mapState(["refreshFlag", "user"]),
  },
  mounted() {
    this.refreshAttachments();
  },
  watch: {
    recipient_id() {
      this.refreshAttachments();
    },
    refreshFlag() {
      if (this.refreshFlag == "attachments") {
        this.refreshAttachments();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshAttachments() {
      axios
        .get(`/api/web/antenna/${this.user.antenna_id}/attachments`)
        .then((res) => {
          this.attachments = res.data.data;
        });
    },
    addFile() {
      let payload = {
        antenna_id: this.user.antenna_id,
        attachment: {
          meta_type: "antenna",
        },
      };
      this.$emit("openAttachmentModal", payload);
    },
    askDelete(attachment) {
      if (this.user.habilitation == "volunteer") {
        this.$toasted
          .show("Vous ne pouvez pas supprimer des documents")
          .goAway(2000);
      } else {
        this.$toasted
          .show("Souhaitez-vous supprimer ce document ?", {
            action: [
              {
                text: "Confimer",
                onClick: (_, toastObject) => {
                  this.delete(attachment._id);
                  toastObject.goAway(0);
                },
              },
              {
                text: "Annuler",
                onClick: (_, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            ],
          })
          .goAway(10000);
      }
    },
    delete(attachment_id) {
      axios
        .delete(`/api/web/attachment/${attachment_id}`)
        .then(() => {
          this.$toasted.show("Le document a été supprimé").goAway(1500);
          this.refreshAttachments();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    downloadAttachment(attachment) {
      axios
        .get(`/api/web/attachment/${attachment._id}/download`, {
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.headers.filename);
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
</style>